// App.js
import React from "react";
import "./App.css";
import { UserContext } from "./Pages/zoomIntegration/Context/globalContext";
import Routes from "./Route";

// var meetingArgs = {
//   videoSDKJWT: "",
//   sessionName: "My Zoom Session",
//   userName: "",
//   sessionPasscode: "",
//   cloud_recording_option: "0",
//   cloud_recording_election: "0",
//   role: 1,
//   telemetry_tracking_id: "",
//   features: ["video", "audio"],
// };

const App = () => {
  return (
    <div className="App">
      {/* <UserContext.Provider value={meetingArgs}> */}
        <Routes />
      {/* </UserContext.Provider> */}
    </div>
  );
};

export default App;
