import React, { useState, useEffect } from "react";
import LeftSidebar from "../OnboardingDashboard/LeftSidebar";
import DashboardCards from "../OnboardingDashboard/DashboardCards";
import AdminNavbar from "../adminNavbar";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MobSideBar from "../mobsidebar";
import AdminPendingInterviews from "./adminPendingInterviews";
import PendingInterviews from "../interviewDashboard/pendingInterviews";

const AdminInterviewDashboard = () => {
  const user = useSelector((state) => state?.AdminLogInSlice?.user?.content);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const approval =  queryParams.get("approval") || "PENDING";
  const interviewStatus = queryParams.get("interviewStatus") || "CANDIDATE";
  // const approval = user?.customer.role === "HR" ? queryParams.get("approval") || "PENDING" : queryParams.get("status") || 'HOLD_ON';
  // const interviewStatus = queryParams.get("interviewStatus") || "HOLD_ON";

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [approval,interviewStatus]);

  return (
    <>
      <div className="mainsection">
        <div className="section grid grid-cols-[260px_1fr] md:grid-cols-1 sm:grid-cols-1">
          <div className="YourComponentStyles">
            {isMobile ? (
              <MobSideBar currentPage="interviews" />
            ) : (
              <div className="leftsection h-screen md:block">
                <LeftSidebar currentPage="interviews" />
              </div>
            )}
          </div>

          <div className="rightsection bg-[#F1F5F9] ">
            <div className="topsection sm:mt-4 bg-white flex justify-end">
              <div className="sm:hidden md:hidden">
                <AdminNavbar showlogo={false} />
              </div>
            </div>
            <div className="pl-6 mt-6">
              <DashboardCards />
              <div className="tablesection">
                <PendingInterviews interviewapproval={approval} interviewStatus={interviewStatus}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminInterviewDashboard;

// ${showSidebar ? "grid grid-cols-[1fr_4fr]" : "grid-cols-[1fr]"}
