import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import loginSlice from "../loginSlice/loginSlice";
import userSlice from '../candidateInfoSlice/candidateInfoSlice';
import topCandidateSlice from "../topCandidateSlice/topCandidateSlice";
import searchCandidatelice from "../candidateSearchSlice/candidateSearchSlice";
import interviewRequestSlice from "../interviewRequestSlice/interviewRequestSlice";
import adminSlice from "../adminProfileSlice/adminProfileSlice";
import zoomSlice from '../zoomVideo/zoomVideoSlice';
import employerInterviewSlice from '../employerInterviewSlice/employerInterviewSlice';
import clientSlice from '../zoomCreateClient/zoomCreateClient';
import AdminLogInSlice from '../adminLoginSlice/adminLoginSlice';
import candidateInterviewStatus from '../candidateDashboardSlice/candidateDashboardSlice';
import allUsersAdminSlice from '../allUsersforAdminSlice/usersforAdminSlice';
import AllEmployersSlice from '../All-Candidates-EmployersSlice/allEmployersSlice';
import searchAdminSlice from '../adminSearchSlice/adminSearchSlice'
import { thunk } from "redux-thunk";
import paginationSlice from "../paginationSlice/paginationSlice";
const reducers = combineReducers({
  loginSlice: loginSlice,
  userSlice:userSlice,
  topCandidateSlice:topCandidateSlice,
  searchCandidatelice:searchCandidatelice,
  interviewRequestSlice:interviewRequestSlice,
  adminSlice:adminSlice,
  zoomSlice:zoomSlice,
  clientSlice:clientSlice,
  AdminLogInSlice:AdminLogInSlice,
  candidateInterviewStatus:candidateInterviewStatus,
  allUsersAdminSlice:allUsersAdminSlice,
  employerInterviewSlice: employerInterviewSlice,
  AllEmployersSlice:AllEmployersSlice,
  searchAdminSlice:searchAdminSlice,
  paginationSlice:paginationSlice

});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginSlice",'AdminLogInSlice'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
  // middleware: [thunk],
});

export const persistor = persistStore(store);

export const { dispatch } = store;

export const useDispatch = () => useAppDispatch();
export const useSelector = useAppSelector;

export default store;
