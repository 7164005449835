import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { TOP_CANDIDATE } from "../../utils/baseUrl";

const initialState = {
  loading: false,
  topCandidates: [],
  error: "",
};

export const fetchTopCandidates = createAsyncThunk(
  "topCandidateSlice/fetchTopCandidates",
  async (data) => {
    const response = await axios.get(TOP_CANDIDATE(data) );
    return response?.data;
  }
);

const topCandidateSlice = createSlice({
  name: "topCandidateSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTopCandidates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTopCandidates.fulfilled, (state, action) => {
      state.loading = false;
      state.topCandidates = action.payload;
      state.error = "";
    });
    builder.addCase(fetchTopCandidates.rejected, (state, action) => {
      state.loading = false;
      state.topCandidates = [];
      state.error = action.error.message;
    });
  },
});

export default topCandidateSlice.reducer;
