// clientSlice.js
import { createSlice } from '@reduxjs/toolkit';
import ZoomVideo from '@zoom/videosdk';

const initialState = {
  client: ZoomVideo.createClient(),
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
  },
});

export const { setClient } = clientSlice.actions;
export default clientSlice.reducer;
