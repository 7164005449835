import React, { Fragment, useEffect } from "react";
import logo from "./../../assets/images/logo.png";
import { Menu, Transition } from "@headlessui/react";
import { BASE_URL } from "../../utils/baseUrl";
import { useDispatch, useSelector } from "react-redux";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import { LogOutUserReducer } from "../../store/loginSlice/loginSlice";
import { fetchUser } from "../../store/candidateInfoSlice/candidateInfoSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CandidateNavbarAfterLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);
  const user = useSelector((state) => state?.userSlice?.user);
  const logoutHandler = () => {
    dispatch(LogOutUserReducer());
    navigate("/");
  };
  return (
    <div className="nav-sec mx-auto  flex items-center justify-between sm:flex-col sm:items-baseline py-3 px-6">
      <img src={logo} alt="" className="ml-12" />
      <div className="rightsection flex items-center gap-10 sm:mt-4">
        {/* <div className="notification relative flex items-center gap-2">
     <img src={bellicon} alt="" />
     <p className="text-[18px] font-normal text[#161614]">
       Notification
     </p>
     <div className="absolute top-[-14px] right-[-16px] bg-black rounded-[50%] py-1 px-2">
       <p className="text-[10px] font-normal text-white">8</p>
     </div>
   </div> */}
        <Menu as="div" className="relative inline-block text-left">
          <div className="flex items-center">
            <img
              src={`${BASE_URL}/profile/${user?.content?.profile_picture}`}
              alt=""
              className="h-[60px] w-[60px] rounded-full object-cover"
            />
            <div className="flex flex-col">
              <Menu.Button className="inline-flex items-center w-full justify-center gap-x-1.5  bg-inherit px-3 py-2 text-[15px] font-semibold text-gray-900 hover:bg-inherit">
                <div
                  style={{
                    maxWidth: "170px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {user?.content?.first_name} {user?.content?.last_name}
                </div>
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
              <h2
                className="text-[12px] font-normal pl-4 "
                style={{
                  maxWidth: "170px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {user?.content?.title}
              </h2>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="/candidate-dashboard"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      My Profile
                    </a>
                  )}
                </Menu.Item>
                {user?.content?.isApproval ==="APPROVED" && user?.content?.interviewResechdule===false ?(
                  <Menu.Item>
                    {({ active }) => (
                      <Link to={`/schedular/${user?.content?._id}`}>
                        <a
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Reschedule Interview
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                ):null}
                <form method="POST" action="#">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="submit"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                        onClick={logoutHandler}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </form>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default CandidateNavbarAfterLogin;
