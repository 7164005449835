import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { ALL_USERS_FOR_ADMIN } from "../../utils/baseUrl";



const initialState = {
  loading: false,
  interview: null,
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const allUsersforAdmin = createAsyncThunk(
  "allUsersAdminSlice/allUsersforAdmin",
  async (data) => {
    const response = await axios.get(ALL_USERS_FOR_ADMIN(data) );
    console.log("🚀 ~ response:", response)
    return response?.data;
  }
);

const allUsersAdminSlice = createSlice({
  name: "allUsersAdminSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(allUsersforAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(allUsersforAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    });
    builder.addCase(allUsersforAdmin.rejected, (state, action) => {
      state.loading = false;
      state.user = [];
      state.error = action.error.message;
    });
  },
});

export default allUsersAdminSlice.reducer;
