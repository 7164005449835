import React, { Fragment, useEffect, useRef, useState } from "react";
import crossicon from "../../../assets/images/Icons/crossicon.svg";
import ChangeProfileImage from "../../../assets/images/ChangeProfileImage.png";
import iconnn from "../../../assets/images/Icons/iconnn.svg";
import titleicon from "../../../assets/images/Icons/titleicon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminNavbar from "../adminNavbar";
import logo from "../../../assets/images/logo.png";
import {
  BASE_URL,
  CANDIDATE_ADMIN_PROFILE,
  DELETE_VIDEO,
  UPDATE_BY_CONTENT_WRITER,
  UPLOAD_ZOOM_VIDEO,
} from "../../../utils/baseUrl";
import VerifiedButton from "../../../common/verifiedIcon/verifiedIcon";
import { IconArrowLeft } from "@tabler/icons-react";
import Chart from "react-google-charts";
import { Dialog, Transition } from "@headlessui/react";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import Swal from "sweetalert2";
import FileUpload from "../../../common/uploadVideo/uploadVideo";
import { renderBadge } from "../../../common/badges/badges";
import Edit from "../../../assets/images/editing.png";
import Editing from "../../../assets/images/Edit.png";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import buildingicon from "../../../assets/images/Icons/buildingicon.svg";
import calendaricon from "../../../assets/images/Icons/calendaricon.svg";
const CandidateProfileAdmin = () => {
  // const { id } = useParams();

  ///////////////////////////notice period integration/////////////////////
  const [showEditButton,setShowEditButton]=useState(false)
  const [user, setUser] = useState();
  const { id } = useParams();
  const getCandidateData=()=>{
    axios.get(CANDIDATE_ADMIN_PROFILE(id)).then((response) => {
      setUser(response?.data);
    });
  }
  useEffect(() => {
    getCandidateData()
  }, []);
  const [openAddress, setOpenAddress] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [editData, setEditData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    cell_phone: "",
    title: "",
    date_of_birth: "",
    address: " ",
    gender: "",
    country: "",
    city: "",
    role: "",
    about_me: "",
    notice_period: "",
    language: [
      {
        lan: "",
        spoking: "",
        reading: "",
        writing: "",
      },
    ],
    skill: [
      {
        name: "",
        rating: "",
        type: "",
      },
    ],
    experience: [
      {
        designation: "",
        company: "",
        description: "",
        start_date: "",
        end_date: "",
      },
    ],
    education: [
      {
        university: "",
        degree: "",
        start_date: "",
        end_date: "",
      },
    ],
    question: [
      {
        Q_number: "",
        Q_ans: "",
      },
    ],
    expect_message: "",
    zoom_meeting_date: "",
    zoom_meeting_time: "",
    zoom_meeting_url: "",
  });
  useEffect(() => {
    // setFormData({});

    setEditData({
      first_name: user?.content?.first_name || "",
      last_name: user?.content?.last_name || "",
      email: user?.content?.email || "",
      cell_phone: user?.content?.cell_phone || "",
      title: user?.content?.title || "",
      address: user?.content?.address || "",
      date_of_birth: user?.content?.date_of_birth || "",
      gender: user?.content?.gender || "",
      country: user?.content?.country || "",
      city: user?.content?.city || "",
      role: user?.content?.role || "",
      about_me: user?.content?.about_me || "",
      notice_period: user?.content?.notice_period || "",
      language: user?.content?.language || [
        { lan: "", spoking: "", reading: "", writing: "" },
      ],
      skill: user?.content?.skill || [{ name: "", rating: "", type: "" }],
      experience: user?.content?.experience || [
        {
          designation: "",
          company: "",
          description: "",
          start_date: "",
          end_date: "",
        },
      ],
      education: user?.content?.education || [
        { university: "", degree: "", start_date: "", end_date: "" },
      ],
      question: user?.content?.question || [{ Q_number: "", Q_ans: "" }],
      expect_message: user?.content?.expect_message || "",
      zoom_meeting_date: user?.content?.zoom_meeting_date || "",
      zoom_meeting_time: user?.content?.zoom_meeting_time || "",
      zoom_meeting_url: user?.content?.zoom_meeting_url || "url",
    });
  }, [user]);
  //////////////////////////////////////
  //////////////// for Work Experience////////////////
  const [button1Visible, setButton1Visible] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const handleEditClick = () => {
    setButton1Visible(!button1Visible);
  };

  const openEditModal = (experience) => {
    setIsEditMode(true);
    setFormData({
      designation: experience.designation,
      company: experience.company,
      description: experience.description,
      start_date: experience.start_date,
      end_date: experience.end_date,
      _id: experience._id,
    });

    setOpen(true);
  };

  const handleAddExperience = (event) => {
    event.preventDefault();

    let updatedExperiences;
    if (isEditMode) {
      updatedExperiences = user.content.experience.map((exp) =>
        exp._id === formData._id ? formData : exp
      );
    } else {
      updatedExperiences = [...user.content.experience, formData];
    }

    const updatedData = {
      ...user.content,
      experience: updatedExperiences,
    };

    for (const experience of updatedExperiences) {
      for (const key in experience) {
        if (
          typeof experience[key] === "string" &&
          experience[key].trim() === ""
        ) {
          toast(`Please Fill all the Fields.`);
          return;
        }
      }
    }

    axios
      .put(UPDATE_BY_CONTENT_WRITER(user?.content?._id), updatedData)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
        setOpen(false);
        getCandidateData()
        // dispatch(fetchUser());
      })
      .catch((error) => {});
    setOpen(false);
  };

  
  const handleInputExperience = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /////////////////////////////////////////////////////////////
  const handleUpdate = () => {

    if (!editData.first_name || !editData.last_name || !editData.title) {
      toast("Please fill in all the fields.");
      return; // Stop further execution
    }
    if (!editData.cell_phone || !editData.address) {
      toast("Please fill in all the fields.");
      return; // Stop further execution
    }
    if (!editData.notice_period) {
      toast("Please fill in all the fields.");
      return; // Stop further execution
    }
    if (!editData.about_me) {
      toast("Please fill in all the fields.");
      return; // Stop further execution
    }
    axios
      .put(UPDATE_BY_CONTENT_WRITER(user?.content?._id), editData)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
        setOpenAbout(false);
        setOpenName(false);
        setOpenAddress(false);
        // setOpen(false);
        setOpenNotice(false);
        getCandidateData()
        // dispatch(fetchUser());
      })
      .catch((error) => {});
  };

  const [myprofile, setMyProfile] = useState(null);
  const [changeImage, setChangeImage] = useState();
  const [openPic, setOpenPic] = useState(false);
  const cancelButtonRef = useRef(null);
  const location = useLocation();
  const receivedData = location.state?.item;

  const navigate = useNavigate();
  const [candidate, setCandidate] = useState([receivedData]);
  const feedbackData = [].concat(
    user?.content?.technicalSkill,
    user?.content?.presentationSkill,
    user?.content?.proactive,
    user?.content?.caseStudy,
    user?.content?.englishLanguage,
    user?.content?.recruiterEmotional
  );
  const userData = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.content
  );
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate(`/public-profile?candidate=${id}`);
    }
  }, [userData, id, navigate]);

  ////////////////////////////// For Video//////////////////////////////////////
  const AddProfile = () => {
    axios
      .post(UPLOAD_ZOOM_VIDEO(id), myprofile, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      })
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          // dispatch(fetchUser());
          setOpenPic(false);
          navigate(
            "/interview-dashboard?approval=COMPLETED&completeRequest=completed"
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        setOpenPic(false);
      });
  };
  ////////////////////////////// For Delete Video//////////////////////////////////////
  const deleteVideo = async () => {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete the Video!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#EBAD07",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!",
    });
    if (confirmResult.isConfirmed) {
      axios
        .delete(DELETE_VIDEO(id))
        .then((response) => {
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              title: `${response?.data?.message}`,
              showConfirmButton: false,
              timer: 1500,
            });
            // dispatch(fetchUser());
            setOpenPic(false);
            navigate(
              "/interview-dashboard?approval=COMPLETED&completeRequest=completed"
            );
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${response?.data?.message}`,
              confirmButtonColor: "#EEAF06",
            });
          }
        })
        .catch((error) => {
          setOpenPic(false);
        });
    }
  };
  /////////////////////////// pie chart data/////////////////////////////
  const data = [
    ["Task", "Hours per Day"],
    ["TECHNICAL SKILLS", user?.content?.technicalSkillTotal],
    ["PRESENTATION SKILLS", user?.content?.presentationTotal],
    ["PROACTIVE & REACTIVE",user?.content?.proactiveTotal],
    ["CASE STUDIES", user?.content?.caseStudyTotal],
    ["ENGLISH LANGUAGE", user?.content?.englishLanguageTotal],
    ["RECRUITER EMOTIONAL FEEL", user?.content?.recruiterEmotionalTotal],
  ];
  const totalUsedPercentage = data
    .slice(1)
    .reduce((acc, [, percentage]) => acc + percentage, 0);
  const options = {
    Total_Marks: "100",
    Getting_Marks: totalUsedPercentage,
  };
  return (
    <>
      {/* modal for work experience */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" overflow-y-auto relative transform w-[70%] h-[600px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%]">
                  <div className="">
                    <form onSubmit={handleAddExperience}>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-[16px] font-medium leading-6 text-gray-900"
                        >
                          Add Experience
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className=" w-[90%]  mx-auto  mt-[1rem] md:w-full sm:w-full">
                        <label className=" text-black-500 text-[14px] mb-2 flex items-center my-10">
                          <img src={titleicon} alt="email" className="mr-2" />{" "}
                          Title
                        </label>

                        <input
                          type="text"
                          id="designation"
                          placeholder="ui/ux Designer"
                          name="designation"
                          value={formData?.designation}
                          // onChange={(e) =>
                          //   setFormData({...formData,"designation", e.target.value })
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {/* {formik.touched.designation &&
                        formik.errors.designation ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.designation}
                          </p>
                        ) : null} */}
                        <label className=" text-black-500 text-[14px] mb-2 flex items-center my-6">
                          <img
                            src={buildingicon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Company Name
                        </label>
                        <input
                          type="text"
                          id="company"
                          placeholder="BMG Talent"
                          name="company"
                          value={formData?.company}
                          // onChange={(e) =>
                          //   setFormData(...formData,"company", e.target.value )
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {/* {formik.touched.company && formik.errors.company ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.company}
                          </p>
                        ) : null} */}
                        <label className=" text-black-500 text-[14px] mb-2 flex items-center my-6">
                          <img
                            src={buildingicon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Job Description
                        </label>
                        <input
                          type="text"
                          id="description"
                          placeholder="job description ....."
                          name="description"
                          value={formData?.description}
                          // onChange={(e) =>
                          //   setFormData(...formData,"description", e.target.value )
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {/* {formik.touched.description &&
                        formik.errors.description ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.description}
                          </p>
                        ) : null} */}
                        <label className=" text-black-500 text-[14px] mb-2 flex items-center my-6">
                          <img
                            src={calendaricon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Start Date
                        </label>
                        <input
                          type="date"
                          id="start_date"
                          placeholder="BMG Talent"
                          name="start_date"
                          value={formData?.start_date}
                          // onChange={(e) =>
                          //   setFormData(...formData,"start_date", e.target.value )
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {/* {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.start_date}
                          </p>
                        ) : null} */}
                        <label className=" text-black-500 text-[14px] mb-2 flex items-center my-6">
                          <img
                            src={calendaricon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          End Date
                        </label>
                        <input
                          type="date"
                          id="end_date"
                          placeholder="BMG Talent"
                          name="end_date"
                          value={formData?.end_date}
                          // onChange={(e) =>
                          //   setFormData(...formData,"end_date", e.target.value )
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {/* {formik.touched.end_date && formik.errors.end_date ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.end_date}
                          </p>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] border-[1px] px-[70px] rounded-md   py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={handleAddExperience}
                    >
                      Save
                    </button>
                    {/* <button
                      onClick={() => handleDeleteExperience(formData._id)}
                      className="border-[1px] px-[70px] rounded-md   py-2 text-sm font-semibold text-black shadow-sm  sm:ml-3 sm:w-auto hover:text-white hover:bg-[#E9AA06]"
                    >
                      Delete
                    </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* modal for address and phone  */}
      <Transition.Root show={openAddress} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenAddress}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[400px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-[16px] font-medium leading-6 text-gray-900"
                        >
                          Edit Information
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenAddress(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="">
                          <label className=" text-black-500 text-[14px] mb-2 flex items-center my-10">
                            <img src={iconnn} alt="email" className="mr-2" />{" "}
                            Address
                          </label>
                          <input
                            type="text"
                            id="address"
                            placeholder="Address"
                            name="address"
                            value={editData?.address}
                            onChange={handleInputChange}
                            className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          <label className=" text-black-500 text-[14px] mb-2 flex items-center my-10">
                            <img src={titleicon} alt="email" className="mr-2" />{" "}
                            Phone Number
                          </label>
                          {/* <input
                            type="text"
                            id="cell_phone"
                            maxLength={10}
                            placeholder="add number without +"
                            name="cell_phone"
                            value={editData?.cell_phone}
                            onChange={handleInputChange}
                            className=" appearance-none w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          /> */}
                          <PatternFormat
                            id="cell_phone"
                            format="60###########"
                            allowEmptyFormatting
                            placeholder="add number without +"
                            name="cell_phone"
                            value={editData?.cell_phone}
                            onChange={handleInputChange}
                            className=" appearance-none w-full border rounded text-[14px] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      // onClick={handleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* modal for  name and Title  */}
      <Transition.Root show={openName} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenName}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[50%] h-[250px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-[16px] font-medium leading-6 text-gray-900"
                        >
                          Edit Title
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenName(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-2 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="">
                          {/* <label className=" text-black-500 text-[14px] mb-2 flex items-center my-10">
                            <img src={iconnn} alt="email" className="mr-2" />{" "}
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            placeholder="First Name"
                            name="first_name"
                            value={editData?.first_name}
                            onChange={handleInputChange}
                            className=" appearance-none border rounded w-full py-3 px-3 text-[14px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          <label className=" text-black-500 text-[14px] mb-2 flex items-center my-10">
                            <img src={iconnn} alt="email" className="mr-2" />{" "}
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            placeholder="Last Name"
                            name="last_name"
                            value={editData?.last_name}
                            onChange={handleInputChange}
                            className=" appearance-none border rounded w-full py-3 px-3 text-[14px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          /> */}
                          <label className=" text-black-500 text-[14px] mb-2 flex items-center my-10">
                            <img src={titleicon} alt="email" className="mr-2" />{" "}
                            Title
                          </label>
                          <input
                            type="text"
                            id="title"
                            placeholder="ui/ux Designer"
                            name="title"
                            value={editData?.title}
                            onChange={handleInputChange}
                            className=" appearance-none w-full border rounded py-3 px-3 text-[14px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />

                          {/* {formik.touched.name && formik.errors.name ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.name}
                            </p>
                          ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* modal for notice Period */}
      <Transition.Root show={openNotice} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenNotice}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[40%] h-[270px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl sm:text-lg font-medium leading-6 text-gray-900"
                        >
                          Notice Period
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenNotice(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="">
                          <label className=" text-black-500 text-[16px] mb-2 flex items-center my-10">
                            {/* <img src={iconnn} alt="email" className="mr-2" />{" "} */}
                            Notice Period
                          </label>
                          <select
                            id="noticePeriod"
                            name="notice_period"
                            value={editData?.notice_period}
                            onChange={handleInputChange}
                            className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="" disabled selected>
                              Please Select 1
                            </option>
                            <option value="ONE_WEEK">1 week</option>
                            <option value="TWO_WEEK">2 week</option>
                            <option value="THREE_WEEK">3 week</option>
                            <option value="ONE_MONTH">1 month</option>
                            <option value="TWO_MONTH">2 month</option>
                            <option value="THREE_MONTH">3 month</option>
                          </select>
                          {/* {formik.touched.name && formik.errors.name ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.name}
                            </p>
                          ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      // onClick={handleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* modal for about */}
      <Transition.Root show={openAbout} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenAbout}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[350px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-[16px] sm:text-lg font-medium leading-6 text-gray-900"
                        >
                          Add About Yourself
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenAbout(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="skillsfield">
                          <label className=" text-black-500 text-[14px] mb-2 flex items-center my-10">
                            <img src={titleicon} alt="email" className="mr-2" />{" "}
                            About Yourself
                          </label>

                          <textarea
                            rows={6}
                            name="about_me"
                            value={editData?.about_me}
                            onChange={handleInputChange}
                            id="about_me"
                            className="block w-full pl-4 outline-0 rounded-md text-[14px] border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                            // defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* /////////////////////Upload the video////////////////// */}
      <Transition.Root show={openPic} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenPic}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[60%] h-[42%]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[70%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Upload Video
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenPic(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="items-center mx-8 mt-[1rem]">
                        <div className="skillsfield relative flex justify-center">
                          {/* <img
                            src={
                              changeImage ? changeImage?.profile : maleAvatar
                            }
                            alt=""
                            className="w-[200px] h-[200px] rounded-2xl object-cover"
                          /> */}
                          <video
                            controls
                            width="90%"
                            height="300px"
                            className="rounded"
                          >
                            <source
                              src={changeImage ? changeImage?.video : ""}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                          <label className=" absolute bottom-[-12px] right-[4%] left text-white  rounded-full cursor-pointer">
                            <img src={ChangeProfileImage} alt="" className="" />
                            <input
                              type="file"
                              accept="video/mp4,video/x-m4v,video/*"
                              name="profile"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const imageUrl = URL.createObjectURL(file);

                                setChangeImage({
                                  video: imageUrl,
                                });
                                setMyProfile({
                                  video: file,
                                });
                                // setOpenPic(false);
                              }}
                              className="hidden"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={AddProfile}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="container mx-auto ">
        <AdminNavbar showlogo={true} logo={logo} setShowEditButton={setShowEditButton} data={5} />
      </div>
      <div className="bg-[#F1F5F9]">
        {/* {candidate?.map((user, index) => ( */}
          <div className="container mx-auto mb-8" >
            {/* <AdminNavbar showlogo={true} logo={logo} /> */}
            {user && (
              <div className="">
                <div className="grid 2xl:grid-cols-[387px_1fr] xl:grid-cols-[300px_1fr] lg:grid-cols-[280px_1fr] md:grid-cols-1 mt-5 pt-5  gap-8">
                  <div>
                    <button
                      onClick={() => navigate(-1)}
                      className="border-2 px-2 rounded-lg bg-white "
                    >
                      <IconArrowLeft className="w-[30px] h-[30px]  text-[#495057] " />
                    </button>
                    <div className="leftsection   flex flex-col justify-between   dashboard-background">
                      <div className="top ">
                        <div className="candidateprofile relative bg-white border rounded-md shadow-md py-8">
                          {showEditButton ?(
                          <img
                            src={Editing}
                            alt=""
                            className=" cursor-pointer w-4 h-4 absolute right-2 top-2"
                            onClick={() => setOpenName(true)}
                          />
                        ):null}
                          <div className="profileimg flex justify-center relative pb-2">
                            <img
                              src={`${BASE_URL}/profile/${user?.content?.profile_picture}`}
                              alt="profilePicture"
                              className="w-[90px] h-[90px] rounded-full object-cover mx-auto"
                            />
                            <div
                              className={`h-7 w-7 absolute ${
                                user?.content?.profile_picture === "empty-avatar.png"
                                  ? "left-[54%]"
                                  : "left-[57%]"
                              } top-[50px] xl:left-[59%] lg:left-[58%] md:left-[56%] sm:left-[53%] -rotate-10`}
                            >
                              {renderBadge(user?.content?.subscription_tier)}
                            </div>
                          </div>
                          <div className="  pb-4   px-2 flex flex-col items-center justify-center  ">
                            <h4 className="text-[20px] font-bold text-center text-[#2c2c2c] ">
                              {user?.content?.first_name} {user?.content?.last_name}
                            </h4>
                            <p className="text-[16px] font-normal text-center text-[#777777]">
                              {user?.content?.title}
                            </p>
                          </div>
                        </div>
                        <div className="candidateinfo bg-white border rounded-md shadow-md  mt-6 ">
                          <div className="bg-[#E9AA06] flex justify-between items-center rounded-t-md p-2">
                            <h2 className="text-[16px] text-[#FFFFFF]    font-medium ">
                              Profile Overview
                            </h2>
                            {/* <img
                              src={Edit}
                              alt=""
                              className=" cursor-pointer w-4 h-4"
                              // onClick={() => setOpenAddress(true)}
                            /> */}
                          </div>
                          <div className="p-2">
                            <div className="flex items-center py-2 gap-1">
                              <h2 className="text-[16px] text-[#495057] font-semibold">
                                Email:{" "}
                              </h2>
                              <p
                                className="text-[14px] pt-[2px] text-center font-normal flex justify-center items-center text-[#696969]"
                                style={{
                                  maxWidth: "250px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {user?.content?.email}
                              </p>
                              {user?.content?.emailVerified && <VerifiedButton />}
                            </div>

                            <div className="flex gap-1 py-2">
                              <h2 className="text-[16px] text-[#495057] font-semibold">
                                Country:{" "}
                              </h2>
                              <p className="text-[14px] pt-[2px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                                {user?.content?.country}
                              </p>
                            </div>
                            <div className="flex gap-1 py-2">
                              <h2 className="text-[16px] text-[#495057] font-semibold">
                                Address:{" "}
                              </h2>
                              <p className="text-[14px] pt-[3px] capitalize  font-normal flex justify-center items-start text-[#696969]">
                                {user?.content?.address}
                              </p>
                            </div>
                            <div className="flex gap-1 py-2">
                              <h2 className="text-[16px] text-[#495057] font-semibold">
                                Contact Number:{" "}
                              </h2>
                              <p className="text-[14px] pt-[2px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                                {user?.content?.cell_phone}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="languages bg-white border rounded-md shadow-md  mt-6">
                          <div className="bg-[#E9AA06] flex justify-between items-center rounded-t-md p-2">
                            <h2 className="text-[16px] text-[#FFFFFF]   font-medium ">
                              Languages
                            </h2>
                            {/* <img
                              src={Edit}
                              alt=""
                              className=" cursor-pointer w-4 h-4"
                              onClick={() => setOpenLanguage(true)}
                            /> */}
                          </div>
                          <div className=" gap-2 p-4  flex  flex-wrap">
                            {user?.content?.language?.map((item, index) => (
                              <h2
                                key={index}
                                className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                              >
                                {item.lan}
                              </h2>
                            ))}
                          </div>
                        </div>
                        <div className="TechSkills bg-white border rounded-md shadow-md  mt-6 ">
                          <div className="bg-[#E9AA06] flex justify-between rounded-t-md items-center p-2">
                            <h2 className="text-[16px] text-[#FFFFFF]   font-medium ">
                              Tech Skills
                            </h2>
                            {/* <img
                              src={Edit}
                              alt=""
                              className=" cursor-pointer w-4 h-4"
                              // onClick={() => setOpenName(true)}
                            /> */}
                          </div>
                          {user?.content?.skill?.some(
                            (skill) => skill.type === "TECH"
                          ) ? (
                            <div className="flex flex-wrap p-4 gap-2">
                              {user?.content?.skill?.map(
                                (title, index) =>
                                  title.type === "TECH" && (
                                    <h2
                                      key={index}
                                      className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                                    >
                                      {title.name} {title.rating}/10
                                    </h2>
                                  )
                              )}
                            </div>
                          ) : (
                            <p className="text-[16px] text-[#878d93] font-medium pt-4  flex justify-center items-center">
                              Candidate does not have Tech skills.
                            </p>
                          )}
                        </div>
                        <div className="SoftSkills bg-white border rounded-md shadow-md  mt-6  ">
                          <div className="bg-[#E9AA06] flex justify-between items-center rounded-t-md p-2">
                            <h2 className="text-[16px] text-[#FFFFFF] font-medium ">
                              Soft Skills
                            </h2>
                            {/* <img
                              src={Edit}
                              alt=""
                              className=" cursor-pointer w-4 h-4"
                              // onClick={() => setOpenName(true)}
                            /> */}
                          </div>
                          {user?.content?.skill?.some(
                            (skill) => skill.type === "SOFT"
                          ) ? (
                            <div className="flex flex-wrap p-4 gap-2">
                              {user?.content?.skill?.map(
                                (title, index) =>
                                  title.type === "SOFT" && (
                                    <h2
                                      key={index}
                                      className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                                    >
                                      {title.name} {title.rating}/10
                                    </h2>
                                  )
                              )}
                            </div>
                          ) : (
                            <p className="text-[16px] text-[#878d93] font-medium pt-4 flex justify-center items-center">
                              Candidate does not have Soft skills.
                            </p>
                          )}
                        </div>
                        <div className="MarksShown bg-white border rounded-md shadow-md  mt-6 ">
                          <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                            Candidate Marks
                          </h2>
                          <div className="p-2">
                            <div className="flex items-center gap-1 py-2">
                              <h2 className="text-[16px] text-[#495057] font-semibold">
                                Total Marks:{" "}
                              </h2>
                              <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                                100
                              </p>
                            </div>
                            <div className="flex items-center gap-1 py-2">
                              <h2 className="text-[16px] text-[#495057] font-semibold">
                                Getting Marks:
                              </h2>
                              {user?.content?.technicalSkillTotal ? (
                                <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                                  {user?.content?.technicalSkillTotal +
                                    user?.content?.presentationTotal +
                                    user?.content?.proactiveTotal +
                                    user?.content?.caseStudyTotal +
                                    user?.content?.englishLanguageTotal +
                                    user?.content?.englishLanguageTotal +
                                    user?.content?.recruiterEmotionalTotal}
                                </p>
                              ) : (
                                <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                                  Interview pending
                                </p>
                              )}
                            </div>
                            <div className="flex items-center gap-1 py-2">
                              <h2 className="text-[16px] text-[#495057] font-semibold">
                                Assigned Badge:
                              </h2>
                              {user?.content?.subscription_tier ? (
                                <p className="text-[14px] capitalize pt-[2px]  text-center font-normal flex justify-center items-center text-[#696969]">
                                  {user?.content?.subscription_tier}
                                </p>
                              ) : (
                                <p className="text-[14px] capitalize pt-[2px]  text-center font-normal flex justify-center items-center text-[#696969]">
                                  interview Pending
                                </p>
                              )}
                            </div>
                            <div className="flex items-center gap-1 py-2">
                              <h2 className="text-[16px] text-[#495057] font-semibold">
                                Expected Salary:
                              </h2>
                              <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                                {user?.content?.expectedSalary} MYR
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="noticePeriod bg-white  border rounded-md shadow-md  mt-6">
                          <div className="bg-[#E9AA06] flex justify-between items-center rounded-t-md p-2">
                            <h2 className="text-[16px] text-[#FFFFFF] font-medium">
                              Notice Period
                            </h2>
                            {/* <img
                              src={Edit}
                              alt=""
                              className=" cursor-pointer w-4 h-4"
                              onClick={() => setOpenNotice(true)}
                            /> */}
                          </div>
                          <div className=" gap-2 p-4">
                            <h2 className="2xl:text-[13px] capitalize border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal">
                              {user?.content?.notice_period?.replace(/_/g, " ")}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <button
                        className="text-[20px] text-[#E9AA06] border-b"
                        onClick={() => setOpenPic(true)}
                      >
                        Upload Video
                      </button> */}
                  <div className="rightsection bg-inherit  mt-[39px]   pb-12 ">
                    {user?.content?.interview_video_url ? (
                      <video
                        controls
                        className="xl:w-full 2xl:w-full h-[30rem] border rounded-md shadow-md  object-cover"
                      >
                        <source
                          src={`${user?.content?.interview_video_url}`}
                          type="video/mp4"
                        />
                        Sorry, your browser doesn't support embedded videos.
                      </video>
                    ) : (
                      <div className="flex flex-col items-center  justify-center gap-2  ">
                        <h2 className="text-[20px] text-[#495057] font-medium text-center pt-2 ">
                          Video will be uploaded when Interview will be done
                        </h2>
                        {/* <button
                        className="text-[20px] text-[#E9AA06] border-b"
                        onClick={() => setOpenPic(true)}
                      >
                        Upload Video
                      </button> */}
                        <FileUpload api={UPLOAD_ZOOM_VIDEO} id={id} />
                      </div>
                    )}
                    <div className="flex justify-end mr-4 mt-2 ">
                      {user?.content?.interview_video_url && (
                        <button
                          className="bg-[#E9AA06] px-3 py-2 text-white rounded-md"
                          onClick={deleteVideo}
                        >
                          Delete Video
                        </button>
                      )}
                    </div>
                    <div className="aboutsection bg-white border rounded-md shadow-md  mt-6">
                      <div className="bg-[#E9AA06] flex justify-between items-center rounded-t-md p-2">
                        <h2 className="text-[16px] text-[#FFFFFF]  font-medium ">
                          About Me
                        </h2>
                        {showEditButton ?(
                        <img
                          src={Edit}
                          alt=""
                          className=" cursor-pointer w-4 h-4"
                          onClick={() => setOpenAbout(true)}
                        />
                        ):null}
                      </div>
                      <p className="text-[14px]  leading-[26px] py-2 px-6 text-[#696969] font-normal">
                        {user?.content?.about_me}
                      </p>
                    </div>

                    <div className="workexperience border rounded-md shadow-md  mt-6 relative bg-white  ">
                      <div className="bg-[#E9AA06] flex justify-between items-center rounded-t-md p-2">
                        <h2 className="text-[16px]  text-[#FFFFFF]  font-medium ">
                          Work Experience
                        </h2>
                        {showEditButton ?(
                        <img
                          src={Edit}
                          alt=""
                          className=" cursor-pointer w-4 h-4"
                          onClick={handleEditClick}
                        />
                        ):null}
                      </div>
                      <div>
                        {user?.content?.experience?.map((exp, index) => (
                          <div
                            key={index}
                            className="flex items-baseline justify-between  px-6 py-2 pb-[5px] bg-white  border-b-[1px] mb-2 border-[#eeeeee] "
                          >
                            <div>
                              <p className="text-[16px] font-medium leading-[20px] pt-1 pr-16 text-[#E9AA06] ">
                                {exp.designation} | {exp.company}
                              </p>
                              <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#696969]">
                                From:{exp.start_date} To:{exp.end_date}
                              </p>
                              <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#696969]">
                                {exp.description}
                              </p>
                            </div>
                            {button1Visible ? (
                              <div className="">
                                <img
                                  src={Editing}
                                  alt=""
                                  onClick={() => openEditModal(exp)}
                                  className="cursor-pointer"
                                />
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="education relative bg-white border rounded-md shadow-md  mt-6">
                      <div className="bg-[#E9AA06] flex justify-between items-center rounded-t-md p-2">
                        <h2 className="text-[16px] text-[#FFFFFF] font-medium">
                          Education
                        </h2>
                        {/* <img
                          src={Edit}
                          alt=""
                          className=" cursor-pointer w-4 h-4"
                          onClick={() => setOpenName(true)}
                        /> */}
                      </div>
                      <div>
                        {user?.content?.education?.map((edu, index) => (
                          <div
                            key={index}
                            className="flex items-baseline justify-between pt-2 px-6 mb-3 pb-[8px] border-solid border-b-[1px] bg-white"
                          >
                            <div>
                              <p className="text-[16px] font-medium leading-[25px]  pr-16 text-[#E9AA06] ">
                                {edu.degree} | {edu.university}
                              </p>
                              <p className="text-[14px] font-medium leading-[25px] pr-16 text-[#696969]">
                                From:{edu.start_date} TO:{edu.end_date}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="Question-answers pb-4 border rounded-md shadow-md  mt-6 bg-white">
                      <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                        Candidate Question Answers
                      </h2>

                      <div className="max-h-[25rem]  overflow-y-auto">
                        {user?.content?.question?.map((edu, index) => (
                          <div
                            key={index}
                            className="gap-2 items-baseline  my-3 px-4 border-b-[1px]   "
                          >
                            <div className="candidateNumber flex gap-2 items-start">
                              <h2 className="text-[14px]  md:pl-0 sm:pl-0 font-semibold text-[#495057] pt-[2px]">
                                {index < 9 ? `0${index + 1}:` : `${index + 1}:`}
                              </h2>
                              <p className="text-[14px] font-semibold leading-[25px] pb-2  pr-16 text-[#495057]">
                                {edu.Q_number}
                              </p>
                            </div>
                            <div>
                              <p className="text-[14px] font-medium leading-[25px] pb-2 pr-16 text-[#696969]">
                                <span className="text-[14px]  md:pl-0 sm:pl-0 font-semibold text-[#000000]">
                                  {" "}
                                  Ans:{" "}
                                </span>{" "}
                                {edu.Q_ans}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="Recruiter'sComment bg-white border rounded-md shadow-md  mt-6   ">
                      <h2 className="text-[16px] rounded-t-md text-[#FFFFFF] p-2 font-medium bg-[#E9AA06] ">
                        Recruiter's Comment
                      </h2>
                      {user?.content?.recruiter_comment ? (
                        <p className="text-[14px]  leading-[26px] p-6 text-[#696969] font-normal">
                          {user?.content?.recruiter_comment}
                        </p>
                      ) : (
                        <p className="text-[18px] capitalize text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                          First Interview Is Not Done Yet
                        </p>
                      )}
                    </div>

                    <div className="feedback relative bg-white  md:py-8 md:px-8 border rounded-md shadow-md  mt-6 ">
                      <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md p-2 font-medium bg-[#E9AA06]">
                        Recruiter's Feedback
                      </h2>
                      {feedbackData?.length > 0 ? (
                        <div>
                          <div className="flex items-baseline justify-between m-4  pt-[0px] mb-3 pb-[8px]  bg-white">
                            <table className="table-fixed border-collapse border  border-slate-400">
                              <thead className="">
                                <tr>
                                  <th className="border border-slate-300 text-left pl-4 font-medium py-2 bg-[#E9AA06] text-white">
                                    Questions
                                  </th>
                                  <th className="border border-slate-300 text-left font-medium py-2 pl-1 pr-4 bg-[#E9AA06] text-white">
                                    Points
                                  </th>
                                </tr>
                              </thead>

                              {feedbackData?.map((title, index) => (
                                ///add index but dont test
                                <tbody key={index}>
                                  <tr className="border-collapse border border-slate-400">
                                    <td className="border border-slate-300 py-3 px-4 text-[14px] text-[#696969]">
                                      {title.name}
                                    </td>
                                    <td className="border border-slate-300 py-2 pl-2 text-[14px] text-[#696969]">
                                      {title.rating}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </div>
                        </div>
                      ) : (
                        <p className="text-[18px] capitalize text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                          first interview is not done yet
                        </p>
                      )}
                    </div>
                    <div className="feedback bg-white border rounded-md shadow-md  mt-6  md:py-8 md:px-8 ">
                      <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md p-2 font-medium bg-[#E9AA06]">
                        Candidate's Marks Chart
                      </h2>
                      {feedbackData?.length > 0 ? (
                        <Chart
                          chartType="PieChart"
                          data={data}
                          options={options}
                          width={"99.9%"}
                          height={"400px"}
                        />
                      ) : (
                        <p className="text-[18px] capitalize text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                          Graph is shown when first interview will be completed
                        </p>
                      )}
                    </div>
                    {/* <div className="mt-5 flex justify-center items-center">
                    <button
                      onClick={() => navigate(-1)}
                      className="bg-black text-white border-2 flex gap-2 items-center border-black hover:bg-white hover:text-black px-10 py-2 rounded text-lg"
                    >
                      Back To Applications
                    </button>
                  </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        {/* ))} */}
      </div>
    </>
  );
};

export default CandidateProfileAdmin;
