import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInterviewRequest } from "../../../store/interviewRequestSlice/interviewRequestSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { EMPLOYER_GIVE_RESULT } from "../../../utils/baseUrl";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import Swal from "sweetalert2";
import { Chart } from "react-google-charts";
import Asterik from "../../../common/asterik";
import { IconMessagePlus } from "@tabler/icons-react";
import { IconStatusChange } from "@tabler/icons-react";
import { ToastContainer, toast } from "react-toastify";

const CandidateFeedback = ({ interviewapproval }) => {
  const [feedbackData, setFeedbackData] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
    q11: "",
    q12: "",
    interview_clear: "",
    subscription_tier: "",
    recruiter_comment: "",
  });
  const [myprofile, setMyProfile] = useState({});

  const techSkillScore =
    parseInt(feedbackData.q1) +
    parseInt(feedbackData.q2) +
    parseInt(feedbackData.q3) +
    parseInt(feedbackData.q4);
  const totalPossibleScore = 40; // Total possible score for technical skills

  const presentationSkillScore =
    parseInt(feedbackData.q5) + parseInt(feedbackData.q6);
  const proactiveSkillScore =
    parseInt(feedbackData.q7) + parseInt(feedbackData.q8);
  const caseStudySkillScore =
    parseInt(feedbackData.q9) + parseInt(feedbackData.q10);
  const languageSkillScore = parseInt(feedbackData.q11);
  const emotionalSkillScore = parseInt(feedbackData.q12);
  const emptyData = [
    ["Task", "Hours per Day"],
    ["Total Marks", 100],
  ];

  const data = [
    ["Task", "Hours per Day"],
    ["TECHNICAL SKILLS", techSkillScore],
    ["PRESENTATION SKILLS", presentationSkillScore],
    ["PROACTIVE & REACTIVE", proactiveSkillScore],
    ["CASE STUDIES", caseStudySkillScore],
    ["ENGLISH LANGUAGE", languageSkillScore],
    ["RECRUITER EMOTIONAL FEEL", emotionalSkillScore],
  ];

  // Calculate the total used percentage
  const totalUsedPercentage = data
    .slice(1)
    .reduce((acc, [, percentage]) => acc + percentage, 0);

  // Calculate the remaining percentage
  const remainingPercentage = 100 - totalUsedPercentage;
  // Add remaining percentage as empty data
  // if (totalUsedPercentage > 0) {
  data.push(["Remaining Marks", remainingPercentage]);
  // }

  const options = {
    Total_Marks: "100",
    Getting_Marks:totalUsedPercentage
  };

  const navigate = useNavigate();
  const [isApiCallSuccessful, setIsApiCallSuccessful] = useState(false);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [openPic, setOpenPic] = useState(false);
  const isLoading = useSelector((state) => state?.userSlice?.loading);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const location = useLocation();
  const receivedData = location.state?.item;
  const id = location.state?.item?._id;
  useEffect(() => {
    // Step 2: Update state variable with received feedback data
    if (receivedData) {
      setFeedbackData({
        q1: receivedData?.technicalSkill[0]?.rating || "",
        q2: receivedData?.technicalSkill[1]?.rating || "",
        q3: receivedData?.technicalSkill[2]?.rating || "",
        q4: receivedData?.technicalSkill[3]?.rating || "",
        q5: receivedData?.presentationSkill[0]?.rating || "",
        q6: receivedData?.presentationSkill[1]?.rating || "",
        q7: receivedData?.proactive[0]?.rating || "",
        q8: receivedData?.proactive[1]?.rating || "",
        q9: receivedData?.caseStudy[0]?.rating || "",
        q10: receivedData?.caseStudy[1]?.rating || "",
        q11: receivedData?.englishLanguage[0]?.rating || "",
        q12: receivedData?.recruiterEmotional[0]?.rating || "",
        interview_clear: receivedData?.interview_clear || "",
        subscription_tier: receivedData?.subscription_tier || "",
        recruiter_comment: receivedData?.recruiter_comment || "",
        // Add other fields here as per your form structure
      });
    }
  }, [receivedData]);
  const user = useSelector((state) => state?.AdminLogInSlice?.user?.content);

  const interview = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.content
  );
  useEffect(() => {
    if (interview?.length > 0) {
      setSearchPerformed(true);
    }
  }, [interview]);
  useEffect(() => {
    if (receivedData) {
      setMyProfile(receivedData);
    }
  }, [receivedData]);
  useEffect(() => {
    dispatch(
      fetchInterviewRequest({
        page: currentStep,
        approval: interviewapproval,
      })
    );
  }, [dispatch, interviewapproval, isApiCallSuccessful, currentStep]);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setMyProfile({
  //     ...myprofile,
  //     [name]: value,
  //   });
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData({
      ...feedbackData,
      [name]: value,
    });
  };

  const AddProfile = () => {
    const technicalSkill = [
      {
        name: "Does their experience match what's needed? (Work experience, life experience or volunteer work?)",
        rating: feedbackData.q1,
      },
      {
        name: "Do they have the required credentials? (Education, license or certification?)",
        rating: feedbackData.q2,
      },
      {
        name: "Does their technical skills match job requirements? (Specific technical tools, approaches, method?)",
        rating: feedbackData.q3,
      },
      {
        name: "Are they willing to be relocated/work in other country?(readiness to be working in other countries; i.e Singapore?)",
        rating: feedbackData.q4,
      },
    ];

    const presentationSkill = [
      {
        name: "Does their experience match what's needed? (Work experience, life experience or volunteer work?)",
        rating: feedbackData.q5,
      },
      {
        name: "Do they have the required credentials? (Education, license or certification?)",
        rating: feedbackData.q6,
      },
    ];
    const proactive = [
      {
        name: "How well did they answer customer service related questions? (Good listener, problem solver, proactive/reactive?)",
        rating: feedbackData.q7,
      },
      {
        name: "Do they have the characteristics to be a Leader? (staff organization, time management, decision making skills?)",
        rating: feedbackData.q8,
      },
    ];

    const caseStudy = [
      {
        name: "How thruthful and realistic are the past experiences shared ? (evidence of Ethics, earn Trust from team, life philosophy?)",
        rating: feedbackData.q9,
      },
      {
        name: "How do they hold to life values and work ethics? (evidence of Ethics, earn Trust from team, life philosophy?)",
        rating: feedbackData.q10,
      },
    ];
    const englishLanguage = [
      {
        name: "How good are their communication skills? (Written: resume, application, as well as verbal?)",
        rating: feedbackData.q11,
      },
    ];
    const recruiterEmotional = [
      {
        name: "Overall judgement on candidate's self projection? (implied body language, gesture, obvious verbal projection?)",
        rating: feedbackData.q12,
      },
    ];

    const {
      q1,
      q2,
      q3,
      q4,
      q5,
      q6,
      q7,
      q8,
      q9,
      q10,
      q11,
      q12,
      ...restProfile
    } = myprofile;

    const payload = {
      subscription_tier: feedbackData.subscription_tier,
      interview_clear: feedbackData.interview_clear,
      recruiter_comment: feedbackData.recruiter_comment,
      technicalSkill: technicalSkill,
      presentationSkill: presentationSkill,
      proactive: proactive,
      caseStudy: caseStudy,
      englishLanguage: englishLanguage,
      recruiterEmotional: recruiterEmotional,
    };

    // for (const key in payload) {
    //   if (payload[key]?.trim() === "") {
    //     toast(`Please Fill all the Fields.`);
    //     // alert(`Please fill the ${key} field.`);
    //     return; // Exit the function if any field is empty
    //   }
    // }
    for (const key in payload) {
      if (typeof payload[key] === 'string' && payload[key].trim() === "") {
        toast(`Please Fill all the Fields.`);
        return; // Exit the function if any field is empty
      }
    }
    axios
      .post(EMPLOYER_GIVE_RESULT(id), payload)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setOpenPic(false);
          // navigate("/interview-dashboard?approval=PENDING");
          navigate(-1);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        setOpenPic(false);
      });
  };
  return (
    <>
      <div className="Onboarding  py-12">
      {toast && <ToastContainer />}
        <h2 className="text-[#495057] text-[16px] mb-12">HR Feedback</h2>
        <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
          <div className="items-center  mt-[1rem]">
            {/* ///////////////////////Candidate Details/////////////////////////////// */}
            <div className="mt-3 mb-10 md:mb-0 sm:ml-4 sm:mt-0 sm:text-left ">
              <div className=" items-center  sm:mx-2 mt-[1rem]">
                <div className="bg-white rounded-2xl border border-[#dddddd]">
                  <h2 className="text-white bg-black pl-4 py-4  font-semibold text-[16px]">
                    Candidate Details
                  </h2>
                  <div className="grid grid-cols-[1.5fr_2fr] md:grid-cols-[1fr] sm:grid-cols-[1fr] items-center justify-evenly gap-8 md:gap-4 mt-2  pl-4 py-4 ">
                    <div>
                      <label
                        className=" text-black-500 text-[16px] sm:text-[14px] mb-2 flex items-center gap-2"
                        htmlFor="interview_number"
                      >
                        First Name:{" "}
                        <span className="text-gray-500 capitalize">
                          {receivedData?.first_name} {receivedData?.last_name}
                        </span>
                      </label>
                    </div>
                    <div>
                      <label
                        className=" text-black-500 text-[16px] sm:text-[14px] mb-2 flex items-center gap-2"
                        htmlFor="interview_number"
                      >
                        Interviewer Name:{" "}
                        <span className="text-gray-500 capitalize">
                          {user?.customer?.first_name}{" "}
                          {user?.customer?.last_name}
                        </span>
                      </label>
                    </div>
                    <div>
                      <label
                        className=" text-black-500 text-[16px] sm:text-[14px] mb-2 flex items-center gap-2"
                        htmlFor="interview_number"
                      >
                        Job Position:{" "}
                        <span className="text-gray-500">
                          {receivedData?.title}
                        </span>
                      </label>
                    </div>
                    <div>
                      <label
                        className=" text-black-500 text-[16px] sm:text-[14px] mb-2 flex items-center gap-2"
                        htmlFor="interview_number"
                      >
                        Date/Time Interview:{" "}
                        <span className="text-gray-500">
                          {receivedData?.zoom_meeting_date}/
                          {receivedData?.zoom_meeting_time}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white mb-4  border border-[#dddddd] rounded-2xl">
              <div className=" sm:ml-4 sm:mt-0 sm:text-left ">
                <div className=" items-center sm:mx-2 ">
                  <h2 className=" text-white bg-black pl-4 py-4  font-semibold text-[16px]">
                    Technical Skills
                  </h2>
                </div>
              </div>

              <div className=" grid grid-cols-[1fr] items-center justify-evenly gap-14  mx-8  rounded-2xl pt-4   md:w-[80%] sm:py-[6.5rem] sm:pr-[0rem] sm:w-[100%] sm:bg-inherit ">
                <div className=" container sm:max-w-[85%] sm:ml-4 ">
                  <div className="w-full h-auto mb-8  grid grid-cols-[3fr_1fr] items-center">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q1"
                    >
                      <p className="font-bold mr-2">01: </p>
                      Does their experience match what's needed? (Work
                      experience, life experience or volunteer work?)
                    </label>
                    <select
                      id="q1"
                      name="q1"
                      // value={myprofile.q1}
                      value={feedbackData.q1}
                      onChange={handleInputChange}
                      className=" appearance-none border ml-4 text-[14px] rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-80% h-auto mb-8  grid grid-cols-[3fr_1fr] items-center">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q1"
                    >
                      <p className="font-bold mr-2">02: </p>
                      Do they have the required credentials? (Education, license
                      or certification?)
                    </label>

                    <select
                      id="q2"
                      name="q2"
                      value={feedbackData.q2}
                      onChange={handleInputChange}
                      className=" appearance-none border ml-4 text-[14px] rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-80% h-auto mb-8  grid grid-cols-[3fr_1fr] items-center">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q1"
                    >
                      <p className="font-bold mr-2">03: </p>
                      Does their technical skills match job requirements?
                      (Specific technical tools, approaches, method?)
                    </label>

                    <select
                      id="q3"
                      name="q3"
                      value={feedbackData.q3}
                      onChange={handleInputChange}
                      className=" appearance-none border ml-4 text-[14px] rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-80% h-auto mb-8  grid grid-cols-[3fr_1fr] items-center">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q1"
                    >
                      <p className="font-bold mr-2">04: </p>
                      Are they willing to be relocated/work in other country?
                      (readiness to be working in other countries; i.e
                      Singapore?)
                    </label>

                    <select
                      id="q4"
                      name="q4"
                      value={feedbackData.q4}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* ///////////////////////    PRESENTATION SKILLS/////////////////////////////// */}
            <div className="bg-white mb-4 border border-[#dddddd] rounded-2xl">
              <div className=" sm:ml-4 sm:mt-0 sm:text-left ">
                <div className=" items-center sm:mx-2 border-[#dddddd]">
                  <h2 className="text-white bg-black pl-4 py-4  font-semibold text-[16px]">
                    Presentation Skills
                  </h2>
                </div>
              </div>

              <div className=" grid grid-cols-[1fr] items-center justify-evenly gap-14 mt-4 mx-8  mb-[1rem] rounded-2xl py-[1rem]    md:w-[80%] sm:py-[6.5rem] sm:pr-[0rem] sm:w-[100%] sm:bg-inherit ">
                <div className=" container sm:max-w-[85%] sm:ml-4 ">
                  <div className="w-full h-auto mb-8  grid grid-cols-[3fr_1fr] items-center ">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q5"
                    >
                      <p className="font-bold mr-2">01: </p>
                      Was the candidate prepared for the interview? (Research
                      company, dressed appropriately, arrived on time?)
                    </label>

                    <select
                      id="q5"
                      name="q5"
                      value={feedbackData.q5}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-80% h-auto  grid grid-cols-[3fr_1fr] items-center">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q6"
                    >
                      <p className="font-bold mr-2">02: </p>
                      Do they expressed and shared the working ideas clearly?
                      (explain well on their tasks and deliverables?)
                    </label>

                    <select
                      id="q6"
                      name="q6"
                      value={feedbackData.q6}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* ///////////////////////    Proactive SKILLS/////////////////////////////// */}
            <div className="bg-white mb-4 border border-[#dddddd]  rounded-2xl">
              <div className=" sm:ml-4 sm:mt-0 sm:text-left ">
                <div className=" items-center  sm:mx-2 border-[#dddddd]">
                  <h2 className="text-white bg-black pl-4 py-4  font-semibold text-[16px]">
                    Proactive & Reactive
                  </h2>
                </div>
              </div>

              <div className=" grid grid-cols-[1fr] items-center justify-evenly gap-14 mt-4 mx-8  mb-[1rem] rounded-2xl py-[1rem]  md:w-[80%] sm:py-[6.5rem] sm:pr-[0rem] sm:w-[100%] sm:bg-inherit ">
                <div className=" container sm:max-w-[85%] sm:ml-4 ">
                  <div className="w-full h-auto mb-8  grid grid-cols-[3fr_1fr] items-center ">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q7"
                    >
                      <p className="font-bold mr-2">01: </p>
                      How well did they answer customer service related
                      questions? (Good listener, problem solver,
                      proactive/reactive?)
                    </label>

                    <select
                      id="q7"
                      name="q7"
                      value={feedbackData.q7}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-80% h-auto   grid grid-cols-[3fr_1fr] items-center">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q8"
                    >
                      <p className="font-bold mr-2">02: </p>
                      Do they have the characteristics to be a Leader? (staff
                      organization, time management, decision making skills?)
                    </label>

                    <select
                      id="q8"
                      name="q8"
                      value={feedbackData.q8}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* ///////////////////////Case Studies/////////////////////////////// */}
            <div className="bg-white mb-4 border border-[#dddddd] rounded-2xl">
              <div className=" sm:ml-4 sm:mt-0 sm:text-left ">
                <div className=" items-center  sm:mx-2  border-[#dddddd]">
                  <h2 className="text-white bg-black pl-4 py-4  font-semibold text-[16px]">
                    Case Studies
                  </h2>
                </div>
              </div>

              <div className=" grid grid-cols-[1fr] items-center justify-evenly gap-14 mt-4 mx-8  mb-[1rem] rounded-2xl py-[1rem]    md:w-[80%] sm:py-[6.5rem] sm:pr-[0rem] sm:w-[100%] sm:bg-inherit ">
                <div className=" container sm:max-w-[85%] sm:ml-4 ">
                  <div className="w-full h-auto mb-8  grid grid-cols-[3fr_1fr] items-center ">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q9"
                    >
                      <p className="font-bold mr-2">01: </p>
                      How thruthful and realistic are the past experiences
                      shared ? (evidence of Ethics, earn Trust from team, life
                      philosophy?)
                    </label>

                    <select
                      id="q9"
                      name="q9"
                      value={feedbackData.q9}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-80% h-auto grid grid-cols-[3fr_1fr] items-center">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q10"
                    >
                      <p className="font-bold mr-2">02: </p>
                      How do they hold to life values and work ethics? (evidence
                      of Ethics, earn Trust from team, life philosophy?)
                    </label>

                    <select
                      id="q10"
                      name="q10"
                      value={feedbackData.q10}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* /////////////////////// ENGLISH LANGUAGE/////////////////////////////// */}
            <div className="bg-white mb-4 border border-[#dddddd] rounded-2xl">
              <div className="sm:ml-4 sm:mt-0 sm:text-left ">
                <div className=" items-center  sm:mx-2 ">
                  <h2 className=" text-white bg-black  pl-4 py-4 font-semibold text-[16px]">
                    English Language
                  </h2>
                </div>
              </div>

              <div className=" grid grid-cols-[1fr] items-center justify-evenly gap-14 mt-4 mx-8  mb-[1rem] rounded-2xl py-[1rem]    md:w-[80%] sm:py-[6.5rem] sm:pr-[0rem] sm:w-[100%] sm:bg-inherit ">
                <div className=" container sm:max-w-[85%] sm:ml-4 ">
                  <div className="w-full h-auto   grid grid-cols-[3fr_1fr] items-center ">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q11"
                    >
                      <p className="font-bold mr-2">01: </p>
                      How good are their communication skills? (Written: resume,
                      application, as well as verbal?)
                    </label>

                    <select
                      id="q11"
                      name="q11"
                      value={feedbackData.q11}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* /////////////////////// RECRUITER EMOTIONAL FEEL/////////////////////////////// */}
            <div className="bg-white mb-8 border border-[#dddddd] rounded-2xl">
              <div className=" sm:ml-4 sm:mt-0 sm:text-left ">
                <div className=" items-center sm:mx-2  border-[#dddddd]">
                  <h2 className="text-white bg-black pl-4 py-4  font-semibold text-[16px]">
                    Recruiter Emotional Feel
                  </h2>
                </div>
              </div>

              <div className=" grid grid-cols-[1fr] items-center justify-evenly gap-14 mt-4 mx-8  mb-[1rem] rounded-2xl py-[1rem]    md:w-[80%] sm:py-[6.5rem] sm:pr-[0rem] sm:w-[100%] sm:bg-inherit ">
                <div className=" container sm:max-w-[85%] sm:ml-4 ">
                  <div className="w-full h-auto grid grid-cols-[3fr_1fr] items-center ">
                    <label
                      className=" text-black-500 text-[14px] mb-2 flex items-start text-left"
                      htmlFor="q12"
                    >
                      <p className="font-bold mr-2">01: </p>
                      Overall judgement on candidate's self projection? (implied
                      body language, gesture, obvious verbal projection?)
                    </label>
                    <select
                      id="q12"
                      name="q12"
                      value={feedbackData.q12}
                      onChange={handleInputChange}
                      className=" appearance-none border text-[14px] ml-4 rounded py-3 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-center"
                      >
                        Give Score
                      </option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* /////////////////////// Other Details/////////////////////////////// */}
            <div className="mt-3 mb-10 sm:ml-4 sm:mt-0 sm:text-left ">
              <div className=" items-center  sm:mx-2 mt-[1rem]">
                <div className="">
                  <div className="grid grid-cols-[0.75fr_0.75fr] md:grid-cols-[1fr] sm:grid-cols-[1fr] items-center justify-evenly gap-14 mt-4">
                    <div>
                      <label
                        className=" text-black-500 text-[16px] sm:text-[14px] mb-2 flex items-center gap-2"
                        htmlFor="subscription_tier"
                      >
                        <IconStatusChange /> Assign Badge <Asterik />
                      </label>
                      <select
                        id="subscription_tier"
                        name="subscription_tier"
                        value={feedbackData?.subscription_tier}
                        onChange={handleInputChange}
                      
                        className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value="" disabled selected>
                          Choose One
                        </option>
                        <option value="Bronze">Bronze</option>
                        <option value="Silver">Silver</option>
                        <option value="Gold">Gold</option>
                        <option value="Platinum">Platinum</option>
                        <option value="Titanium">Titanium</option>
                      </select>
                    </div>
                    <div>
                      <label
                        className=" text-black-500 text-[16px] sm:text-[14px] mb-2 flex items-center gap-2"
                        htmlFor="interview_number"
                      >
                        <IconStatusChange /> Interview Status
                      </label>
                      <select
                        id="interview_clear"
                        name="interview_clear"
                        value={feedbackData?.interview_clear}
                        onChange={handleInputChange}
                     
                        className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value="" disabled selected>
                          Set status
                        </option>
                        <option value="HOLD_ON">On Hold</option>
                        <option value="CLEAR">Accepted</option>
                        <option value="NO_CLEAR">Rejected</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label
                className=" text-black-500 text-[16px] sm:text-[14px] mb-2 flex items-center gap-2"
                htmlFor="recruiter_comment"
              >
                <IconMessagePlus /> Comment About Candidate <Asterik />
              </label>

              <textarea
                rows={7}
                type="text"
                id="recruiter_comment"
                placeholder="Tell about yourself..."
                name="recruiter_comment"
                value={feedbackData?.recruiter_comment}
                onChange={handleInputChange}
             
                className=" appearance-none border rounded text-[14px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="border border-[#dddddd] mb-8 ">
              <h2 className="text-white bg-black pl-4 py-4  font-semibold text-[16px]">
                Candidate Total Marks
              </h2>
              {!isNaN(techSkillScore) ? (
                <Chart
                  chartType="PieChart"
                  data={data}
                  options={options}
                  width={"99.9%"}
                  height={"400px"}
                />
              ) : (
                <Chart
                  chartType="PieChart"
                  data={emptyData}
                  // options={options}
                  width={"99.9%"}
                  height={"400px"}
                />
              )}
            </div>
            <div className="flex justify-center gap-8">
              <button
                onClick={() => AddProfile()}
                className="bg-black text-white px-16  sm:px-6 py-2 rounded text-lg hover:bg-white hover:text-black border-[1px]"
              >
                Submit Feedback
              </button>
              <button
                onClick={() => AddProfile()}
                className="bg-black text-white px-16  sm:px-6 py-2 rounded text-lg hover:bg-white hover:text-black border-[1px]"
              >
                Save and Exit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateFeedback;
