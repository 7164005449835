// zoomSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meetingArgs: {
    sessionName: 'interview',
    userIdentity: 'Qasim',
    sessionKey: '123',
    role: 0,
  },
};

const zoomSlice = createSlice({
  name: 'zoom',
  initialState,
  reducers: {
    updateMeetingArgs: (state, action) => {
      state.meetingArgs = action.payload;
    },
  },
});

export const { updateMeetingArgs } = zoomSlice.actions;
export default zoomSlice.reducer;
