import gold from "../../assets/images/Gold.png";
import silver from "../../assets/images/Silver.png";
import bronze from "../../assets/images/Bronze.png";
import titanium from "../../assets/images/Titanium.png";
import platanium from "../../assets/images/Platinum.png";
export const renderBadge = (subscription_tier) => {
    if (subscription_tier === "Gold") {
      return <img src={gold} alt="Gold Badge" className="badge" />;
    } else if (subscription_tier === "Silver") {
      return <img src={silver} alt="Silver Badge" className="badge" />;
    } else if (subscription_tier === "Bronze") {
      return <img src={bronze} alt="Bronze Badge" className="badge" />;
    } else if (subscription_tier === "Platinum") {
      return <img src={platanium} alt="Bronze Badge" className="badge" />;
    } else if (subscription_tier === "Titanium") {
      return <img src={titanium} alt="Bronze Badge" className="badge" />;
    } else {
      return null;
    }
  };