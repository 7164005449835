import React, { useEffect, useState } from "react";

import Flag from "../../../assets/images/Flag.png";
import { BASE_URL } from "../../../utils/baseUrl";
import { useDispatch } from "react-redux";
import logo from "../../../assets/images/logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import VerifiedButton from "../../../common/verifiedIcon/verifiedIcon";
import Chart from "react-google-charts";

const CandidatePublicProfile = () => {
  const location = useLocation();
  const isLoading = useSelector((state) => state?.userSlice?.loading);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("candidate");

  const [user, setUser] = useState();
  console.log("🚀 ~ CandidatePublicProfile ~ user:", user);

  useEffect(() => {
    const apiUrl = `/public/profile?userId=${id}`;

    axios
      .get(apiUrl)
      .then((response) => {
        setUser(response?.data?.content);
      })
      .catch((error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
      });
  }, [id]);
/////////////////////////for feedback//////////////////////
const data = [
  ["Task", "Hours per Day"],
  ["TECHNICAL SKILLS", user?.technicalSkillTotal],
  ["PRESENTATION SKILLS", user?.presentationTotal],
  ["PROACTIVE & REACTIVE", user?.proactiveTotal],
  ["CASE STUDIES", user?.caseStudyTotal],
  ["ENGLISH LANGUAGE", user?.englishLanguageTotal],
  ["RECRUITER EMOTIONAL FEEL", user?.recruiterEmotionalTotal],
];
const totalUsedPercentage = data
  .slice(1)
  .reduce((acc, [, percentage]) => acc + percentage, 0);
const options = {
  Total_Marks: "100",
  Getting_Marks: totalUsedPercentage,
};
const feedbackData = [].concat(
  user?.technicalSkill,
  user?.presentationSkill,
  user?.proactive,
  user?.caseStudy,
  user?.englishLanguage,
  user?.recruiterEmotional
);
  return (
    <>
      <div className="container mx-auto py-4">
        <img src={logo} />
      </div>
      <div className="bg-[#F1F5F9]">
        {user && (
          <div className="container mx-auto  ">
            <div className="">
              <div className="grid 2xl:grid-cols-[387px_1fr] xl:grid-cols-[300px_1fr] lg:grid-cols-[280px_1fr] md:grid-cols-1   gap-8">
                <div>
                  <div className="leftsection  flex flex-col justify-between  py-12 dashboard-background">
                    <div className="top">
                      <div className="candidateprofile bg-white border rounded-md shadow-md py-8">
                        <img
                          src={`${BASE_URL}/profile/${user?.profile_picture}`}
                          alt="profilePicture"
                          className="w-[90px] h-[90px] rounded-full object-cover mx-auto"
                        />
                        <div className="candidateinfo  pb-4   px-2 flex flex-col items-center justify-center  ">
                          <p className="text-[16px] font-normal text-center text-[#777777]">
                            {user?.title}
                          </p>
                        </div>
                      </div>
                      <div className="candidateinfo bg-white border rounded-md shadow-md  mt-6">
                        <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md  p-2 font-medium bg-[#E9AA06]">
                          Profile Overview
                        </h2>
                        {/* <p className="text-sm font-normal text-[#696969]">
                        {user?.email}
                      </p> */}

                        <div className="flex gap-1 p-4">
                          <h2 className="text-[16px] text-[#495057] font-semibold">
                            Country:{" "}
                          </h2>
                          <p className="text-[14px] pt-[2px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                            {user?.country}
                          </p>
                        </div>
                      </div>

                      <div className="languages bg-white border rounded-md shadow-md  mt-6 ">
                        <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md p-2 font-medium bg-[#E9AA06]">
                          Languages
                        </h2>
                        <div className="  gap-2 p-4  flex  flex-wrap">
                          {user?.language?.map((item, index) => (
                            <h2
                              key={index}
                              className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                            >
                              {item.lan}
                            </h2>
                          ))}
                        </div>
                      </div>
                      <div className="TechSkills bg-white border rounded-md shadow-md  mt-6 ">
                        <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                          Tech Skills
                        </h2>

                        {user?.skill?.some((skill) => skill.type === "TECH") ? (
                          <div className="flex flex-wrap gap-2 p-4">
                            {user?.skill?.map(
                              (title, index) =>
                                title.type === "TECH" && (
                                  <h2
                                    key={index}
                                    className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                                  >
                                    {title.name} {title.rating}/10
                                  </h2>
                                )
                            )}
                          </div>
                        ) : (
                          <p className="text-[22px] text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                            Candidate does not have Tech skills.
                          </p>
                        )}
                      </div>
                      <div className="SoftSkills bg-white border rounded-md shadow-md  mt-6  ">
                        <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                          Soft Skills
                        </h2>

                        {user?.skill?.some((skill) => skill.type === "SOFT") ? (
                          <div className="flex flex-wrap p-4 gap-2">
                            {user?.skill?.map(
                              (title, index) =>
                                title.type === "SOFT" && (
                                  <h2
                                    key={index}
                                    className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                                  >
                                    {title.name} {title.rating}/10
                                  </h2>
                                )
                            )}
                          </div>
                        ) : (
                          <p className="text-[22px] text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                            Candidate does not have Soft skills.
                          </p>
                        )}
                      </div>
                      {/* <div className="MarksShown bg-white border rounded-md shadow-md  mt-6 ">
                    <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                        Candidate Marks
                      </h2>
                      <div className="flex items-center gap-1 py-2">
                        <h2 className="text-[16px] text-[#495057] font-semibold">
                          Total Marks:{" "}
                        </h2>
                        <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                          100
                        </p>
                      </div>
                      <div className="flex items-center gap-1 py-2">
                        <h2 className="text-[16px] text-[#495057] font-semibold">
                          Getting Marks:
                        </h2>
                        <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                          {user?.technicalSkillTotal +
                            user?.presentationTotal +
                            user?.proactiveTotal +
                            user?.caseStudyTotal +
                            user?.englishLanguageTotal +
                            user?.recruiterEmotionalTotal}
                        </p>
                      </div>
                    </div> */}
                      <div className="MarksShown bg-white border rounded-md shadow-md  mt-6 ">
                        <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                          Candidate Marks
                        </h2>
                        <div className="p-2">
                          <div className="flex items-center gap-1 py-2">
                            <h2 className="text-[16px] text-[#495057] font-semibold">
                              Total Marks:{" "}
                            </h2>
                            <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                              100
                            </p>
                          </div>
                          <div className="flex items-center gap-1 py-2">
                            <h2 className="text-[16px] text-[#495057] font-semibold">
                              Getting Marks:
                            </h2>
                            {user?.technicalSkillTotal ? (
                              <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                                {user?.technicalSkillTotal +
                                  user?.presentationTotal +
                                  user?.proactiveTotal +
                                  user?.caseStudyTotal +
                                  user?.englishLanguageTotal +
                                  user?.recruiterEmotionalTotal}
                              </p>
                            ) : (
                              <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                                Interview pending
                              </p>
                            )}
                          </div>
                          <div className="flex items-center gap-1 py-2">
                            <h2 className="text-[16px] text-[#495057] font-semibold">
                              Assigned Badge:
                            </h2>
                            {user?.subscription_tier ? (
                              <p className="text-[14px] capitalize pt-[2px]  text-center font-normal flex justify-center items-center text-[#696969]">
                                {user?.subscription_tier}
                              </p>
                            ) : (
                              <p className="text-[14px] capitalize pt-[2px]  text-center font-normal flex justify-center items-center text-[#696969]">
                                interview Pending
                              </p>
                            )}
                          </div>
                          <div className="flex items-center gap-1 py-2">
                            <h2 className="text-[16px] text-[#495057] font-semibold">
                              Expected Salary:
                            </h2>
                            <p className="text-[14px] pt-[3px] capitalize text-center font-normal flex justify-center items-center text-[#696969]">
                              {user?.expectedSalary} MYR
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="noticePeriod bg-white  border rounded-md shadow-md  mt-6">
                        <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                          Notice Period
                        </h2>
                        <div className=" gap-2 p-4">
                          <h2 className="2xl:text-[13px] capitalize border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal">
                            {user?.notice_period?.replace(/_/g, " ")}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightsection bg-inherit  mt-[48px]   pb-12 ">
                  {user?.interview_video_url ? (
                    <video
                      controls
                      className="xl:w-full 2xl:w-full h-[30rem] border rounded-md shadow-md  object-cover"
                    >
                      <source
                        src={`${user?.interview_video_url}`}
                        type="video/mp4"
                      />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  ) : (
                    <>
                      <h2 className="text-[20px] text-[#495057] font-medium text-center pt-2 ">
                        Video will be uploaded when Interview will be done
                      </h2>
                    </>
                  )}
                  {/* <div className="aboutsection  rounded-md p-6">
                  <h2 className="text-[20px] text-[#495057] font-semibold ">
                    About Me
                  </h2>
                  <p className="text-[14px]  leading-[26px] pt-2 pr-16 text-[#696969] font-normal">
                    {user?.about_me}
                  </p>
                </div> */}
                  <div className="aboutsection bg-white border rounded-md shadow-md  mt-6">
                    <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                      About Me
                    </h2>
                    <p className="text-[14px]  leading-[26px] py-2 px-6 text-[#696969] font-normal">
                      {user?.about_me}
                    </p>
                  </div>
                  <div className="workexperience border rounded-md shadow-md  mt-6 relative bg-white  ">
                    <h2 className="text-[16px] rounded-t-md text-[#FFFFFF] p-2 font-medium bg-[#E9AA06]">
                      Work Experience
                    </h2>

                    <div>
                      {user?.experience?.map((exp, index) => (
                        <div
                          key={index}
                          className="flex items-baseline justify-between  px-6 py-2 pb-[5px] bg-white  border-b-[1px] mb-2 border-[#eeeeee] "
                        >
                          <div>
                            <p className="text-[16px] font-medium leading-[20px] pt-1 pr-16 text-[#E9AA06] ">
                              {exp.designation} | {exp.company}
                            </p>
                            <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#696969]">
                              From:{exp.start_date} To:{exp.end_date}
                            </p>
                            <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#696969]">
                              {exp.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>


                  <div className="education relative bg-white border rounded-md shadow-md  mt-6">
                    <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md p-2 font-medium bg-[#E9AA06]">
                      Education
                    </h2>

                    <div>
                      {user?.education?.map((edu, index) => (
                        <div
                          key={index}
                          className="flex items-baseline justify-between pt-2 px-6 mb-3 pb-[8px] border-solid border-b-[1px] bg-white"
                        >
                          <div>
                            <p className="text-[16px] font-medium leading-[25px]  pr-16 text-[#E9AA06] ">
                              {edu.degree} | {edu.university}
                            </p>
                            <p className="text-[14px] font-medium leading-[25px] pr-16 text-[#696969]">
                              From:{edu.start_date} TO:{edu.end_date}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <div className="Question-answers pb-4 border rounded-md shadow-md  mt-6 bg-white">
                    <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                      Candidate Question Answers
                    </h2>

                    <div className="max-h-[25rem]  overflow-y-auto">
                      {user?.question?.map((edu, index) => (
                        <div
                          key={index}
                          className="gap-2 items-baseline  my-3 px-4 border-b-[1px]   "
                        >
                          <div className="candidateNumber flex gap-2 items-start">
                            <h2 className="text-[14px]  md:pl-0 sm:pl-0 font-semibold text-[#495057] pt-[2px]">
                              {index < 9 ? `0${index + 1}:` : `${index + 1}:`}
                            </h2>
                            <p className="text-[14px] font-semibold leading-[25px] pb-2  pr-16 text-[#495057]">
                              {edu.Q_number}
                            </p>
                          </div>
                          <div>
                            <p className="text-[14px] font-medium leading-[25px] pb-2 pr-16 text-[#696969]">
                              <span className="text-[14px]  md:pl-0 sm:pl-0 font-semibold text-[#000000]">
                                {" "}
                                Ans:{" "}
                              </span>{" "}
                              {edu.Q_ans}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}
                  <div className="Recruiter'sComment bg-white border rounded-md shadow-md  mt-6   ">
                    <h2 className="text-[16px] rounded-t-md text-[#FFFFFF] p-2 font-medium bg-[#E9AA06] ">
                      Recruiter's Comment
                    </h2>
                    {user?.recruiter_comment ?(
                    <p className="text-[14px]  leading-[26px] p-6 text-[#696969] font-normal">
                      {user?.recruiter_comment}
                    </p>
                    ):(
                      <p className="text-[18px] capitalize text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                        First Interview Is Not Done Yet
                      </p>
                    )}
                  </div>
                  <div className="chart relative bg-white border rounded-md shadow-md  mt-6">
                    <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md p-2 font-medium bg-[#E9AA06]">
                        Candidate's Marks Chart
                      </h2>
                      {feedbackData?.length > 0 ? (
                        <Chart
                          chartType="PieChart"
                          data={data}
                          options={options}
                          width={"99.9%"}
                          height={"400px"}
                        />
                      ) : (
                        <p className="text-[18px] capitalize text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                          Graph is shown when first interview will be completed
                        </p>
                      )}
                    </div>
                </div>
              </div>
            </div>
          
          </div>
        )}
      </div>
    </>
  );
};

export default CandidatePublicProfile;
