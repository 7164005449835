import React, { Fragment, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../common/pagination/pagination";
import { useNavigate } from "react-router-dom";
import {

  BASE_URL,
  EMPLOYER_SHORTLISTED_CANDIDATES,

} from "../../../utils/baseUrl";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import Swal from "sweetalert2";
import { Dialog, Transition } from "@headlessui/react";
import crossicon from "../../../assets/images/Icons/crossicon.svg";
import Skeleton from "react-loading-skeleton";
import { employerInterviewRequest } from "../../../store/employerInterviewSlice/employerInterviewSlice";

const EmployerPendingInterviews = ({ interviewapproval, interviewStatus }) => {
  const navigate = useNavigate();

  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [openPic, setOpenPic] = useState(false);
  const isLoading = useSelector((state) => state?.userSlice?.loading);
  const [searchPerformed, setSearchPerformed] = useState(false);
const [employerId,setEmployerId]=useState()

  const interview = useSelector(
    (state) => state?.employerInterviewSlice?.user?.content
  );

  useEffect(() => {
    if (interview?.length > 0) {
      setSearchPerformed(true);
    }
  }, [interview]);

  useEffect(() => {
    dispatch(employerInterviewRequest());
  }, [dispatch]);

 
  const [open, setOpen] = useState(false);
  const [candidateData, setCandidateData] = useState();
  const [employerData, setEmployerData] = useState([]);
  const [employerOpen, setEmployerOpen] = useState(false);
  const [data,setData]=useState("")

  const viewCandidates = (id) => {
    setOpen(true);
    axios
      .get(EMPLOYER_SHORTLISTED_CANDIDATES(id))
      .then((response) => {
        setEmployerData(response?.data?.content);
     
      })
      .catch((error) => {
        setOpenPic(false);
      });
  };
 
  
  
  const shortlistedCandidates = employerData?.filter(item => {
   
    return item?.hired_point?.some(point => point.type === "selected" && point.userId===employerId);
  });
  const interestedCandidates = employerData?.filter(item => {
   
    return item?.hired_point?.some(point => point.type === "interested" && point.userId===employerId);
  });
  return (
    <>
      {/* modal for candidate */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" overflow-y-auto relative transform w-[35%] md:w-[50%] sm:w-[60%] max-h-[450px] lg:h-[500px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8">
                  <div className=" sm:items-start">
                    <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-medium leading-6 text-gray-900"
                      >
                        Candidate
                      </Dialog.Title>
                      <img
                        src={crossicon}
                        alt=""
                        className="pr-10 cursor-pointer"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      />
                    </div>
                    {employerData?.length === 0 ? (
                      <div
                        className="skeleton-loader"
                        style={{ lineHeight: 2, marginLeft: "7rem" }}
                      >
                        <Skeleton width={300} count={6} />
                      </div>
                    ) : (
                      <>
                      {data==="shortlist" ?(
                      <div className="pt-4  grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-4">
                        {shortlistedCandidates?.map((item, index) => (
                          <div key={index} className="">
                            <div className=" rounded-lg mb-4">
                              <div className="profile bg-white rounded-lg">
                                <div className="flex justify-between px-[2rem] md:flex-col   pt-[5px] pb-[10px] border-solid border-b-2 border-[#eeeeee] sm:flex-col items-center sm:justify-center md:justify-center gap-2 md:border-b-2 sm:border-b-2 md:pb-4 sm:pb-4">
                                  <div className="profileimg flex items-center gap-2">
                                    <img
                                      src={`${BASE_URL}/profile/${item?.profile_picture}`}
                                      alt="profilePicture"
                                      className="w-[50px] h-[50px] rounded-full object-cover mx-auto"
                                    />
                                    <h2 className="2xl:text-[14px] capitalize xl:text-[14px] lg:text-[14px] font-medium">
                                      {item?.first_name} {item?.last_name}
                                    </h2>
                                  </div>
                                  <button
                                    onClick={() => {
                                      navigate(
                                        `/candidate-profile-admin/${item._id}`,
                                        {
                                          state: { item: item },
                                        }
                                      );
                                    }}
                                    className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[10px]"
                                  >
                                    View Profile
                                  </button>

                                  {/* <button
                                className="bg-white ml-5 hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[10px]"
                                onClick={() => {
                                  setOpen(true);
                                  setCandidateData(item);
                                }}
                              >
                                View Profile
                              </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ):(
                      <div className="pt-4  grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-4">
                      {interestedCandidates?.map((item, index) => (
                        <div key={index} className="">
                          <div className=" rounded-lg mb-4">
                            <div className="profile bg-white rounded-lg">
                              <div className="flex justify-between px-[2rem] md:flex-col   pt-[5px] pb-[10px] border-solid border-b-2 border-[#eeeeee] sm:flex-col items-center sm:justify-center md:justify-center gap-2 md:border-b-2 sm:border-b-2 md:pb-4 sm:pb-4">
                                <div className="profileimg flex items-center gap-2 ">
                                  <img
                                    src={`${BASE_URL}/profile/${item?.profile_picture}`}
                                    alt="profilePicture"
                                    className="w-[50px] h-[50px] rounded-full object-cover mx-auto"
                                  />
                                  <h2 className="2xl:text-[14px] capitalize xl:text-[14px] lg:text-[14px] font-medium">
                                    {item?.first_name} {item?.last_name}
                                  </h2>
                                </div>
                                <button
                                  onClick={() => {
                                    navigate(
                                      `/candidate-profile-admin/${item._id}`,
                                      {
                                        state: { item: item },
                                      }
                                    );
                                  }}
                                  className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                >
                                  View Profile
                                </button>

                                {/* <button
                              className="bg-white ml-5 hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[10px]"
                              onClick={() => {
                                setOpen(true);
                                setCandidateData(item);
                              }}
                            >
                              View Profile
                            </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    )}
                    </>
                    )}
                    <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md   py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                        onClick={() => setOpen(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* modal for employer */}
      <Transition.Root show={employerOpen} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setEmployerOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" overflow-y-auto relative transform w-[35%] md:w-[50%] sm:w-[60%]  h-[550px] lg:h-[500px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8">
                  <div className=" sm:items-start">
                    <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-medium leading-6 text-gray-900"
                      >
                        Employer
                      </Dialog.Title>
                      <img
                        src={crossicon}
                        alt=""
                        className="pr-10 cursor-pointer"
                        onClick={() => setEmployerOpen(false)}
                        ref={cancelButtonRef}
                      />
                    </div>

                    <div className="pt-8 lg:pt-5 grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-4">
                      <div key={candidateData?.item} className="">
                        <div className=" rounded-lg border-l-0 mb-4">
                          <div className="profile bg-white rounded-lg ">
                            <div className="profilecontent flex xl:flex-col lg:flex-col md:flex-col sm:flex-col items-center gap-16 xl:gap-6 py-4 pl-4">
                              {
                                <div className="profileimg ">
                                  <img
                                    src={`${BASE_URL}/profile/${candidateData?.profile_picture}`}
                                    alt="profilePicture"
                                    className="w-[150px] h-[150px] rounded-full object-cover mx-auto"
                                  />
                                </div>
                              }

                              <div className="completeinfo ">
                                <div className="profileinfo flex items-start gap-4">
                                  <h2 className="2xl:text-[20px] capitalize xl:text-[18px] lg:text-[16px] font-medium">
                                    {candidateData?.first_name}{" "}
                                    {candidateData?.last_name}
                                  </h2>
                                </div>
                                <div className="roleposition mt-6 flex items-center gap-14 ">
                                  <p className="text-[#696969] flex items-center gap-1 font-normal text-[14px]">
                                    Position:{" "}
                                    {candidateData?.title ? (
                                      <span className="text-black  text-[12px] font-medium">
                                        {candidateData?.title}
                                      </span>
                                    ) : (
                                      <div>
                                        <p className="text-black pl-2">N/A</p>
                                      </div>
                                    )}
                                  </p>
                                </div>

                                <div className="roleposition mt-4 ">
                                  <p className="text-[#696969] flex items-center gap-1 font-normal text-[14px]">
                                    Gender:{" "}
                                    {candidateData?.gender ? (
                                      <span className="text-black text-[12px] capitalize font-medium">
                                        {candidateData?.gender}
                                      </span>
                                    ) : (
                                      <div>
                                        <p className="text-black pl-2">N/A</p>
                                      </div>
                                    )}
                                  </p>
                                  <p className="text-[#696969] font-normal mt-4 text-[14px]">
                                    Company :{" "}
                                    <span className="text-black text-[12px] font-medium">
                                      {candidateData?.company_name}
                                    </span>
                                  </p>
                                </div>
                                <div className="email mt-4 lg:items-baseline lg:gap-4 sm:flex-col sm:items-baseline sm:gap-3 items-center gap-14">
                                  <p className="text-[#696969] font-normal text-[14px]">
                                    Email:{" "}
                                    <span className="text-black capitalize text-[12px] font-medium">
                                      {candidateData?.email}
                                    </span>
                                  </p>
                                  <p className="text-[#696969] mt-4 font-normal text-[14px]">
                                    Phone:{" "}
                                    <span className="text-black text-[12px] font-medium">
                                      {candidateData?.cell_phone}
                                    </span>
                                  </p>
                                </div>

                                <div className="company mt-4">
                                  <p className="text-[#696969] flex items-center gap-1 font-normal text-[14px]">
                                    Address:{" "}
                                    {candidateData?.address ? (
                                      <span className="text-black text-[12px] font-medium">
                                        {candidateData?.address}
                                      </span>
                                    ) : (
                                      <div>
                                        <p className="text-black pl-2">N/A</p>
                                      </div>
                                    )}
                                  </p>
                                </div>
                                <div className="company mt-4">
                                  <p className="text-[#696969] flex items-center gap-1 font-normal text-[14px]">
                                    Country:{" "}
                                      <span className="text-black text-[12px] capitalize font-medium">
                                        {candidateData?.country}
                                      </span>
                                  
                                  </p>
                                </div>
                                <div className="company mt-4">
                                  <p className="text-[#696969] flex items-baseline gap-1 font-normal text-[14px]">
                                    Registration No:{" "}
                                  
                                      <span className="text-black text-[12px] font-medium">
                                        {candidateData?.registration_number}
                                      </span>
                                  
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md   py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                        onClick={() => setEmployerOpen(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {isLoading ? (
        <div className="skeleton-loader">
          <Skeleton width={1200} count={10} />
        </div>
      ) : interview && interview?.length > 0 ? (
        <div>
          <div className="border-[1px] grid grid-cols-[1fr_2fr_4fr] md:grid-cols-1 sm:grid-cols-1 md:text-center sm:text-center items-center shadow-xl mb-4 mr-6 py-4 md:hidden sm:hidden mt-7 bg-[#FCC015]">
            <div className="text-[16px] pl-10 text-[white] font-medium">
              Sr#
            </div>
            <h2 className="text-[16px] text-[white] font-medium">Employer</h2>
            <h2 className="text-[16px] text-[white]  font-medium flex justify-center">
              Candidate
            </h2>
          </div>
          {interview?.map((item, index) => (
            <div key={index}>
              <div className="border-[1px] mt-2 bg-white grid grid-cols-[1fr_2fr_4fr] md:grid-cols-1 sm:grid-cols-1 md:text-center sm:text-center items-center shadow-xl mb-4 mr-6 py-4">
                <div>
                  <h2 className="text-[14px] pl-10 md:pl-0 sm:pl-0 font-semibold text-[#111827]">
                    {index + 1}
                  </h2>
                </div>

                <h2 className="text-[14px] 2xl:hidden xl:hidden lg:hidden sm:mt-2 ">
                  Candidate
                </h2>
                <div className="flex justify-between md:flex-col sm:flex-col md:mt-5 sm:mt-5 sm:justify-center md:justify-center items-center gap-2">
                  <div className="profileimg flex gap-4 items-center ">
                    <img
                      src={`${BASE_URL}/profile/${item?.profile_picture}`}
                      alt="profilePicture"
                      className="w-[50px] h-[50px] rounded-full object-cover mx-auto"
                    />
                    <h2 className="2xl:text-[14px] capitalize xl:text-[14px] lg:text-[14px] font-medium ">
                      {item?.first_name} {item?.last_name}
                    </h2>
                  </div>

                  <button
                    className="bg-white ml-5 hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[2rem]  py-[5px]  text-[12px]"
                    onClick={() => {
                      setEmployerOpen(true);
                      setCandidateData(item);
                    }}
                  >
                    View Profile
                  </button>
                </div>

                <h2 className="text-[14px] 2xl:hidden xl:hidden lg:hidden  ">
                  Employer
                </h2>

                <div className="flex md:flex-col sm:flex-col items-center justify-center sm:justify-center md:justify-center gap-2 md:border-b-2 sm:border-b-2 md:pb-4 sm:pb-4">
              

                  <button
                    className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.7rem]  py-[5px]  text-[12px]"
                    onClick={() => {
                      viewCandidates(item._id);
                      setEmployerId(item._id)
                      setData("shortlist")
                    }}
                  >
                    Shortlisted Candidates
                  </button>
                  <button
                    className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.7rem]  py-[5px]  text-[12px]"
                    onClick={() => {
                      viewCandidates(item._id);
                      setEmployerId(item._id)
                      setData("interested")
                    }}
                  >
                    Interested Candidates
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex mt-4 justify-center">
          <h2 className="text-3xl py-4 text-[#495057]">No Results Found</h2>
        </div>
      ) }

      {/* {mineInterview > 12 ? (
        <Pagination
          totalPages={page}
          currentPage={currentStep}
          setCurrentPage={setCurrentStep}
        />
      ) : null} */}
    </>
  );
};

export default EmployerPendingInterviews;
