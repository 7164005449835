import React, { Fragment, useEffect, useRef, useState } from "react";

import tick from "../../../assets/images/Icons/tick.svg";
import cross from "../../../assets/images/Icons/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchInterviewRequest } from "../../../store/interviewRequestSlice/interviewRequestSlice";
import Pagination from "../../../common/pagination/pagination";
import { Link, useNavigate } from "react-router-dom";
import {
  BASE_URL,
  DELETE_CANDIDATE_FROM_LIST,
  EMPLOYER_GIVE_RESULT,
  FILTER_BY_STATUS,
  INTERVIEW_APPROVAL,
} from "../../../utils/baseUrl";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import Swal from "sweetalert2";
import { Dialog, Transition } from "@headlessui/react";
import crossicon from "../../../assets/images/Icons/crossicon.svg";
import Skeleton from "react-loading-skeleton";
import { fetchUser } from "../../../store/candidateInfoSlice/candidateInfoSlice";
import { Tooltip } from "react-tooltip";
import CircleLoader from "../../../common/circleLoader/circleLoader";
import { fetchSearchCandidate } from "../../../store/candidateSearchSlice/candidateSearchSlice";
import { IconSearch } from "@tabler/icons-react";
import { fetchSearchByAdmin } from "../../../store/adminSearchSlice/adminSearchSlice";

const PendingInterviews = ({ interviewapproval, completeRequest }) => {

  //////////////////////////////////////////////////
  const today = new Date().toISOString().split("T")[0];
  const [myId, setMyId] = useState("");
  const navigate = useNavigate();
  const [isApiCallSuccessful, setIsApiCallSuccessful] = useState(false);
  const [myprofile, setMyProfile] = useState({});
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [openPic, setOpenPic] = useState(false);
  const [myLoading, setIsLoading] = useState(false);
  const isLoading = useSelector((state) => state?.userSlice?.loading);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [deleteSuccessFully, setDeletedSuccessFully] = useState(false);

  /////////for interview///////////////////////////////

  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  const user = useSelector((state) => state?.AdminLogInSlice?.user?.content);

  const interview = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.content
  );

  useEffect(() => {
    if (interview?.length > 0) {
      setSearchPerformed(true);
    }
  }, [interview]);
  useEffect(() => {
    // Reset currentStep to 1 whenever interviewApproval changes
    setCurrentStep(1);
  }, [interviewapproval]);

  const page = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.pages
  );
 
  const mineInterview = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.total
  );
  console.log("🚀 ~ useEffect ~ interviewapproval:", interviewapproval)
  const interviewRequestStatus = async (value, id) => {
    const paylod = {
      approval: value,
    };
    await axios
      .post(INTERVIEW_APPROVAL(id), paylod)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setIsApiCallSuccessful(!isApiCallSuccessful);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchInterviewRequest({
        page: currentStep,
        approval: interviewapproval,
      })
        
    ).then(() => {
      // Set loading to false when the fetch is complete
      setIsLoading(false);
    });
  }, [
    dispatch,
    interviewapproval,
    isApiCallSuccessful,
    currentStep,
    mineInterview,
    deleteSuccessFully,
  ]);

  const AddProfile = () => {
    axios
      .post(EMPLOYER_GIVE_RESULT(myId), myprofile, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      })
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setOpenPic(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        setOpenPic(false);
      });
  };
  const startIndex = (currentStep - 1) * 12;

  /////////////  for search ////////////
  const [filterCandidate, setfilterCandidate] = useState(false);
  const [searchFilter, setSearchFilter] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchCandidate, setSearchCandidate] = useState(false);
  const search = useSelector(
    (state) => state?.searchAdminSlice?.searchCandidate?.content
  );
  useEffect(() => {
    setSearchQuery("");
    setSearchCandidate(false)
    setSearchPerformed(false); // Reset input field value
  }, [interviewapproval]);
  const handleSelectionChange = (event) => {
    const value = event.target.value;

    setSelectedStatus(value);
    // filterData(value, interviewapproval);
  };

  const handleChange = (value) => {
    setSearchQuery(value);
    handleSearchChange(value);
  };

  const handleSearchChange = (event) => {
    const query = event;
    setSearchQuery(query);

    if (query.length >= 3) {
      setSearchCandidate(true);
      setIsLoading(true);
      // if (selectedStatus) {
      dispatch(
        fetchSearchByAdmin({
          key: query,
          interviewapproval: interviewapproval || "All",
          role: "CANDIDATE",
        })
      ).then(() => {
        // Set loading to false when the fetch is complete
        setIsLoading(false);
      });
    } else {
      setSearchCandidate(false);
    }
  };

 ////////////////////////////////

  ///////////delete candidate//////////////////
  const deleteTeamMember = async (id) => {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Candidate!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#EBAD07",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!",
    });
    if (confirmResult.isConfirmed) {
      axios
        .delete(DELETE_CANDIDATE_FROM_LIST(id))
        .then((response) => {
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              title: `${response?.data?.message}`,
              showConfirmButton: false,
              timer: 1500,
            });
            setDeletedSuccessFully(!deleteSuccessFully);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${response?.data?.message}`,
              confirmButtonColor: "#EEAF06",
            });
          }
        })

        .catch((error) => {
        return error;
        });
    }
  };
  return (
    <>
      {/* Modal For open add team member */}
      <Transition.Root show={openPic} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenPic}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <form
                    // onSubmit={AddProfile}
                    >
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 sm:w-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Upload Result
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenPic(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="items-center mx-8 mt-[1rem]">
                        <div className="mt-3 mb-10 sm:ml-4 sm:mt-0 sm:text-left ">
                          <div className=" items-center mx-8 mt-[1rem]">
                            <div className="">
                              <div className="grid grid-cols-[1fr_1.75fr] items-center justify-evenly gap-14 mt-4">
                                <div className="flex items-center gap-3">
                                  <select
                                    id="interview_number"
                                    name="interview_number"
                                    onChange={(e) => {
                                      setMyProfile({
                                        ...myprofile,
                                        interview_number: e.target.value,
                                      });
                                    }}
                                    className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  >
                                    <option value="" disabled selected>
                                      Interview Number
                                    </option>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                      (item, index) => (
                                        <option key={index} value={index + 1}>
                                          {index + 1}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <select
                                    id="interview_clear"
                                    name="interview_clear"
                                    onChange={(e) => {
                                      setMyProfile({
                                        ...myprofile,
                                        interview_clear: e.target.value,
                                      });
                                    }}
                                    className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  >
                                    <option value="" disabled selected>
                                      Interview status
                                    </option>
                                    <option value="CLEAR">Clear</option>
                                    <option value="NO_CLEAR">No Clear</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <label
                          className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                          htmlFor="recruiter_comment"
                        >
                          Comment About Candidate
                        </label>
                        <textarea
                          rows={7}
                          type="text"
                          id="recruiter_comment"
                          placeholder="Tell about yourself..."
                          name="recruiter_comment"
                          onChange={(e) => {
                            setMyProfile({
                              ...myprofile,
                              recruiter_comment: e.target.value,
                            });
                          }}
                          className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={AddProfile}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="Search Section">
        <div className="mt-3  sm:mt-0 sm:text-left ">
          <div className=" items-center  sm:mx-2 mt-[1rem]">
            <div className="">
              <h2 className="text-[#495057] font-semibold text-[16px] pb-2">
                Search Candidate
              </h2>
              <div className=" shadow-md bg-white px-4 py-6 rounded-md mt-2">
                <div className="grid grid-cols-[1fr_2fr] gap-4  ">
                  <div className="flex items-center gap-4 border rounded-md ">
                    <button className="  sm:text-[12px] pl-4 rounded flex items-center ">
                      <IconSearch className="h-4 w-4 text-gray-700" />
                    </button>
                    <input
                      type="text"
                      placeholder="Search..."
                      className="  w-[21rem]   text-[14px]   py-3  text-gray-700 leading-tight  outline-none focus:outline-none focus:shadow-outline"
                      value={searchQuery}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {myLoading ? (
        <CircleLoader />
      ) : (
        <div>
          {/* /////search section/////// */}

          <div className="Onboarding  py-8">
            {interviewapproval === "PENDING" &&
            completeRequest === "notCompleted" ? (
              <h2 className="text-[#495057] font-semibold text-[16px] ">
                Requested Interview List
              </h2>
            ) : interviewapproval === "APPROVED" &&
              completeRequest === "notCompleted" ? (
              <h2 className="text-[#495057] font-semibold text-[16px] ">
                Accepted Interview List
              </h2>
            ) : (
              interviewapproval === "APPROVED" &&
              completeRequest === "completed" && (
                <h2 className="text-[#495057] font-semibold text-[16px] ">
                  Completed Interview List
                </h2>
              )
            )}
            {searchCandidate ? (
              <div>
                {search?.length > 0 ? (
                  <div>
                    {search?.map((item, index) => (
                      <React.Fragment key={index}>
                        {/* {!item?.interview_video_url && ( */}
                        <div
                          className={` onboardinglist border-[1px] bg-white grid grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr] lg:grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr]  2xl:grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr]  md:grid-cols-1 sm:grid-cols-1 md:gap-6 md:text-center sm:text-center items-center shadow-xl mb-3 mr-6 py-4 ${
                            item.zoom_meeting_date === today ||
                            item.zoom_meeting_date <= today
                              ? "border-red-500"
                              : ""
                          }`}
                        >
                          <div className="candidateNumber">
                            <h2 className="text-[14px] pl-10 md:pl-0 sm:pl-0 font-semibold text-[#111827]">
                              {/* {index < 9 ? `0${index + 1}` : index + 1} */}
                              {index + 1}
                            </h2>
                          </div>
                          <div className="candidateinfo flex items-center md:justify-center sm:justify-center gap-2">
                            {/* <img src={smallprofilecevtor} alt="" /> */}
                            <img
                              src={`${BASE_URL}/profile/${item?.profile_picture}`}
                              alt="profilePicture"
                              className="w-[52px] h-[52px] rounded-full object-cover "
                            />
                            <div>
                              <h2 className="text-[13px] text-black font-medium   flex gap-1">
                                {item.first_name} <span className="truncate ... w-[80px]">{item.last_name}</span>
                              </h2>
                              <p className="text-[#696969] font-normal text-[10px] truncate ... w-[200px]">
                                {item.title}
                              </p>
                            </div>
                          </div>
                          <div className="date">
                            <h2 className="text-[13px] text-[#495057] font-semibold">
                              {item.zoom_meeting_date}
                            </h2>
                          </div>
                          <div className="time">
                            <h2 className="text-[13px] text-[#495057] font-semibold">
                              {item.zoom_meeting_time}
                            </h2>
                          </div>

                          <div className="BMGcompany flex sm:flex-col items-center gap-2 md:justify-center">
                            {interviewapproval === "PENDING" && (
                              <div className="flex gap-2">
                                <button
                                  disabled={today >= item.zoom_meeting_date}
                                  onClick={() =>
                                    interviewRequestStatus(
                                      "APPROVED",
                                      item?._id
                                    )
                                  }
                                  className={`border-solid border-[0.5px]  px-[0.1rem] w-[8rem] lg:w-[6rem]  flex items-center justify-center gap-2 ${
                                    today >= item.zoom_meeting_date
                                      ? "opacity-50 cursor-not-allowed "
                                      : "text-[#4CAF50] hover:bg-[#4CAF50] hover:text-white border-[#4CAF50]"
                                  }`}
                                >
                                  Accept <img src={tick} alt="tick" />
                                </button>
                                <button
                                  disabled={today >= item.zoom_meeting_date}
                                  onClick={() =>
                                    interviewRequestStatus(
                                      "REJECTED",
                                      item?._id
                                    )
                                  }
                                  className={`border-solid border-[0.5px]  px-[0.1rem] w-[8rem] lg:w-[6rem]  flex items-center justify-center gap-2 ${
                                    today >= item.zoom_meeting_date
                                      ? "opacity-50 cursor-not-allowed "
                                      : "text-[#FF0000] hover:bg-[#FF0000] hover:text-white border-[#FF0000]"
                                  }`}
                                >
                                  Reject <img src={cross} alt="cross" />
                                </button>
                              </div>
                            )}
                            
                            <button
                              onClick={() => {
                                navigate(
                                  `/candidate-profile-admin/${item?._id}`,
                                  {
                                    state: { item: item },
                                  }
                                );
                              }}
                              className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                            >
                              View Profile
                            </button>
                            <button
                              onClick={() => deleteTeamMember(item._id)}
                              className="bg-[#d33] hover:bg-white hover:text-black border-[1px] border-[#d33] flex gap-1 items-center justify-center text-white px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                            >
                              Delete
                            </button>

                            {interviewapproval === "APPROVED" &&
                              completeRequest === "notCompleted" && (
                                <Link
                                  to={`https://interview.bmgtalentplus.com.sg/?firstName=${user?.customer?.first_name}&lastName=${user?.customer?.last_name}&role=${user?.customer?.role}`}
                                >
                                  <button
                                    disabled={item.zoom_meeting_date !== today}
                                    className={`bg-white border-[1px] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px] text-[12px] ${
                                      item.zoom_meeting_date === today
                                        ? "hover:bg-[#EAB108] hover:text-white border-[#EAB108]"
                                        : "opacity-50 cursor-not-allowed"
                                    }`}
                                  >
                                    Join call
                                  </button>
                                </Link>
                              )}
                          </div>
                        </div>
                        {/* )} */}
                      </React.Fragment>
                    ))}
                  </div>
                ) : searchPerformed ? (
                  <div className="flex mt-4 justify-center">
                    <h2 className="text-3xl py-4 text-[#495057]">
                      No Results Found
                    </h2>
                  </div>
                ) : null}
              </div>
            ) : interview && interview?.length > 0 ? (
              <>
                <div className="gap-2 grid grid-cols-[0.50fr_1.30fr_1fr_0.7fr_3fr]  lg:grid-cols-[0.50fr_1.30fr_1fr_0.7fr_3fr]  2xl:grid-cols-[0.50fr_1.30fr_1fr_0.7fr_3fr]  md:grid-cols-1 sm:grid-cols-1 border-[1px] md:gap-6 md:text-center sm:text-center items-center shadow-xl mb-4 mr-6 py-4 bg-[#FCC015]  mt-7 md:hidden sm:hidden ">
                  <div className="text-[13px] pl-10 text-white font-medium">
                    Sr#
                  </div>
                  <h2 className="text-[13px] text-white  font-medium">
                    Candidate Name
                  </h2>
                  <h2 className="text-[13px] pl-8 text-white font-medium">
                    Interview Date
                  </h2>
                  <h2 className="text-[13px] text-white text-center font-medium">
                    Interview Time
                  </h2>

                  <h2 className="text-[13px] pl-8 text-white font-medium">
                    Actions
                  </h2>
                </div>
                {isLoading ? (
                  <div className="skeleton-loader ">
                    <Skeleton width={1000} count={10} />
                  </div>
                ) : (
                  <div>
                    {interviewapproval === "COMPLETED" ? (
                      <div>
                        {interview
                          // .filter((user) => user?.interview_video_url)
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={` onboardinglist border-[1px] bg-white grid grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr] lg:grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr]  2xl:grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr]  md:grid-cols-1 sm:grid-cols-1 md:gap-6 md:text-center sm:text-center items-center shadow-xl mb-3 mr-6 py-4 ${
                                  item.zoom_meeting_date === today ||
                                  item.zoom_meeting_date <= today
                                    ? "border-red-500"
                                    : ""
                                }`}
                              >
                                <div className="candidateNumber">
                                  <h2 className="text-[14px] pl-10 md:pl-0 sm:pl-0 font-semibold text-[#111827]">
                                    {/* {index < 9 ? `0${index + 1}` : index + 1} */}
                                    {startIndex + index + 1}
                                  </h2>
                                </div>
                                <div className="candidateinfo flex items-center md:justify-center sm:justify-center gap-2">
                                  {/* <img src={smallprofilecevtor} alt="" /> */}
                                  <img
                                    src={`${BASE_URL}/profile/${item?.profile_picture}`}
                                    alt="profilePicture"
                                    className="w-[52px] h-[52px] rounded-full object-cover "
                                  />
                                  <div>
                                    <h2 className="text-[13px] text-[#343a40] font-medium flex gap-1">
                                      {item.first_name} <span className="truncate ... w-[80px]">{item.last_name}</span>
                                    </h2>
                                    <p className="text-[#74788d] font-normal text-[10px] ">
                                      {item.title}
                                    </p>
                                  </div>
                                </div>
                                <div className="date">
                                  <h2 className="text-[13px] text-[#495057] font-semibold">
                                    {item.zoom_meeting_date}
                                  </h2>
                                </div>
                                <div className="time">
                                  <h2 className="text-[13px] text-[#495057] font-semibold">
                                    {item.zoom_meeting_time}
                                  </h2>
                                </div>

                                <div className="BMGcompany flex sm:flex-col items-center gap-2 md:justify-center">
                                  {interviewapproval === "PENDING" &&
                                    user?.customer?.role === "HR" && (
                                      <div className="flex gap-2">
                                        <button
                                          disabled={
                                            today >= item.zoom_meeting_date
                                          }
                                          onClick={() =>
                                            interviewRequestStatus(
                                              "APPROVED",
                                              item?._id
                                            )
                                          }
                                          className={`border-solid border-[0.5px]  px-[0.1rem] w-[8rem] lg:w-[6rem]  flex items-center justify-center gap-2 ${
                                            today >= item.zoom_meeting_date
                                              ? "opacity-50 cursor-not-allowed "
                                              : "text-[#4CAF50] hover:bg-[#4CAF50] hover:text-white border-[#4CAF50]"
                                          }`}
                                        >
                                          Accept <img src={tick} alt="tick" />
                                        </button>
                                        <button
                                          disabled={
                                            today >= item.zoom_meeting_date
                                          }
                                          onClick={() =>
                                            interviewRequestStatus(
                                              "REJECTED",
                                              item?._id
                                            )
                                          }
                                          className={`border-solid border-[0.5px]  px-[0.1rem] w-[8rem] lg:w-[6rem]  flex items-center justify-center gap-2 ${
                                            today >= item.zoom_meeting_date
                                              ? "opacity-50 cursor-not-allowed "
                                              : "text-[#FF0000] hover:bg-[#FF0000] hover:text-white border-[#FF0000]"
                                          }`}
                                        >
                                          Reject <img src={cross} alt="cross" />
                                        </button>
                                      </div>
                                    )}
                                  <button
                                    onClick={() => {
                                      navigate(
                                        `/candidate-profile-admin/${item?._id}`,
                                        {
                                          state: { item: item },
                                        }
                                      );
                                    }}
                                    className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    View Profile
                                  </button>
                                  {interviewapproval === "APPROVED" &&
                                    completeRequest === "completed" && (
                                      // item?.interview_video_url &&
                                      <div>
                                        {item?.technicalSkill?.length < 0 ? (
                                          <button
                                            disabled={
                                              !item?.interview_video_url
                                            }
                                            onClick={() => {
                                              // setOpenPic(true);
                                              setMyId(item?._id);
                                              navigate(
                                                "/hr-candidate-feedback",
                                                {
                                                  state: { item: item },
                                                }
                                              );
                                            }}
                                            className={`border-[1px] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px] ${
                                              !item?.interview_video_url
                                                ? "opacity-50 cursor-not-allowed"
                                                : "bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108]"
                                            }`}
                                          >
                                            Give Feedback
                                          </button>
                                        ) : (
                                          <button
                                            disabled={
                                              !item?.interview_video_url
                                            }
                                            onClick={() => {
                                              // setOpenPic(true);
                                              setMyId(item?._id);
                                              navigate(
                                                "/hr-candidate-feedback",
                                                {
                                                  state: { item: item },
                                                }
                                              );
                                            }}
                                            className={`border-[1px] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px] ${
                                              !item?.interview_video_url
                                                ? "opacity-50 cursor-not-allowed"
                                                : "bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108]"
                                            }`}
                                          >
                                            Update Feedback
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  {interviewapproval === "APPROVED" &&
                                    completeRequest === "notCompleted" && (
                                      <Link
                                        to={`https://interview.bmgtalentplus.com.sg/?firstName=${user?.customer?.first_name}&lastName=${user?.customer?.last_name}&role=${user?.customer?.role}`}
                                      >
                                        <button
                                          disabled={
                                            item.zoom_meeting_date !== today
                                          }
                                          className={`bg-white border-[1px] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px] text-[12px] ${
                                            item.zoom_meeting_date === today
                                              ? "hover:bg-[#EAB108] hover:text-white border-[#EAB108]"
                                              : "opacity-50 cursor-not-allowed"
                                          }`}
                                        >
                                          Join call
                                        </button>
                                      </Link>
                                    )}

                                  {interviewapproval === "COMPLETED" &&
                                    completeRequest === "completed" && (
                                      <a
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Please Wait for upload the interview Video"
                                      >
                                        <button
                                          disabled={!item?.interview_video_url}
                                          onClick={() => {
                                            // setOpenPic(true);
                                            setMyId(item?._id);
                                            navigate("/hr-candidate-feedback", {
                                              state: { item: item },
                                            });
                                          }}
                                          className={`border-[1px] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px] ${
                                            !item?.interview_video_url
                                              ? "opacity-50 cursor-not-allowed"
                                              : "bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108]"
                                          }`}
                                        >
                                          Give Feedback
                                        </button>
                                      </a>
                                    )}
                                  <button
                                    onClick={() => deleteTeamMember(item._id)}
                                    className="bg-[#d33] hover:bg-white hover:text-black border-[1px] border-[#d33] flex gap-1 items-center justify-center text-white px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    ) : (
                      <div>
                        {interview?.map((item, index) => (
                          <React.Fragment key={index}>
                            {!item?.interview_video_url && (
                              <div
                                className={` onboardinglist border-[1px] bg-white grid grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr] lg:grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr]  2xl:grid-cols-[0.50fr_1.6fr_1fr_0.5fr_3fr]  md:grid-cols-1 sm:grid-cols-1 md:gap-6 md:text-center sm:text-center items-center shadow-xl mb-3 mr-6 py-4 ${
                                  item.zoom_meeting_date === today ||
                                  item.zoom_meeting_date <= today
                                    ? "border-red-500"
                                    : ""
                                }`}
                              >
                                <div className="candidateNumber">
                                  <h2 className="text-[14px] pl-10 md:pl-0 sm:pl-0 font-semibold text-[#111827]">
                                    {/* {index < 9 ? `0${index + 1}` : index + 1} */}
                                    {startIndex + index + 1}
                                  </h2>
                                </div>
                                <div className="candidateinfo flex items-center md:justify-center sm:justify-center gap-2">
                                  {/* <img src={smallprofilecevtor} alt="" /> */}
                                  <img
                                    src={`${BASE_URL}/profile/${item?.profile_picture}`}
                                    alt="profilePicture"
                                    className="w-[52px] h-[52px] rounded-full object-cover "
                                  />
                                  <div>
                                    <h2 className="text-[13px] text-black font-medium truncate ... w-[200px]  flex  gap-1">
                                      {item.first_name} <span className="truncate ... w-[80px]">{item.last_name}</span>
                                    </h2>
                                    <p className="text-[#696969] font-normal text-[10px] truncate ... w-[200px]">
                                      {item.title}
                                    </p>
                                  </div>
                                </div>
                                <div className="date">
                                  <h2 className="text-[13px] text-[#495057] font-semibold">
                                    {item.zoom_meeting_date}
                                  </h2>
                                </div>
                                <div className="time">
                                  <h2 className="text-[13px] text-[#495057] font-semibold">
                                    {item.zoom_meeting_time}
                                  </h2>
                                </div>

                                <div className="BMGcompany flex sm:flex-col items-center gap-2 md:justify-center">
                                  {interviewapproval === "PENDING" &&
                                    user?.customer?.role === "HR" && (
                                      <div className="flex gap-2">
                                        <button
                                          disabled={
                                            today >= item.zoom_meeting_date
                                          }
                                          onClick={() =>
                                            interviewRequestStatus(
                                              "APPROVED",
                                              item?._id
                                            )
                                          }
                                          className={`border-solid border-[0.5px]  px-[0.1rem] w-[8rem] lg:w-[6rem]  flex items-center justify-center gap-2 ${
                                            today >= item.zoom_meeting_date
                                              ? "opacity-50 cursor-not-allowed "
                                              : "text-[#4CAF50] hover:bg-[#4CAF50] hover:text-white border-[#4CAF50]"
                                          }`}
                                        >
                                          Accept <img src={tick} alt="tick" />
                                        </button>
                                        <button
                                          disabled={
                                            today >= item.zoom_meeting_date
                                          }
                                          onClick={() =>
                                            interviewRequestStatus(
                                              "REJECTED",
                                              item?._id
                                            )
                                          }
                                          className={`border-solid border-[0.5px]  px-[0.1rem] w-[8rem] lg:w-[6rem]  flex items-center justify-center gap-2 ${
                                            today >= item.zoom_meeting_date
                                              ? "opacity-50 cursor-not-allowed "
                                              : "text-[#FF0000] hover:bg-[#FF0000] hover:text-white border-[#FF0000]"
                                          }`}
                                        >
                                          Reject <img src={cross} alt="cross" />
                                        </button>
                                      </div>
                                    )}
                                  <button
                                    onClick={() => {
                                      navigate(
                                        `/candidate-profile-admin/${item?._id}`,
                                        {
                                          state: { item: item },
                                        }
                                      );
                                    }}
                                    className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    View Profile
                                  </button>

                                  {interviewapproval === "APPROVED" &&
                                    completeRequest === "notCompleted" && (
                                      // item?.interview_video_url &&

                                      <Link
                                        to={`https://interview.bmgtalentplus.com.sg/?firstName=${user?.customer?.first_name}&lastName=${user?.customer?.last_name}&role=${user?.customer?.role}`}
                                      >
                                        <button
                                          disabled={
                                            item.zoom_meeting_date !== today
                                          }
                                          className={`bg-white border-[1px] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px] text-[12px] ${
                                            item.zoom_meeting_date === today
                                              ? "hover:bg-[#EAB108] hover:text-white border-[#EAB108]"
                                              : "opacity-50 cursor-not-allowed"
                                          }`}
                                        >
                                          Join call
                                        </button>
                                      </Link>
                                    )}
                                  <button
                                    onClick={() => deleteTeamMember(item._id)}
                                    className="bg-[#d33] hover:bg-white hover:text-black border-[1px] border-[#d33] flex gap-1 items-center justify-center text-white px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : searchPerformed ? (
              <div className="flex items-center justify-center">
                <h2 className="text-3xl py-4 text-[#495057]">
                  No Results Found!
                </h2>
              </div>
            ) : null}

            {searchCandidate ? (
              search?.length > 12 ? (
                <Pagination
                  totalPages={page}
                  currentPage={currentStep}
                  setCurrentPage={setCurrentStep}
                />
              ) : null
            ) : (
              mineInterview > 12 && (
                <Pagination
                  totalPages={page}
                  currentPage={currentStep}
                  setCurrentPage={setCurrentStep}
                />
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PendingInterviews;
