// Routes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginForm from "./components/Auth/login/index";
import CandidateSignup from "./components/Auth/Register/Candidate";
import EmployerSignUp from "./components/Auth/Register/Employer";
import OTP from "./components/Auth/Register/OTP";
import AdminLogin from "./components/Auth/adminLogin/adminlogin";
import AuthRoute from "./components/ProtectedRouting/authRoute";
import RoleBaseAccess from "./components/ProtectedRouting/roleBaseAccess";
import {
  adminProtectedRoute,
  protectedRoutes,
  rolebaseProtectedRoute,
} from "./components/ProtectedRouting/routes";
import EmailSent from "./components/Auth/Register/OTP/EmailSent";
import AdminAccess from "./components/ProtectedRouting/adminAccess";
import CandidatePublicProfile from "./Pages/Employer/publicProfileCandidate/publicProfile";
import CandidateProfile from "./Pages/Employer/CandidateProfile";
import CandidateProfileAdmin from "./Pages/BMGadmin/candidateProfileAdmin/candidateProfileAdmin";
import EmailVerify from "./components/Auth/Register/OTP/emailVerify";
import ResetPassword from "./components/Auth/resetPassword/resetPassword";
import SignupPage from "./Pages/HomePage/signupPage";
import Page404 from "./common/404Page/404Page";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthRoute allowedRoles={["CANDIDATE"]} />}>
        {protectedRoutes?.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Route>
      <Route element={<RoleBaseAccess allowedRoles={["EMPLOYEE"]} />}>
        {rolebaseProtectedRoute?.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Route>
      <Route
        element={
          <AdminAccess allowedRoles={["SUPER-ADMIN", "HR", "MANAGER","CONTENT-WRITER"]} />
        }
      >
        {adminProtectedRoute?.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Route>
      {/* <Route path="/my-team" element={<MyTeam />} /> */}
      
      <Route path="/" element={<LoginForm />} />
      <Route path="*" element={<Page404 />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/reset-password/:id" element={<ResetPassword />} />
      <Route path="/email-sent" element={<EmailSent />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/candidate-signup" element={<CandidateSignup />} />
      <Route path="/employer-signup" element={<EmployerSignUp />} />
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="/public-profile" element={<CandidatePublicProfile />} />
      <Route path="/candidate-profile/:id" element={<CandidateProfile />} />
      <Route path='/email-verification' element={<EmailVerify/>} />
      <Route path="/candidate-profile-admin/:id" element={<CandidateProfileAdmin />}
      />
    </Routes>
  );
};

export default AppRoutes;
