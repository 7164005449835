import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { EMPLOYER_CANDIDATE_SEARCH } from "../../utils/baseUrl";

const initialState = {
  loading: false,
  searchCandidate: [],
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchSearchCandidate = createAsyncThunk(
  "searchCandidatelice/fetchSearchCandidate",
  async (data) => {
    console.log("🚀 ~ data:", data)
    
    const response = await axios.get(EMPLOYER_CANDIDATE_SEARCH(data.key));
    console.log(response,"responsivenesssss");
    return response?.data;
  }
);

const searchCandidatelice = createSlice({
  name: "searchCandidatelice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSearchCandidate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchCandidate.fulfilled, (state, action) => {
      state.loading = false;
      state.searchCandidate = action.payload;
      state.error = "";
    });
    builder.addCase(fetchSearchCandidate.rejected, (state, action) => {
      state.loading = false;
      state.searchCandidate = [];
      state.error = action.error.message;
    });
  },
});

export default searchCandidatelice.reducer;
