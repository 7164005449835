import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { useFormik } from "formik";
import videoicon from "./../../assets/images/videoicon.png";
import clock from "./../../assets/images/clock.png";
import user from "./../../assets/images/user.png";
import DatePicker from "react-datepicker";
import {
  CANDIDATE_SCHEDULAR_TIME,
  EMPLOYER_SCHEDULE_MEETING,
  RESHEDULE_INTERVIEW,
} from "../../utils/baseUrl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";
import CandidateNavbarAfterLogin from "../loginNavbar/loginNavbar";
const Schedular = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [myDate, setMyDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [meetingData, setMeetingData] = useState({
    meeting_time: "",
    meeting_date: "",
    meting_url: "string",
  });
  useEffect(() => {
    const year = startDate.getFullYear();
    const month = ("0" + (startDate.getMonth() + 1)).slice(-2);
    const day = ("0" + startDate.getDate()).slice(-2);

    const formattedStartDate = `${year}-${month}-${day}`;
    const fetchTimeSlots = async () => {
      try {
        const response = await axios.get(
          CANDIDATE_SCHEDULAR_TIME(formattedStartDate)
        );
        setTimeSlots(response?.data?.content);
        setIsLoading(false);
      } catch (error) {
        return error
      }
    };
    fetchTimeSlots();
  }, [startDate]);

  const formik = useFormik({
    initialValues: meetingData,

    onSubmit: async (values) => {
      try {
        if (
          values.zoom_meeting_time === "" ||
          values.zoom_meeting_date === ""
        ) {
          // Show an error message using a library like Swal
          Swal.fire({
            icon: "error",
            title: "Meeting date and time is required",
            text: "Please Select Both.",
          });
          return; // Stop the function execution if the date is empty
        }
        const year = startDate.getFullYear();
        const month = ("0" + (startDate.getMonth() + 1)).slice(-2);
        const day = ("0" + startDate.getDate()).slice(-2);

        const formattedStartDate = `${year}-${month}-${day}`;
        const payload = {
          zoom_meeting_time: values.zoom_meeting_time,
          zoom_meeting_date: formattedStartDate,
          // Other fields in your payload
        };
        const response = await axios.post(RESHEDULE_INTERVIEW, payload);

        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/candidate-dashboard");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response?.data?.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error?.data?.message,
        });
      }
    },
  });

  const handleTimeSlotClick = (time, index) => {
    formik.setFieldValue("meeting_time", time.start_time);
    setSelectedTimeSlot(index); // Set the index of the selected time slot
  };

  // for date selection
  const [selectedDate, setSelectedDate] = useState(null);
  const currentDate = new Date(); // Get the current date
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1); // Set the next date
  const selectableDate = new Date(currentDate);
  selectableDate.setDate(currentDate.getDate() + 2);

  const combinedFilter = (date) => {
    // Disable weekends
    if (date.getDay() === 0 || date.getDay() === 6) {
      return false;
    }

    const holidays = timeSlots?.filter((item) => typeof item === "string");
    const holidayDates = holidays?.map((holiday) => new Date(holiday));
    if (
      holidayDates?.find(
        (holidayDate) => holidayDate?.toDateString() === date?.toDateString()
      )
    ) {
      return false;
    }

    // Limit selection to the next 30 days
    const currentDate = new Date();
    const next30Days = new Date();
    next30Days.setDate(currentDate.getDate() + 30);
    return date <= next30Days;
  };
  return (
    <>
      <div className="scheduling-page-container">
        <CandidateNavbarAfterLogin />
        <div className="container sm:max-w-[85%] mx-auto shadow-lg  bg-white rounded-xl mb-[4rem] w-[90%] py-10 mt-16 ">
          <div className="border-b-[2px] py-4 text-lg text-center font-semibold">
            You are one step closer in making your dreams a reality. Do book a
            slot with us. You will reach a confirmation <br /> email once the
            booking is done. All the best
          </div>
          <div className="p-6 rounded-xl mt-2 grid 2xl:grid-cols-[1fr_1.75fr] xl:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_2fr] gap-16">
            <div className="custom-calendar border-r-2 pr-7">
              <div className="py-4">
                <h2 className="text-[22px]">Office Hours</h2>
                <p className="text-lg sm:text-sm font-normal py-4">
                  Book A Slot To Speak To Our Team
                </p>
                <div className="flex sm:flex-col sm:gap-2 justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <img src={videoicon} alt="" className="w-8 sm:w-5" />
                    <h2 className="text-[16px] sm:text-[14px]">Zoom Meeting</h2>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={clock} alt="" className="w-8  sm:w-5" />
                    <h2 className="text-[16px] sm:text-[14px]">20 minutes</h2>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={user} alt="" className="w-8 sm:w-5" />
                    <h2 className="text-[16px] sm:text-[14px]">1 on 1</h2>
                  </div>
                </div>
              </div>
              <DatePicker
                selected={selectedDate}
                filterDate={combinedFilter}
                minDate={selectableDate}
                inline
                // selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setMyDate(date);
                  formik.setFieldValue(
                    "zoom_meeting_date",
                    date.toISOString().split("T")[0],
                    setSelectedDate(date)
                  );
                }}
              />
            </div>
            <div>
              {myDate ? (
                <div className="rightsection flex flex-col pt-10 ">
                  <h2 className="text-lg text-[#696969]">
                    Available Time Slots for Interview
                  </h2>

                  <div className="slots mt-6">
                    <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 gap-4 ">
                      {isLoading ? (
                        <div className="py-8  px-8 text-center rounded-lg">
                          <Skeleton count={6} width={300} className="mb-4" />
                        </div>
                      ) : (
                        timeSlots
                          ?.filter((time) => time && time.start_time)
                          ?.map((time, index) => (
                            <button
                              key={index}
                              onClick={() => {
                                if (time.available_time) {
                                  handleTimeSlotClick(time, index);
                                  formik.setFieldValue(
                                    "zoom_meeting_time",
                                    time.start_time
                                  );
                                }
                              }}
                              className={`border-2 sm:max-w-[90%] py-3 px-8 sm:px-4 text-center rounded-lg ${
                                !time.available_time
                                  ? "opacity-50 cursor-not-allowed"
                                  : selectedTimeSlot === index
                                  ? "bg-[#E9AA06] text-white"
                                  : ""
                              }`}
                              disabled={!time.available_time}
                            >
                              {time?.start_time} - {time?.end_time}
                            </button>
                          ))
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="py-4">
                  <p className="text-lg text-center capitalize sm:text-sm font-semibold py-4">
                    Select a Date when You want to give Interview
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="md:container lg:container xl:container 2xl:container pb-[5rem]  w-4/5 mx-auto flex  justify-center">
            <button
              type="submit"
              className="border-[1px] bg-black text-white hover:bg-white hover:text-black  border-black ml-[5px] px-10  sm:px-8  py-2 rounded text-lg flex items-center"
            >
              Schedule Your Interview{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Schedular;
