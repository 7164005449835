import React, { Fragment, useEffect, useRef, useState } from "react";
import smallprofilecevtor from "../../../assets/images/smallprofilecevtor.png";
import { useSelector } from "react-redux";
import Pagination from "../../../common/pagination/pagination";
import crossicon from "../../../assets/images/Icons/crossicon.svg";

import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import Skeleton from "react-loading-skeleton";
import LeftSidebar from "../OnboardingDashboard/LeftSidebar";
import MobSideBar from "../mobsidebar";
import AdminNavbar from "../adminNavbar";
import DashboardCards from "../OnboardingDashboard/DashboardCards";
import { Dialog, Transition } from "@headlessui/react";
import Swal from "sweetalert2";
import { DELETE_TEAM_MEMBER } from "../../../utils/baseUrl";

const MyTeam = ({ interviewapproval, interviewStatus }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [openForgetModal, setOpenForgetModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const isLoading = useSelector((state) => state?.userSlice?.loading);

  const [searchPerformed, setSearchPerformed] = useState(false);
  const [team, setTeam] = useState();

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const interview = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.content
  );
  useEffect(() => {
    if (interview?.length > 0) {
      setSearchPerformed(true);
    }
  }, [interview]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [interviewapproval, interviewStatus]);

  const page = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.pages
  );
  const mineInterview = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.total
  );
  const getMyTeam = () => {
    axios
      .get(`/admin/myteam`)
      .then((response) => {
        setTeam(response);
      })
      .catch((error) => {
        return error;
      });
  };
  useEffect(() => {
    getMyTeam();
  }, []);
  const deleteTeamMember = async (id) => {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete your Team Member!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#EBAD07",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!",
    });
    if (confirmResult.isConfirmed) {
    axios
      .delete(DELETE_TEAM_MEMBER(id))
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          getMyTeam();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
 
      .catch((error) => {
        return error;
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        email: email,
      };
      const response = await axios
        .post
        // RESET_PASSWORD, payload
        ();
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          title: `${response?.data?.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
        setOpenForgetModal(false);
        setEmail("");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${response?.data?.message}`,
          confirmButtonColor: "#EEAF06",
        });
      }
    } catch (error) {
    return error;
    }
  };

  const cancelButtonRef = useRef(null);

  return (
    <>
      {/* //////Reset Password////// */}
      <Transition.Root show={openForgetModal} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenForgetModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" overflow-y-auto relative transform w-[30%] lg:w-[50%] md:w-[50%] sm:w-[85%]  h-[400px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8">
                  <div className=" sm:items-start">
                    {/* <form onSubmit={handleAddExperience}> */}
                    <div className="  flex h-16 xl:h-16 md:h-16 sm:h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 ">
                      <Dialog.Title
                        as="h3"
                        className="text-2xl font-medium leading-6 text-gray-900"
                      >
                        Reset Password
                      </Dialog.Title>
                      <img
                        src={crossicon}
                        alt=""
                        className="pr-10"
                        onClick={() => setOpenForgetModal(false)}
                        ref={cancelButtonRef}
                      />
                    </div>
                    {/* </form> */}
                    <div className="mt-3 text-center md:mx-4 sm:ml-4 sm:mt-0 sm:text-left">
                      <div className=" w-[90%]  mx-auto  mt-[1rem] sm:mt-[1rem] md:w-full sm:w-full">
                        <label className=" text-black-500 text-[18px] sm:text-[18px] mb-2 flex items-center my-10 ">
                          {/* <img src={titleicon} alt="email" className="mr-2" />{" "} */}
                          New Password
                        </label>
                        <input
                          type="text"
                          id="password"
                          placeholder="***************"
                          name="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {/* {formik.touched.designation &&
                        formik.errors.designation ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.designation}
                          </p>
                        ) : null} */}
                      </div>

                      <div className=" w-[90%]  mx-auto  mt-[1rem] sm:mt-[1rem] md:w-full sm:w-full">
                        <label className=" text-black-500 text-[18px] sm:text-[18px] mb-2 flex items-center my-10 ">
                          {/* <img src={titleicon} alt="email" className="mr-2" />{" "} */}
                          Confirm new password
                        </label>
                        <input
                          type="text"
                          id="password"
                          placeholder="***************"
                          name="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {/* {formik.touched.designation &&
                        formik.errors.designation ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.designation}
                          </p>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      // type="submit"
                      className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                      onClick={() => handleSubmit()}
                    >
                      Change Password
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="mainsection">
        <div className="section grid grid-cols-[260px_1fr] md:grid-cols-[220px_1fr] sm:grid-cols-1">
          <div className="YourComponentStyles">
            {isMobile ? (
              <MobSideBar currentPage="interviews" />
            ) : (
              <div className="leftsection h-screen md:block">
                <LeftSidebar currentPage="interviews" />
              </div>
            )}
          </div>
          <div className="rightsection bg-[#F1F5F9] ">
            <div className="topsection sm:mt-4 bg-white flex justify-end">
              <div className="sm:hidden">
                <AdminNavbar showlogo={false} />
              </div>
            </div>
            <div className="pl-6 mt-6">
              <DashboardCards />
              <div className="tablesection">
                <div className="Onboarding  pt-4">
                  <h2 className="text-[#495057] font-semibold text-[16px] py-4">
                    HR List
                  </h2>

                  <>
                    <div className="grid grid-cols-[1fr_1.60fr_2fr_2fr]  lg:grid-cols-[1fr_1.60fr_2fr_2fr]  2xl:grid-cols-[1fr_1.60fr_2fr_2fr]   md:grid-cols-1 sm:grid-cols-1 border-[1px] md:gap-6 md:text-center sm:text-center items-center shadow-md mb-4 mr-6 py-4 bg-[#FCC015] md:hidden sm:hidden ">
                      <div className="text-[13px] pl-10 text-white font-medium">
                        Sr#
                      </div>
                      <h2 className="text-[13px] text-white font-medium">
                        Name
                      </h2>
                      <h2 className="text-[13px] text-white font-medium">
                        Email
                      </h2>
                      <h2 className="text-[13px] text-white font-medium">
                        Action
                      </h2>
                    </div>
                    {isLoading ? (
                      <div className="skeleton-loader ">
                        <Skeleton width={1000} count={10} />
                      </div>
                    ) : (
                      <div>
                        {team?.data?.content?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item?.role === "HR" && (
                              <div className="onboardinglist border-[1px] bg-white grid grid-cols-[1fr_1.60fr_2fr_2fr]  lg:grid-cols-[1fr_1.60fr_2fr_2fr]  2xl:grid-cols-[1fr_1.60fr_2fr_2fr]  md:grid-cols-1 sm:grid-cols-1 md:gap-6 md:text-center sm:text-center items-center shadow-md mb-3 mr-6 py-4">
                                <div className="candidateNumber">
                                  <h2 className="text-[14px] pl-10 md:pl-0 sm:pl-0 font-semibold text-[#111827]">
                                    {index < 9 ? `0${index + 1}` : index + 1}
                                  </h2>
                                </div>
                                <div className="candidateinfo flex items-center md:justify-center sm:justify-center gap-2">
                                  <img src={smallprofilecevtor} alt="" />
                                  <div>
                                    <h2 className="text-[13px] text-black font-medium">
                                      {item.first_name} {item.last_name}
                                    </h2>
                                    <p className="text-[#696969] font-normal text-[10px]">
                                      {item.role}
                                    </p>
                                  </div>
                                </div>

                                <div className="company flex sm:flex-col items-center md:justify-center sm:justify-center gap-6">
                                  <div>
                                    <h2 className="text-[13px] text-black font-medium">
                                      {item?.email}
                                    </h2>
                                  </div>
                                </div>
                                <div className="password flex sm:flex-col items-center md:justify-center sm:justify-center gap-6">
                                  <button
                                    onClick={() => setOpenForgetModal(true)}
                                    className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    Change Password
                                  </button>
                                  <button
                                    onClick={() => deleteTeamMember(item._id)}
                                    className="bg-[#d33] hover:bg-white hover:text-black border-[1px] border-[#d33] flex gap-1 items-center justify-center text-white px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </>

                  {/* {mineInterview > 12 ? (
                    <Pagination
                      totalPages={page}
                      currentPage={currentStep}
                      setCurrentPage={setCurrentStep}
                    />
                  ) : null} */}
                </div>

                <div className="Onboarding  py-6">
                  <h2 className="text-[#495057] font-semibold text-[16px] pb-3">
                    Success Manager List
                  </h2>
                  {/* {interview && interview?.length > 0 ? ( */}
                  <>
                    <div className="grid grid-cols-[1fr_1.60fr_2fr_2fr]  lg:grid-cols-[1fr_1.60fr_2fr_2fr]  2xl:grid-cols-[1fr_1.60fr_2fr_2fr]   md:grid-cols-1 sm:grid-cols-1 border-[1px] md:gap-6 md:text-center sm:text-center items-center shadow-md mb-4 mr-6 py-4 bg-[#FCC015] md:hidden sm:hidden ">
                      <div className="text-[13px] pl-10 text-white font-medium">
                        Sr#
                      </div>
                      <h2 className="text-[13px] text-white font-medium">
                        Name
                      </h2>
                      <h2 className="text-[13px] text-white font-medium">
                        Email
                      </h2>
                      <h2 className="text-[13px] text-white font-medium">
                        Actions
                      </h2>
                    </div>
                    {isLoading ? (
                      <div className="skeleton-loader ">
                        <Skeleton width={1000} count={10} />
                      </div>
                    ) : (
                      <div>
                        {team?.data?.content?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item?.role === "MANAGER" && (
                              <div className="onboardinglist border-[1px] bg-white grid grid-cols-[1fr_1.60fr_2fr_2fr]  lg:grid-cols-[1fr_1.60fr_2fr_2fr]  2xl:grid-cols-[1fr_1.60fr_2fr_2fr] md:grid-cols-1 sm:grid-cols-1 md:gap-6 md:text-center sm:text-center items-center shadow-md mb-3 mr-6 py-4">
                                <div className="candidateNumber">
                                  <h2 className="text-[14px] pl-10 md:pl-0 sm:pl-0 font-semibold text-[#111827]">
                                    {index < 9 ? `0${index + 1}` : index + 1}
                                  </h2>
                                </div>
                                <div className="candidateinfo flex items-center md:justify-center sm:justify-center gap-2">
                                  <img src={smallprofilecevtor} alt="" />
                                  <div>
                                    <h2 className="text-[13px] text-black font-medium">
                                      {item.first_name} {item.last_name}
                                    </h2>
                                    <p className="text-[#696969] font-normal text-[10px]">
                                      {item.role}
                                    </p>
                                  </div>
                                </div>

                                <div className="company flex sm:flex-col items-center md:justify-center sm:justify-center gap-6">
                                  <div>
                                    <h2 className="text-[13px] text-black font-medium">
                                      {item?.email}
                                    </h2>
                                  </div>
                                </div>
                                <div className="password flex sm:flex-col items-center md:justify-center sm:justify-center gap-6">
                                  <button
                                    onClick={() => setOpenForgetModal(true)}
                                    className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    Change Password
                                  </button>
                                  <button
                                    onClick={() => deleteTeamMember(item._id)}
                                    className="bg-[#d33] hover:bg-white hover:text-black border-[1px] border-[#d33] flex gap-1 items-center justify-center text-white px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </>

                  {/* {mineInterview > 12 ? (
                    <Pagination
                      totalPages={page}
                      currentPage={currentStep}
                      setCurrentPage={setCurrentStep}
                    />
                  ) : null} */}
                </div>
                <div className="Onboarding  py-6">
                  <h2 className="text-[#495057] font-semibold text-[16px] pb-3">
                    Content Creators List
                  </h2>
                  {/* {interview && interview?.length > 0 ? ( */}
                  <>
                    <div className="grid grid-cols-[1fr_1.60fr_2fr_2fr]  lg:grid-cols-[1fr_1.60fr_2fr_2fr]  2xl:grid-cols-[1fr_1.60fr_2fr_2fr]   md:grid-cols-1 sm:grid-cols-1 border-[1px] md:gap-6 md:text-center sm:text-center items-center shadow-md mb-4 mr-6 py-4 bg-[#FCC015] md:hidden sm:hidden ">
                      <div className="text-[13px] pl-10 text-white font-medium">
                        Sr#
                      </div>
                      <h2 className="text-[13px] text-white font-medium">
                        Name
                      </h2>
                      <h2 className="text-[13px] text-white font-medium">
                        Email
                      </h2>
                      <h2 className="text-[13px] text-white font-medium">
                        Actions
                      </h2>
                    </div>
                    {isLoading ? (
                      <div className="skeleton-loader ">
                        <Skeleton width={1000} count={10} />
                      </div>
                    ) : (
                      <div>
                        {team?.data?.content?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item?.role === "CONTENT-WRITER" && (
                              <div className="onboardinglist border-[1px] bg-white grid grid-cols-[1fr_1.60fr_2fr_2fr]  lg:grid-cols-[1fr_1.60fr_2fr_2fr]  2xl:grid-cols-[1fr_1.60fr_2fr_2fr] md:grid-cols-1 sm:grid-cols-1 md:gap-6 md:text-center sm:text-center items-center shadow-md mb-3 mr-6 py-4">
                                <div className="candidateNumber">
                                  <h2 className="text-[14px] pl-10 md:pl-0 sm:pl-0 font-semibold text-[#111827]">
                                    {index < 9 ? `0${index + 1}` : index + 1}
                                  </h2>
                                </div>
                                <div className="candidateinfo flex items-center md:justify-center sm:justify-center gap-2">
                                  <img src={smallprofilecevtor} alt="" />
                                  <div>
                                    <h2 className="text-[13px] text-black font-medium">
                                      {item.first_name} {item.last_name}
                                    </h2>
                                    <p className="text-[#696969] font-normal text-[10px]">
                                      {item.role}
                                    </p>
                                  </div>
                                </div>

                                <div className="company flex sm:flex-col items-center md:justify-center sm:justify-center gap-6">
                                  <div>
                                    <h2 className="text-[13px] text-black font-medium">
                                      {item?.email}
                                    </h2>
                                  </div>
                                </div>
                                <div className="password flex sm:flex-col items-center md:justify-center sm:justify-center gap-6">
                                  <button
                                    onClick={() => setOpenForgetModal(true)}
                                    className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    Change Password
                                  </button>
                                  <button
                                    onClick={() => deleteTeamMember(item._id)}
                                    className="bg-[#d33] hover:bg-white hover:text-black border-[1px] border-[#d33] flex gap-1 items-center justify-center text-white px-[0.1rem] w-[8rem] py-[5px]  text-[12px]"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </>

                  {/* {mineInterview > 12 ? (
                    <Pagination
                      totalPages={page}
                      currentPage={currentStep}
                      setCurrentPage={setCurrentStep}
                    />
                  ) : null} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTeam;
