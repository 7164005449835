import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { CANDIDATE_SEARCH, CANDIDATE_SEARCH_BY_ADMIN } from "../../utils/baseUrl";

const initialState = {
  loading: false,
  searchCandidate: [],
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchSearchByAdmin = createAsyncThunk(
  "searchAdminSlice/fetchSearchByAdmin",
  async (data) => {
    // console.log("🚀 ~ data:", data)
    if(data.interviewapproval==="PENDING" || data.interviewapproval==="APPROVED" || data.interviewapproval==="COMPLETED"){
    const response = await axios.get(CANDIDATE_SEARCH(data.key,data.interviewapproval,data.role,data.badge));
    // console.log(response,"responsivenesssss");
    return response?.data;
    }else{
        const response = await axios.get(CANDIDATE_SEARCH_BY_ADMIN(data.key,data.interviewapproval,data.role,data.badge));
        // console.log(response,"responsivenesssss");
        return response?.data;
    }
  }
);

const searchAdminSlice = createSlice({
  name: "searchAdminSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSearchByAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchByAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.searchCandidate = action.payload;
      state.error = "";
    });
    builder.addCase(fetchSearchByAdmin.rejected, (state, action) => {
      state.loading = false;
      state.searchCandidate = [];
      state.error = action.error.message;
    });
  },
});

export default searchAdminSlice.reducer;
