import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Vector from "./../../../assets/images/Icons/email.svg";
import Password from "./../../../assets/images/Icons/Password 2.svg";
import logo from "./../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

import bgimg from "../../../assets/images/bgnew2.png";
import { useDispatch, useSelector } from "react-redux";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

import { LogInAdminHandler } from "../../../store/adminLoginSlice/adminLoginSlice";
import Swal from "sweetalert2";
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your e-mail address"),
  password: Yup.string().required("please enter your Password"),
});

const AdminLogin = () => {
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const response=useSelector((state)=>state?.AdminLogInSlice)
  const message = useSelector(
    (state) => state?.AdminLogInSlice?.user
      );
  const [showError,setShowError]=useState(false)
  const dispatch=useDispatch();
  const [backendMessage, setBackendMessage] = useState("");
  const navigate = useNavigate();
  useEffect(()=>{
    if (!message?.success) {
      setShowError(true)
      if(showError){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message?.message,
        confirmButtonColor: '#EEAF06',
      });
    }
    } else {
      localStorage.setItem(
        "accessToken",
        response?.user?.content?.accessToken
      );
        if(response?.user?.content?.customer?.role === "HR"){
      navigate("/interview-dashboard");
        }else if(response?.user?.content?.customer?.role === "MANAGER"){
          navigate("/admin-candidate-dashboard");
        }else if(response?.user?.content?.customer?.role === "CONTENT-WRITER"){
          navigate("/all-users?role=CANDIDATE");
        }else{
          navigate("/admin-interview-dashboard");
        }

    }
  },[message])
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      dispatch(LogInAdminHandler(values));
       
    },
  });

  return (
    <div className="login-page-container  grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1 h-screen overflow-hidden">
      <div className="left">
        <img src={logo} alt="" className="pl-8 pt-4" />
        <div className="">
          <div className="2xl:py-[10.5rem] xl:py-[6rem] lg:py-[6rem] py-[3rem] 2xl:px-36 xl:px-24 lg:px-10 md:px-10 sm:px-12 px-6  md:pr-[1rem] md:w-[80%]  bg-white sm:py-[6.5rem] sm:w-[100%]">
            <h1 className="font-bold text-2xl mb-[64px] ">
              Login for BMG Team
            </h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-[38px]">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="email"
                >
                  <img src={Vector} alt="email" className="mr-2" /> Email
                  Address
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>
              <div className=" relative mb-4">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="password"
                >
                  <img src={Password} alt="password" className="mr-2" />{" "}
                  Password
                </label>
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <button
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center text-sm leading-5"
                type="button"
              >
                {passwordShown ? (
                  <IconEyeOff size={24} />
                ) : (
                  <IconEye size={24} />
                )}
              </button>
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.password}
                  </p>
                ) : null}
                {/* <p className="text-xs mt-1 flex justify-end text-[#E9AA06] ">
                  <a href="#forgot-password">Forgot password?</a>
                </p> */}
              </div>
              <div className="text-red-600">{backendMessage}</div>
              <button
                type="submit"
                className=" bg-gradient-to-r from-[#fcd46c] via-[#f1bc32] to-[#FFC107] mt-8  hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Log In
              </button>
            </form>
            {/* <p className="mt-8  text-center text-[#6B7280] ">
              New to BMG? <Link to={"/candidate-signup"}>Sign up now</Link>
            </p> */}
          </div>
        
        </div>
      </div>
      <div className="right sm:hidden ">
        <img src={bgimg} alt="" className="w-full h-[1000px] 2xl:block xl:block lg:block md:block sm:hidden hidden" />
      </div>
    </div>
  );
};

export default AdminLogin;
