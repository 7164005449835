import React from "react";
import error from "../../assets/images/error404.png";
import CandidateNavbar from "../candidateNavbar";
import { useNavigate } from "react-router-dom";
const Page404 = () => {
    const navigate=useNavigate();
  return (
    <>
      <CandidateNavbar />
      <div className=" flex flex-col  justify-center items-center">
        <h2 className="text-[50px] font-medium py-2">
          {" "}
          Sorry, page not found!
        </h2>
        <div>
          <img src={error} alt="404image" />
        </div>
        <div className=" gap-8 flex items-center mt-16 justify-center w-120">
          <button onClick={()=>navigate(-1)} className="bg-black mt-6 text-white px-12 py-2 border-2 border-black rounded text-lg hover:bg-white hover:text-black">
          Back to Application
          </button>
        </div>
      </div>
    </>
  );
};

export default Page404;
