import React from "react";
import logo from "../../assets/images/logo.png";
import malaysian from "../../assets/images/malaysian.png";
import BMGlogowhite from "../../assets/images/BMGlogowhite.png";
import emailicon from "../../assets/images/emailicon.png";
import messageicon from "../../assets/images/Icons/messageicon.svg";
import phoneicon from "../../assets/images/phoneicon.png";
import Button from "../../common/commonButton/button";
import employerIcon from "../../assets/images/businessman.png";
import candidateIcon from "../../assets/images/employee.png";
import { Link } from "react-router-dom";
const SignupPage = () => {
  return (
    <>
       <div className="container mx-auto sm:w-full h-[250px]  flex flex-col justify-between  py-3 px-6 rounded  ">
        <div className="flex justify-center ">
          <img src={logo} alt="logo" />
        </div>
        <div className="flex justify-center items-center text-[#E9AA06] font-bold text-3xl h-[100px]">
            <h1>Please Choose Your Signup</h1>
        </div>
      </div>
  
      <div className="flex gap-2  justify-center items-center h-[320px]  sm:h-auto sm:mt-4 sm:flex sm:flex-col">
       
        <Link to={"/employer-signup"}>
          <button
            type="submit"
            className="border-[1px] px-[100px] rounded-md   py-[100px] text-xl font-semibold text-[#121823] shadow-sm  sm:ml-3 sm:w-auto hover:text-white hover:bg-[#E9AA06]"
          >
            <div className="  w-20 h-20 mx-auto mb-2 ">
              <img
                src={employerIcon}
                alt="employer"
                className="flex justify-center   "
              />
            </div>
            
            Signup as Employer
          </button>
        </Link>
        <Link to={"/candidate-signup"}>
          <button className="border-[1px] px-[100px] rounded-md   py-[100px] text-xl font-semibold text-[#121823] shadow-sm  sm:ml-3 sm:w-auto hover:text-white hover:bg-[#E9AA06]">
            <div className="  w-20 h-20 mx-auto mb-2">
              <img
                src={candidateIcon}
                alt="candidate"
                className="flex justify-center  "
              />
            </div>
            Signup as Candidate
          </button>
        </Link>
      </div>

    </>
  );
};

export default SignupPage;
