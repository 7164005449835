import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { ALL_EMPLOYERS_INTERVIEW_REQUEST } from "../../utils/baseUrl";



const initialState = {
  loading: false,
  interview: null,
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const employerInterviewRequest = createAsyncThunk(
  "employerInterviewSlice/employerInterviewRequest",
  async (data) => {
    const response = await axios.get(ALL_EMPLOYERS_INTERVIEW_REQUEST);
    return response?.data;
  }
);

const employerInterviewSlice = createSlice({
  name: "employerInterviewSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(employerInterviewRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(employerInterviewRequest.fulfilled, (state, action) => {
     
      state.user = action.payload;
      state.error = "";
      state.loading = false;
    });
    builder.addCase(employerInterviewRequest.rejected, (state, action) => {
      state.loading = false;
      state.user = [];
      state.error = action.error.message;
    });
  },
});

export default employerInterviewSlice.reducer;
