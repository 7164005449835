import React from "react";

const Stepper = ({ step }) => {
  return (
    <>
      <div className="container mx-auto sm:max-w-[85%]">
        {step === "step1" && (
          <div className="stepper grid   md:grid-cols-3  lg:grid-cols-3   xl:grid-cols-3   2xl:grid-cols-3 bg-[#EFEFEF] mb-12 rounded-[50px]">
            <div className="step1 rounded-tl-full  h-[72px] bg-gradient-to-l from-yellow-500 via-[#E9AA06] via-30% to-yellow-300 flex  rounded-r-full justify-center items-center">
              <div className="bg-white rounded-full w-8 h-8 flex items-center justify-center">
                {" "}
                <p className="text-center"> 1</p>
              </div>
              <h2 className="text-white text-[18px] sm:text-[16px] font-medium pl-3">
                Personal Info
              </h2>
            </div>
            <div className="step2 rounded-tl-full h-[72px] bg-[#EFEFEF]  flex justify-center items-center rounded-r-full">
              <div className="bg-[#696969] opacity-50 rounded-full w-8 h-8 flex items-center justify-center md:p-[10px]">
                {" "}
                <p className="text-center">2</p>
              </div>
              <h2 className="text-[#696969] text-[18px] sm:text-[16px] font-medium pl-3">
                Work Experience
              </h2>
            </div>
            <div className="step3 rounded-tl-full h-[72px] bg-[#EFEFEF]  flex justify-center items-center rounded-r-full">
              <div className="bg-[#696969] opacity-50 rounded-full w-8 h-8 flex items-center justify-center">
                {" "}
                <p className="text-center"> 3</p>
              </div>
              <h2 className="text-[#696969] text-[18px] sm:text-[16px] font-medium pl-3">
                Questions
              </h2>
            </div>
          </div>
        )}
        {step === "step2" && (
          <div className="stepper grid  md:grid-cols-3   lg:grid-cols-3   xl:grid-cols-3   2xl:grid-cols-3 bg-[#EFEFEF] mb-12">
            <div className="step1 rounded-tl-full h-[72px] bg-[#EFEFEF]  flex justify-center items-center rounded-r-full">
              <div className="bg-[#696969] opacity-50 rounded-full w-8 h-8 flex items-center justify-center">
                {" "}
                <p className="text-center"> 1</p>
              </div>
              <h2 className="text-[#696969] text-[18px] sm:text-[16px] font-medium pl-3">
                Personal Info
              </h2>
            </div>
            <div className="step2 rounded-tl-full  h-[72px] bg-gradient-to-l from-yellow-500 via-[#E9AA06] via-30% to-yellow-300 flex  rounded-r-full justify-center items-center">
              <div className="bg-white rounded-full w-8 h-8 flex items-center justify-center md:mt-[13px] md:p-[10px]">
                {" "}
                <p className="text-center">2</p>
              </div>
              <h2 className="text-white text-[18px] sm:text-[16px] font-medium pl-3">
                Work Experience
              </h2>
            </div>
            <div className="step3 rounded-tl-full h-[72px] bg-[#EFEFEF]  flex justify-center items-center rounded-r-full">
              <div className="bg-[#696969] opacity-50 rounded-full w-8 h-8 flex items-center justify-center">
                {" "}
                <p className="text-center"> 3</p>
              </div>
              <h2 className="text-[#696969] text-[18px] sm:text-[16px] font-medium pl-3">
                Questions
              </h2>
            </div>
          </div>
        )}
        {step === "step3" && (
          <div className="stepper grid  md:grid-cols-3   lg:grid-cols-3   xl:grid-cols-3   2xl:grid-cols-3 bg-[#EFEFEF] mb-12">
            <div className="step1 rounded-tl-full h-[72px] bg-[#EFEFEF]  flex justify-center items-center rounded-r-full">
              <div className="bg-[#696969] opacity-50 rounded-full w-8 h-8 flex items-center justify-center">
                {" "}
                <p className="text-center"> 1</p>
              </div>
              <h2 className="text-[#696969] text-[18px] sm:text-[16px] font-medium pl-3">
                Personal Info
              </h2>
            </div>
            <div className="step2 rounded-tl-full h-[72px] bg-[#EFEFEF]  flex justify-center items-center rounded-r-full">
              <div className="bg-[#696969] opacity-50 rounded-full w-8 h-8 flex items-center justify-center md:mt-[13px] md:p-[10px]">
                {" "}
                <p className="text-center">2</p>
              </div>
              <h2 className="text-[#696969] text-[18px] sm:text-[16px] font-medium pl-3">
                Work Experience
              </h2>
            </div>
            <div className="step3 rounded-tl-full  h-[72px] bg-gradient-to-l from-yellow-500 via-[#E9AA06] via-30% to-yellow-300 flex rounded-r-full justify-center items-center">
              <div className="bg-white rounded-full w-8 h-8 flex items-center justify-center">
                {" "}
                <p className="text-center"> 3</p>
              </div>
              <h2 className="text-white text-[18px] font-medium pl-3">
                Questions
              </h2>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Stepper;
