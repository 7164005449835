import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RoleBaseAccess = ({ allowedRoles, children }) => {
  const user = useSelector(
    (state) => state?.loginSlice?.user?.content?.customer?.role
  );
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const userRole = user;

  // Check if the user's role is allowed for the route
  const isRoleAllowed = allowedRoles.includes(userRole);

  return token && isRoleAllowed ? (
    <Outlet>{children}</Outlet>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RoleBaseAccess;
