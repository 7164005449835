import React from "react";
import logo from "../../../../assets/images/logo.png";
import emailotpimg from "../../../../assets/images/emailotpimg.png";
import { Link } from "react-router-dom";
const EmailSent = () => {
  return (
    <>
      <div className="container mx-auto sm:w-full  py-3 px-6 rounded flex items-center justify-between">
        <div className="">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="optpage-background flex justify-center items-center">
        <div className="container mx-auto shadow-xl bg-white rounded-xl py-16 max-w-[435px] flex flex-col justify-center items-center mt-16">
          <img src={emailotpimg} alt="" />
          <h2 className="text-[28px] font-semibold py-2"> OTP Verification</h2>
          <h2 className="text-[16px] font-semibold py-2"> Hey, Smith john!</h2>
          <p className="font-light text-[#696969] text-base text-center px-12 py-2 ">
            You've been received an email regarding your upcoming call
          </p>

          <Link to={"/"}>
            <button className="bg-black mt-6 text-white px-24 py-2 rounded border-2 border-black text-lg hover:bg-white hover:text-black">
              Open Mail
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default EmailSent;
