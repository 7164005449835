import { createSlice } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { LOGIN } from "../../utils/baseUrl";

const LogInSlice = createSlice({
  name: "LogInSlice",
  initialState: {
    user: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    LogInStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    LogInSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload?.data;
      state.error = null;
      localStorage.setItem("accessToken", action.payload?.data?.content?.accessToken);
    },
    LogInFailure: (state, action) => {
      state.isLoading = false;
      state.user = action.payload?.data;
      state.error = action.payload;
    },
    LogOutUserReducer: (state) => {
      state.isLoading = false;
      state.user = null;
      state.error = null;
      localStorage.clear();
    },
  },
});
export const { LogInStart, LogInSuccess, LogInFailure, LogOutUserReducer } =
  LogInSlice.actions;

export const LogInUserHandler = (userData) => async (dispatch) => {
  dispatch(LogInStart());
  try {
    const response = await axios.post(LOGIN, userData);
  
    if (response) {
      
      await dispatch(LogInSuccess(response));
      console.log("🚀 ~ LogInUserHandler ~ response:", response?.data)

      return response?.data;

      
    } else {
      throw response;
    }
  } catch (error) {
    dispatch(LogInFailure(error.message));
  }
};

export default LogInSlice.reducer;
