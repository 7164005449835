import logo from "../../../assets/images/logo.png";
import { Fragment } from "react";
// import { LogOutUserReducer } from "../../../store/loginSlice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { fetchUser } from "../../../store/candidateInfoSlice/candidateInfoSlice";
import smallprofilevector from "./../../../assets/images/smallprofilecevtor.png";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { LogOutUserReducer } from "../../../store/adminLoginSlice/adminLoginSlice";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const AdminNavbar = ({ includeContainer, showlogo, logo,setShowEditButton,data }) => {

  const user = useSelector((state) => state?.AdminLogInSlice?.user?.content);
 
 useEffect(()=>{
  if(user?.customer?.role==="CONTENT-WRITER"&& data===5){
    setShowEditButton(true)
  }
 },[user,setShowEditButton])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  const logoutHandler = () => {
    dispatch(LogOutUserReducer());
    navigate("/admin-login");
  };
  return (
    <div
      className={`${
        includeContainer ? "container mx-auto" : ""
      } pt-3 rounded flex items-center justify-between`}
    >
      {showlogo && (
        <div className="">
          <img src={logo} alt="logo" />
        </div>
      )}

      <Menu as="div" className="relative inline-block text-left">
        <div className="flex items-center gap-1">
          <img src={smallprofilevector} alt="" />
      

          <div className="flex flex-col">
            <Menu.Button className="inline-flex items-center w-full justify-center gap-x-1.5 px-3 pt-2 text-lg font-semibold text-gray-900 ">
            
            <h2 className="capitalize  text-[14px] font-semibold">
            {user?.customer?.first_name} {user?.customer?.last_name}
          </h2>
          <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400 "
                aria-hidden="true"
              />
          
         
              {/* {employerData?.first_name} {" "} {employerData?.last_name} */}
           
            </Menu.Button>
            {user?.customer?.role==="HR" && (
            <p className="capitalize text-center text-[14px] text-[#74788d]">
            {user?.customer?.role} 
          </p>
          )}
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <form method="POST" action="#">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="submit"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm"
                      )}
                      onClick={logoutHandler}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </form>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
export default AdminNavbar;
