import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../assets/images/logo.png";
import Emailotp from "../../../../assets/images/Emailotp.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../authenticateApi/authenticateApi";
import { OTP_VERIFICATION, PHONE_OTP_VERIFICATION, RESEND_OTP } from "../../../../utils/baseUrl";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../../../store/candidateInfoSlice/candidateInfoSlice";

const OTP = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phoneNumber = queryParams.get("phoneNumber");
  const firstname = queryParams.get("firstname");
  const last_name = queryParams.get("last_name");
  const receivedData = location.state?.item;
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.userSlice?.user?.content);

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);

  const navigate = useNavigate();
  const [phoneOtp, setPhoneOtp] = useState(["", "", "", ""]);
  const [emailOtp, setEmailOtp] = useState(["", "", "", ""]);
  const [backendMessage, setBackendMessage] = useState("");
useEffect(()=>{
  Swal.fire({
    // icon: "error",
    icon: "info",
    title: "Verify First",
    text: "Please verify your OTP",
    showConfirmButton: false,
    timer: 2000,
  });
},[])
  useEffect(() => {
    if (!data) {
      dispatch(fetchUser());
    }
  }, [data, dispatch]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const phoneOtpValue = phoneOtp.join("");
    const emailOtpValue = emailOtp.join("");

    const payload = {
      otp: emailOtpValue,
    };

    try {
      const response = await axios.post(PHONE_OTP_VERIFICATION, payload);

      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          title: `${response?.data?.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${response?.data?.message}`,
          confirmButtonColor: "#EEAF06",
        });
      }
    } catch (error) {
      setBackendMessage(error?.response?.data?.message);
     return error;
    }
  };

  const handleOTPChange = (value, index, type) => {
    let newOtp = type === "phone" ? [...phoneOtp] : [...emailOtp];
    newOtp[index] = value;

    if (value.length === 0 && index > 0) {
      // If the current input is empty and not the first input, focus on the previous input
      if (type === "phone") {
        // Handle phone OTP
      } else {
        if (index === 3) {
          // If the backspace is pressed in the last input, go to the previous input
          inputRef3.current.focus();
        } else if (index === 2) {
          // If the backspace is pressed in the second last input, go to the previous input
          inputRef2.current.focus();
        } else if (index === 1) {
          // If the backspace is pressed in the third input, go to the previous input
          inputRef1.current.focus();
        }
      }
    } else if (value.length === 1 && index < 3) {
      // If a digit is entered and not the last input, focus on the next input
      if (type === "phone") {
        // Handle phone OTP
      } else {
        if (index === 0) inputRef2.current.focus();
        else if (index === 1) inputRef3.current.focus();
        else if (index === 2) inputRef4.current.focus();
      }
    }

    if (type === "phone") {
      setPhoneOtp(newOtp);
    } else {
      setEmailOtp(newOtp);
    }
  };

  const handlePaste = (e, type) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain");

    if (pastedText.length === 4) {
      // If pasted value is 4 digits, distribute each digit to individual fields
      const newOtp = pastedText.split("");
      if (type === "phone") {
        // Handle phone OTP
      } else {
        setEmailOtp(newOtp);
        inputRef4.current.focus(); // Focus on the last input field after pasting
      }
    }
  };

  const resendOTP = () => {
    const payload={
      email:receivedData
    }
    axios
      .post(RESEND_OTP,payload)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        setBackendMessage(error?.response?.data?.message);
        return error;
      });
  };
  const maskedPhoneNumber = phoneNumber?.replace(/\d(?=\d{4})/g, "*");
  return (
    <>
      <div className="container mx-auto  sm:w-full  py-3 px-6 rounded flex items-center justify-between">
        <div className="">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="optpage-background flex justify-center items-center">
        <div className="container mx-auto shadow-xl bg-white rounded-xl py-16 max-w-[835px] flex flex-col justify-center items-center">
          <img src={Emailotp} alt="otpImage" />
          <h2 className="text-[28px] font-semibold py-2"> OTP Verification</h2>
          <h2 className="text-[16px] font-semibold py-2">
            {" "}
            Hey, {firstname} {last_name}!
          </h2>
          <p className="font-light text-[#696969] text-base text-center px-[10rem] py-2 ">
            An OTP has been sent to your {maskedPhoneNumber? maskedPhoneNumber: "Phone Number"}
          </p>
          <div className="otpfields mt-8 flex gap-16">
            {/* <div className="phoneotp ">
              <h2 className="text-[16px] font-medium py-2">Phone OTP</h2>

              <input
                type="text"
                name="phone_otp"
                value={phoneOtp[0]}
                onChange={(e) => handleOTPChange(e.target.value, 0, "phone")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 w-14 p-4 rounded-lg border-2 mr-5 "
              />
              <input
                type="text"
                name="phone_otp"
                value={phoneOtp[1]}
                onChange={(e) => handleOTPChange(e.target.value, 1, "phone")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2 mr-5"
              />
              <input
                type="text"
                name="phone_otp"
                value={phoneOtp[2]}
                onChange={(e) => handleOTPChange(e.target.value, 2, "phone")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2 mr-5"
              />
              <input
                type="text"
                name="phone_otp"
                value={phoneOtp[3]}
                onChange={(e) => handleOTPChange(e.target.value, 3, "phone")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2"
              />
            </div> */}
            <div className="emailotp ">
              <h2 className="text-[16px] font-medium py-2"> OTP</h2>

              <input
                ref={inputRef1}
                type="text"
                name="otp"
                value={emailOtp[0]}
                onPaste={(e) => handlePaste(e, "otp")}
                onChange={(e) => handleOTPChange(e.target.value, 0, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 w-14 p-4 rounded-lg border-2 mr-5"
              />
              <input
                ref={inputRef2}
                type="text"
                name="otp"
                value={emailOtp[1]}
                onPaste={(e) => handlePaste(e, "otp")}
                onChange={(e) => handleOTPChange(e.target.value, 1, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2 mr-5"
              />
              <input
                ref={inputRef3}
                type="text"
                name="otp"
                onPaste={(e) => handlePaste(e, "otp")}
                value={emailOtp[2]}
                onChange={(e) => handleOTPChange(e.target.value, 2, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2 mr-5"
              />
              <input
                ref={inputRef4}
                type="text"
                name="otp"
                onPaste={(e) => handlePaste(e, "otp")}
                value={emailOtp[3]}
                onChange={(e) => handleOTPChange(e.target.value, 3, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2"
              />
            </div>
          </div>

          <button
            onClick={resendOTP}
            className="font-light text-[#696969] text-base text-center px-16 py-8 "
          >
            OTP not Received?
            <span className="text-[#E9AA06] font-semibold]"> Resend</span>{" "}
          </button>
          <h2>{backendMessage}</h2>
          <button
            onClick={handleSubmit}
            className="bg-black mt-6 text-white px-24 py-2 border-2 border-black rounded text-lg hover:bg-white hover:text-black"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default OTP;
