import React from 'react'
import check from '../../assets/images/check.png';
const VerifiedButton = () => {
  return (
  <span className='pl-1'>
    <img src={check} alt='check' className='w-4 h-4'/>
  </span>
  )
}

export default VerifiedButton;
