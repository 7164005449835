import React, { useRef } from "react";
import { Fragment, useState } from "react";
import logo from "../../../assets/images/logo.png";

import { useNavigate } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import password from "../../../assets/images/Icons/Password 2.svg";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import AdminNavbar from "../adminNavbar";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import crossicon from "../../../assets/images/Icons/crossicon.svg";
import iconnn from "../../../assets/images/Icons/iconnn.svg";
import titleicon from "../../../assets/images/Icons/titleicon.svg";
import email from "../../../assets/images/Icons/email.svg";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import { ADD_MORE_ADMIN } from "../../../utils/baseUrl";
import Swal from "sweetalert2";
import { IconUsersPlus } from "@tabler/icons-react";
import { IconUsersGroup } from "@tabler/icons-react";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { Tooltip } from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import myTeam from "../../../assets/images/team.png";
import PendingIcon from "../../../assets/images/pendingInterviews.png";
import acceptingIcon from "../../../assets/images/acceptInterview.png";
import completeIcon from "../../../assets/images/completeInterview.png";
import allemployeIcon from "../../../assets/images/allEmployees.png";
import allCandidateIcon from "../../../assets/images/allCandidate.png";
import addTeamMember from "../../../assets/images/addTeamMember.png";
import employerInterviewIcon from "../../../assets/images/employerInterview.png";
import slotIcon from "../../../assets/images/management.png";
import rejectCandidatesIcon from "../../../assets/images/rejectCandidates.png";
import availableCandidatesIcon from "../../../assets/images/avaiableCandidates.png";
import pendingCandidatesIcon from "../../../assets/images/pendingCandidates.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MobSideBar = ({ currentPage }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [openMember, setOpenMember] = useState(false);
  const user = useSelector((state) => state?.AdminLogInSlice?.user?.content);
  const [activeItem, setActiveItem] = useState("1");
  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const handleMyTeam = (item) => {
    localStorage.setItem("color", item);
    // setSidebarOpen(false)
    navigate(`/my-team`);
  };

  const handleCandidate = (value, item) => {
    localStorage.setItem("color", item);
    navigate(`/admin-candidate-dashboard?approval=${value}`);
  };

  const handleSlotManagement = (item) => {
    localStorage.setItem("color", item);
    navigate(`/slot-management`);
  };
  const handleAllUsers = (link, status, item, completeStatus) => {
    localStorage.setItem("color", item);

    if (user?.customer?.role === "SUPER-ADMIN") {
      navigate(
        `/admin-interview-dashboard?approval=${link}&interviewStatus=${status}`
      );
    } else if (user?.customer?.role === "HR") {
      navigate(
        `/interview-dashboard?approval=${link}&completeRequest=${completeStatus}`
      );
    }
    setSidebarOpen(false);
  };

  const handleAllEmployersCandidates = (link, item) => {
    localStorage.setItem("color", item);
    setActiveItem(item);
    navigate(`/all-users?role=${link}`);
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
  });

  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleUpdate = async () => {
    for (const key in formData) {
      if (formData[key].trim() === "") {
        toast(`Please Fill all the Fields.`);
        // alert(`Please fill the ${key} field.`);
        return; // Exit the function if any field is empty
      }
    }
    await axios
      .post(ADD_MORE_ADMIN, formData)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setOpenMember(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {});
  };

  const handleEmployerRequest = (value, item) => {
    localStorage.setItem("color", item);
    navigate(`/employer-interviews`);
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  {/* <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img className="h-8 pl-4 w-auto" src={logo} alt="logo" />
                    </div>
                   
                  </div> */}

                  {/* <aside
                      id="default-sidebar"
                      className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform translate-x-0 sm:translate-x-full "
                      aria-label="Sidebar"
                    > */}
                  <div className="h-full px-3 py-4 overflow-y-auto w-[88%] bg-[#f5f5f8] dark:bg-gray-800">
                    <div className="flex items-center justify-start pl-4 pb-12 pt-5">
                      <img src={logo} alt="logo" className="text-center" />
                    </div>
                    <ul className="space-y-2 font-medium pl-6">
                      {(user?.customer?.role === "HR" ||
                        user?.customer?.role === "SUPER-ADMIN") && (
                        <>
                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "1"
                                ? "bg-[#FCC015] rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() =>
                                handleAllUsers(
                                  "PENDING",
                                  "CANDIDATE",
                                  "1",
                                  "notCompleted"
                                )
                              }
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d] rounded-lg  hover:bg-[#FCC015] hover:text-white group"
                            >
                              <img
                                src={PendingIcon}
                                alt="teamicon"
                                className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                              />
                              <span
                                className={`ms-3  ${
                                  localStorage.getItem("color") === "1"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                Requested Interviews
                              </span>
                            </a>
                          </li>
                          <li
                            className={` cursor-pointer ${
                              localStorage.getItem("color") === "2"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() =>
                                handleAllUsers(
                                  "APPROVED",
                                  "CANDIDATE",
                                  "2",
                                  "notCompleted"
                                )
                              }
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                            >
                               <img
                      src={acceptingIcon}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "2"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                Accepted Interviews
                              </span>
                              {/* <span className="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">
                    Pro
                  </span> */}
                            </a>
                          </li>
                          <li
                            className={` cursor-pointer ${
                              localStorage.getItem("color") === "11"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() =>
                                handleAllUsers(
                                  "COMPLETED",
                                  "CANDIDATE",
                                  "11",
                                  "completed"
                                )
                              }
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                            >
                             <img
                      src={completeIcon}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "11"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                Completed Interviews
                              </span>
                              {/* <span className="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">
                    Pro
                  </span> */}
                            </a>
                          </li>
                          {user?.customer?.role === "HR" && (
                            <li
                              className={` cursor-pointer ${
                                localStorage.getItem("color") === "12"
                                  ? "bg-[#FCC015]  rounded-md"
                                  : ""
                              }`}
                            >
                              <a
                                onClick={() => handleSlotManagement("12")}
                                className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                              >
                                <img
                        src={slotIcon}
                        alt="teamicon"
                        className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                      />
                                <span
                                  className={`flex-1 ms-3 whitespace-nowrap  ${
                                    localStorage.getItem("color") === "12"
                                      ? " text-white"
                                      : ""
                                  }`}
                                >
                                  Slot Management
                                </span>
                              </a>
                            </li>
                          )}
                        </>
                      )}

                      {(user?.customer?.role === "HR" ||
                        user?.customer?.role === "MANAGER" ||
                        user?.customer?.role === "SUPER-ADMIN") && (
                        <>
                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "3"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() =>
                                handleAllEmployersCandidates("CANDIDATE", "3")
                              }
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                            >
                               <img
                      src={allCandidateIcon}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "3"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                All Candidates
                              </span>
                            </a>
                          </li>
                        </>
                      )}

                      {(user?.customer?.role === "MANAGER" ||
                        user?.customer?.role === "SUPER-ADMIN") && (
                        <>
                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "4"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() =>
                                handleAllEmployersCandidates("EMPLOYEE", "4")
                              }
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d] rounded-lg  hover:bg-[#FCC015] hover:text-white group"
                            >
                             <img
                      src={allemployeIcon}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "4"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                All Employers
                              </span>
                              {/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    3
                  </span> */}
                            </a>
                          </li>

                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "5"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() => handleCandidate("HOLD_ON", "5")}
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                            >
                              <img
                      src={pendingCandidatesIcon}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 flex items-center ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "5"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                Pending Candidates
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Candidates that have pending Interviews "
                                >
                                  <Tooltip
                                    id="my-tooltip"
                                    style={{ fontSize: "12px" }}
                                  />
                                  <IconInfoCircleFilled className="w-[16px] h-[16px]  ml-2" />
                                </a>
                              </span>
                            </a>
                          </li>
                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "6"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() => handleCandidate("CLEAR", "6")}
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                            >
                               <img
                      src={availableCandidatesIcon}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 flex items-center ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "6"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                Available Candidates
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Candidates who available for Hiring "
                                >
                                  <Tooltip
                                    id="my-tooltip"
                                    style={{ fontSize: "12px" }}
                                  />
                                  <IconInfoCircleFilled className="w-[16px] h-[16px]  ml-2" />
                                </a>
                              </span>
                            </a>
                          </li>
                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "7"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() => handleCandidate("NO_CLEAR", "7")}
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg hover:bg-[#FCC015] hover:text-white  group"
                            >
                               <img
                      src={rejectCandidatesIcon}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 flex items-center ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "7"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                Rejected Candidates
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Candidates who Failed in interview with HR "
                                >
                                  <Tooltip
                                    id="my-tooltip"
                                    style={{ fontSize: "12px" }}
                                  />
                                  <IconInfoCircleFilled className="w-[16px] h-[16px]  ml-2" />
                                </a>
                              </span>
                            </a>
                          </li>
                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "8"
                                ? "bg-[#FCC015] rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() =>
                                handleEmployerRequest("employer-Interview", "8")
                              }
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                            >
                            <img
                      src={employerInterviewIcon}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "8"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                Employer Interviews
                              </span>
                            </a>
                          </li>
                        </>
                      )}
                      {user?.customer?.role === "SUPER-ADMIN" && (
                        <>
                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "9"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() => handleMyTeam("9")}
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                            >
                        <img
                      src={myTeam}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 ms-3 whitespace-nowrap  ${
                                  localStorage.getItem("color") === "9"
                                    ? " text-white"
                                    : ""
                                }`}
                              >
                                My Team
                              </span>
                            </a>
                          </li>
                          <li
                            className={`cursor-pointer ${
                              localStorage.getItem("color") === "10"
                                ? "bg-[#FCC015]  rounded-md"
                                : ""
                            }`}
                          >
                            <a
                              onClick={() => {
                                localStorage.setItem("color", 10);
                                setOpenMember(true);
                                setSidebarOpen(false);
                              }}
                              className="flex items-center p-2 font-normal  text-[13px] text-[#545a6d]  rounded-lg  hover:bg-[#FCC015] hover:text-white  group"
                            >
                               <img
                      src={addTeamMember}
                      alt="teamicon"
                      className="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 group-hover:text-gray-900 "
                    />
                              <span
                                className={`flex-1 ms-3 whitespace-nowrap ${
                                  localStorage.getItem("color") === "10"
                                    ? " text-white"
                                    : ""
                                } `}
                              >
                                Add Team Member
                              </span>
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                  {/* </aside> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openMember} as={Fragment}>
          <Dialog
            as="div"
            className=" z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpenMember}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform w-[50%] md:w-[80%] sm:w-[90%] h-[530px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all ">
                    <div className="">
                      <form>
                        <div className=" flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2]   ">
                          <Dialog.Title
                            as="h3"
                            className="text-2xl font-medium leading-6 text-gray-900"
                          >
                            Add Member
                          </Dialog.Title>
                          <img
                            src={crossicon}
                            alt=""
                            className="pr-10"
                            onClick={() => setOpenMember(false)}
                            ref={cancelButtonRef}
                          />
                        </div>
                      </form>
                      <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                        <div className=" mx-8 mt-[1rem]">
                          <div className="grid grid-cols-2 gap-3">
                            <div className="firstname">
                              <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                                <img
                                  src={iconnn}
                                  alt="email"
                                  className="mr-2"
                                />{" "}
                                First Name
                              </label>
                              <input
                                type="text"
                                id="first_name"
                                placeholder="First Name"
                                name="first_name"
                                value={formData.first_name}
                                onChange={(e) =>
                                  handleChange("first_name", e.target.value)
                                }
                                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              />
                            </div>
                            <div className="lastname">
                              <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                                <img
                                  src={iconnn}
                                  alt="email"
                                  className="mr-2"
                                />{" "}
                                Last Name
                              </label>
                              <input
                                type="text"
                                id="last_name"
                                placeholder="Last Name"
                                name="last_name"
                                value={formData.last_name}
                                onChange={(e) =>
                                  handleChange("last_name", e.target.value)
                                }
                                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-3">
                            <div className="email">
                              <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                                <img src={email} alt="email" className="mr-2" />{" "}
                                Email
                              </label>
                              <input
                                type="email"
                                id="email"
                                placeholder="email"
                                name="email"
                                value={formData.email}
                                onChange={(e) =>
                                  handleChange("email", e.target.value)
                                }
                                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              />
                            </div>
                            <div className="password relative">
                              <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                                <img
                                  src={password}
                                  alt="email"
                                  className="mr-2"
                                />{" "}
                                Password
                              </label>
                              <input
                                type={passwordShown ? "text" : "password"}
                                id="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={(e) =>
                                  handleChange("password", e.target.value)
                                }
                                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              />
                              <button
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-0 top-[4.6rem] pr-3 flex items-center text-sm leading-5"
                                type="button"
                              >
                                {passwordShown ? (
                                  <IconEyeOff size={24} />
                                ) : (
                                  <IconEye size={24} />
                                )}
                              </button>
                            </div>
                          </div>

                          <div>
                            <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                              <img
                                src={titleicon}
                                alt="email"
                                className="mr-2"
                              />{" "}
                              Role
                            </label>
                            <select
                              id="role"
                              name="role"
                              value={formData.role}
                              onChange={(e) =>
                                handleChange("role", e.target.value)
                              }
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled>
                                Role
                              </option>

                              <option value="HR">HR</option>
                              <option value="MANAGER">
                                Customer Success Manager
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => handleUpdate()}
                      >
                        Save
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {toast && <ToastContainer />}
        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex justify-between h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />
            <div className="2xl:hidden xl:hidden lg:hidden ">
              <AdminNavbar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobSideBar;
