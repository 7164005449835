import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { ADMIN_PROFILE } from "../../utils/baseUrl";


const initialState = {
  loading: false,
  admin: null,
  error: "",
};

// if candidate Login then hit this api and render candidate Dashboard
export const fetchAdminProfile = createAsyncThunk(
  "adminSlice/fetchAdminProfile",
  async (data) => {
    const response = await axios.get(ADMIN_PROFILE);
   
    return response?.data;
  }
);

const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAdminProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdminProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.admin = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAdminProfile.rejected, (state, action) => {
      state.loading = false;
      state.admin = [];
      state.error = action.error.message;
    });
  },
});

export default adminSlice.reducer;
