
import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const AdminAccess = ({ allowedRoles,children  }) => {
      const user=useSelector((state)=>state?.AdminLogInSlice?.user?.content?.customer?.role)
    const token = localStorage.getItem("accessToken");
    const location = useLocation();
  const userRole = user;
  
  const isRoleAllowed = allowedRoles.includes(userRole);

  return token && isRoleAllowed ? (
    <Outlet>{children}</Outlet>
  ) : (
    <Navigate to="/admin-login" state={{ from: location }} replace />
  );
};

export default AdminAccess;