import React, { Fragment, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Vector from "./../../../assets/images/Icons/email.svg";
import Password from "./../../../assets/images/Icons/Password 2.svg";
import logo from "./../../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import crossicon from "../../../assets/images/Icons/crossicon.svg";
import employerIcon from "../../../assets/images/businessman.png";
import candidateIcon from "../../../assets/images/employee.png";

import { IconEye, IconEyeOff } from "@tabler/icons-react";
import bgimg from "../../../assets/images/bgnew2.png";
import { useDispatch, useSelector } from "react-redux";
import { LogInUserHandler } from "../../../store/loginSlice/loginSlice";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Dialog, Transition } from "@headlessui/react";
import axios from "../authenticateApi/authenticateApi";
import { RESET_PASSWORD } from "../../../utils/baseUrl";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your e-mail address"),
  password: Yup.string().required("please enter your Password"),
});

const LoginForm = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [openForgetModal, setOpenForgetModal] = useState(false);
  const cancelButtonRef = useRef(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [showError, setShowError] = useState(false);
  const backendMessage = useSelector(
    (state) => state?.loginSlice?.user?.message
  );

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const dispatch = useDispatch();
  const role = useSelector(
    (state) => state?.loginSlice?.user?.content?.customer?.role
  );
  const message = useSelector((state) => state?.loginSlice?.user);
 
  const handleSubmit = async () => {
    try {
      const payload = {
        email: email,
      };
      const response = await axios.post(RESET_PASSWORD, payload);
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          title: `${response?.data?.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
        setOpenForgetModal(false);
        setEmail("");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${response?.data?.message}`,
          confirmButtonColor: "#EEAF06",
        });
      }
    } catch (error) {
     return error;
    }
  };

  useEffect(() => {
   
    if (!message?.success) {
      setShowError(true);
      if (showError) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: backendMessage,
          confirmButtonColor: "#EEAF06",
        });
      }
    } 
    
    
    else {
      if (role === "EMPLOYEE") {
        navigate("/top-candidate");
      } else if (role === "CANDIDATE" ) {
        navigate("/candidate-dashboard");
      }
    }
  }, [role, message]);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const resp=await dispatch(LogInUserHandler(values))
      
        if (resp?.success===false && resp?.message==='Please Verify First'){
          navigate('/otp', { state: { item: values?.email }, });
        }
      } catch (error) {
        return error;
      }
    },
  });

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" overflow-y-auto relative transform w-[30%] xl:w-[40%] lg:w-[50%] md:w-[60%] h-[360px] sm:h-[430px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-[60%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl sm:text-lg font-medium leading-6 text-gray-900"
                        >
                          Choose One
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10 cursor-pointer"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="flex gap-2 mx-2 justify-center items-center  h-[76%] sm:h-auto sm:mt-4 sm:flex sm:flex-col">
                    <Link to={"/employer-signup"}>
                      <button
                        type="submit"
                        className="border-[1px] px-[40px] rounded-md   py-[40px] text-sm font-semibold text-[#E9AA06] shadow-sm  sm:ml-3 sm:w-auto hover:text-white hover:bg-[#E9AA06]"
                      >
                        <div className="  w-12 h-12 mx-auto mb-2 ">
                          <img
                            src={employerIcon}
                            alt="employer"
                            className="flex justify-center   "
                          />
                        </div>
                        Signup as Employer
                      </button>
                    </Link>
                    <Link to={"/candidate-signup"}>
                      <button className="border-[1px] px-[40px] rounded-md   py-[40px] text-sm font-semibold text-[#E9AA06] shadow-sm  sm:ml-3 sm:w-auto hover:text-white hover:bg-[#E9AA06]">
                        <div className="  w-12 h-12 mx-auto mb-2">
                          <img
                            src={candidateIcon}
                            alt="candidate"
                            className="flex justify-center  "
                          />
                        </div>
                        Signup as Candidate
                      </button>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* //////Reset Password////// */}
      <Transition.Root show={openForgetModal} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenForgetModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" overflow-y-auto relative transform w-[40%] sm:w-[85%]  h-[300px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8">
                  <div className=" sm:items-start">
                    {/* <form onSubmit={handleAddExperience}> */}
                    <div className="  flex h-16 xl:h-16 md:h-16 sm:h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 ">
                      <Dialog.Title
                        as="h3"
                        className="text-2xl font-medium leading-6 text-gray-900"
                      >
                        Email Verification
                      </Dialog.Title>
                      <img
                        src={crossicon}
                        alt=""
                        className="pr-10"
                        onClick={() => setOpenForgetModal(false)}
                        ref={cancelButtonRef}
                      />
                    </div>
                    {/* </form> */}
                    <div className="mt-3 text-center md:mx-4 sm:ml-4 sm:mt-0 sm:text-left">
                      <div className=" w-[90%]  mx-auto  mt-[1rem] sm:mt-[1rem] md:w-full sm:w-full">
                        <label className=" text-black-500 text-[18px] sm:text-[18px] mb-2 flex items-center my-10 ">
                          {/* <img src={titleicon} alt="email" className="mr-2" />{" "} */}
                          Enter Your Email
                        </label>
                        <input
                          type="text"
                          id="email"
                          placeholder="abc@gmail.com"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {/* {formik.touched.designation &&
                        formik.errors.designation ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.designation}
                          </p>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      // type="submit"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md mt-4   py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={() => handleSubmit()}
                    >
                      Verify
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="login-page-container grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1 h-screen overflow-hidden ">
        <div className="left">
          <img src={logo} alt="" className="pl-8 pt-4" />
          <div className="2xl:py-[7.5rem] xl:py-[5rem] lg:py-[6rem] py-[3rem] 2xl:px-36 xl:px-24 lg:px-10 md:px-10 sm:px-12 px-6  md:pr-[1rem] md:w-[80%]  bg-white sm:py-[6.5rem] sm:w-[100%]">
            <h1 className="font-semibold text-[30px] xl:text-[24px] lg:text-[24px] md:text-[18px] sm:text-[15px] mb-[64px] xl:mb-[40px] ">
              Log in to your account
            </h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-[38px]">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="email"
                >
                  <img src={Vector} alt="email" className="mr-2" /> Email
                  Address
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="abc@gmail.com"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>
              <div className="mb-12 xl:mb-10 lg:mb-8 md:mb-5 sm:mb-5 relative">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="password"
                >
                  <img src={Password} alt="password" className="mr-2" />{" "}
                  Password
                </label>
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="********"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <button
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center text-sm leading-5"
                  type="button"
                >
                  {passwordShown ? (
                    <IconEye size={24} />
                  ) : (
                    <IconEyeOff size={24} />
                  )}
                </button>
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.password}
                  </p>
                ) : null}
              </div>

              {/* <div className="text-red-600">{backendMessage}</div> */}
              <button
                type="submit"
                className="group transition-all bg-gradient-to-l from-[#E9AA06] to-[#FFC107] w-full text-black font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline "
              >
                Log In
              </button>
            </form>
            <p className="text-xs mt-1 flex justify-end text-[#E9AA06] ">
              <button
                onClick={() => setOpenForgetModal(true)}
                className="text-[#E9AA06]"
              >
                Forgot Your password?
              </button>
            </p>
            <p className="mt-8  text-center text-[#6B7280] ">
              New to BMG?{" "}
              <Link to={"/signup"}>
                {" "}
                <button className="text-[#E9AA06] ">Sign up now</button>
              </Link>
            </p>
          </div>
        </div>
        <div className="right sm:hidden ">
          <img
            src={bgimg}
            alt=""
            className="w-full h-[1000px] 2xl:block xl:block lg:block md:block sm:hidden hidden"
          />
        </div>
      </div>
    </>
  );
};

export default LoginForm;
