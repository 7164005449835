import React, { Fragment, useEffect, useRef, useState } from "react";
import logo from "../../../../assets/images/logo.png";
import Emailotp from "../../../../assets/images/Emailotp.png";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import smallprofilevector from "../../../../assets/images/smallprofilevector.png";

import axios from "../../authenticateApi/authenticateApi";
import { BASE_URL, OTP_VERIFICATION, RESEND_OTP, SEND_OTP,EMAIL_VERIFY } from "../../../../utils/baseUrl";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../../../store/candidateInfoSlice/candidateInfoSlice";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { LogOutUserReducer } from "../../../../store/loginSlice/loginSlice";
import DropDown from "../../../../common/employerNavbar/DropDown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EmailVerify = ({ includeContainer }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);
  const user = useSelector((state) => state?.userSlice?.user);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phoneNumber = queryParams.get("phoneNumber");
  const firstname = queryParams.get("firstname");
  const last_name = queryParams.get("last_name");

  const data = useSelector((state) => state?.userSlice?.user?.content?.role);


  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);

  const navigate = useNavigate();
  const [phoneOtp, setPhoneOtp] = useState(["", "", "", ""]);
  const [emailOtp, setEmailOtp] = useState(["", "", "", ""]);
  const [backendMessage, setBackendMessage] = useState("");

  useEffect(() => {
    if (!data) {
      dispatch(fetchUser());
    }
  }, [data, dispatch]);

  const logoutHandler = () => {
    dispatch(LogOutUserReducer());
    navigate("/");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const phoneOtpValue = phoneOtp.join("");
    const emailOtpValue = emailOtp.join("");
    const number=parseInt(emailOtpValue,10)
    if (isNaN(number)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please enter the OTP`,
        confirmButtonColor: "#EEAF06",
      });
      return; 
    }
    const payload = {
      otp: number,
    };
 
    
     
    try {
      const response = await axios.post(EMAIL_VERIFY, payload);

      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          title: `${response?.data?.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${response?.data?.message}`,
          confirmButtonColor: "#EEAF06",
        });
      }
    } catch (error) {
      setBackendMessage(error?.response?.data?.message);
    return error;
    }
  };

  const handleOTPChange = (value, index, type) => {
    let newOtp = type === "phone" ? [...phoneOtp] : [...emailOtp];
    newOtp[index] = value;

    if (value.length === 0 && index > 0) {
     
      if (type === "phone") {
        // Handle phone OTP
      } else {
        if (index === 3) {
          inputRef3.current.focus();
        } else if (index === 2) {
          inputRef2.current.focus();
        } else if (index === 1) {
          inputRef1.current.focus();
        }
      }
    } else if (value.length === 1 && index < 3) {
     
      if (type === "phone") {
      
      } else {
        if (index === 0) inputRef2.current.focus();
        else if (index === 1) inputRef3.current.focus();
        else if (index === 2) inputRef4.current.focus();
      }
    }

    if (type === "phone") {
      setPhoneOtp(newOtp);
    } else {
      setEmailOtp(newOtp);
    }
  };

  const handlePaste = (e, type) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain");

    if (pastedText.length === 4) {
    
      const newOtp = pastedText.split("");
      if (type === "phone") {
        // Handle phone OTP
      } else {
        setEmailOtp(newOtp);
        inputRef4.current.focus(); // Focus on the last input field after pasting
      }
    }
  };

  const resendOTP = () => {
    axios
      .post(SEND_OTP)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        setBackendMessage(error?.response?.data?.message);
       return error;
      });
  };
  const maskedPhoneNumber = user?.content?.cell_phone?.replace(
    /\d(?=\d{4})/g,
    "*"
  );
  return (
    <>
      {data === "CANDIDATE" ? (
        <div className="nav-sec mx-auto  flex items-center justify-between sm:flex-col sm:items-baseline py-3 px-6">
          <img src={logo} alt="" className="ml-12" />
          <div className="rightsection flex items-center gap-10 sm:mt-4">
        
            <Menu as="div" className="relative inline-block text-left">
              <div className="flex items-center">
                <img
                  src={`${BASE_URL}/profile/${user?.content?.profile_picture}`}
                  alt="profilePic"
                  className="h-[60px] w-[60px] rounded-full object-cover"
                />
                <div className="flex flex-col">
                  <Menu.Button className="inline-flex items-center w-full justify-center gap-x-1.5  bg-white px-3 py-2 text-[15px] font-semibold text-gray-900 hover:bg-gray-50">
                    {user?.content?.first_name} {user?.content?.last_name}
                    <ChevronDownIcon
                      className="-mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <h2 className="text-[12px] font-normal pl-4">
                    {user?.content?.title}
                  </h2>
                </div>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/candidate-dashboard"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          My Profile
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/email-verification"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Verify Email Address
                        </a>
                      )}
                    </Menu.Item>

                    <form method="POST" action="#">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="submit"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}
                            onClick={logoutHandler}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </form>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      ) : (
        <>
          <div
            className={` ${
              includeContainer ? "container mx-auto" : ""
            } py-3 px-6 rounded flex items-center justify-between sm:items-baseline sm:gap-3 sm:max-w-[80%] `}
          >
            <div className="">
              <img src={logo} alt="logo" />
            </div>

            <DropDown />
          </div>
        </>
      )}

      <div className="optpage-background flex justify-center items-center">
        <div className="container mx-auto shadow-xl bg-white rounded-xl py-16 max-w-[835px] flex flex-col justify-center items-center">
          <img src={Emailotp} alt="otpImage" />
          <h2 className="text-[28px] font-semibold py-2"> OTP Verification</h2>
          <h2 className="text-[16px] font-semibold py-2">
            {" "}
            Hey, {user?.content?.first_name} {user?.content?.last_name}!
          </h2>
          <p className="font-light text-[#696969] text-base text-center px-[10rem] py-2 ">
            An OTP has been sent to your Email
          </p>
          <div className="otpfields mt-8 flex gap-16">
            {/* <div className="phoneotp ">
              <h2 className="text-[16px] font-medium py-2">Phone OTP</h2>

              <input
                type="text"
                name="phone_otp"
                value={phoneOtp[0]}
                onChange={(e) => handleOTPChange(e.target.value, 0, "phone")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 w-14 p-4 rounded-lg border-2 mr-5 "
              />
              <input
                type="text"
                name="phone_otp"
                value={phoneOtp[1]}
                onChange={(e) => handleOTPChange(e.target.value, 1, "phone")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2 mr-5"
              />
              <input
                type="text"
                name="phone_otp"
                value={phoneOtp[2]}
                onChange={(e) => handleOTPChange(e.target.value, 2, "phone")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2 mr-5"
              />
              <input
                type="text"
                name="phone_otp"
                value={phoneOtp[3]}
                onChange={(e) => handleOTPChange(e.target.value, 3, "phone")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2"
              />
            </div> */}
            <div className="emailotp ">
              <h2 className="text-[16px] font-medium py-2"> OTP</h2>

              <input
                ref={inputRef1}
                type="text"
                name="otp"
                value={emailOtp[0]}
                onPaste={(e) => handlePaste(e, "otp")}
                onChange={(e) => handleOTPChange(e.target.value, 0, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 w-14 p-4 rounded-lg border-2 mr-5"
              />
              <input
                ref={inputRef2}
                type="text"
                name="otp"
                value={emailOtp[1]}
                onPaste={(e) => handlePaste(e, "otp")}
                onChange={(e) => handleOTPChange(e.target.value, 1, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2 mr-5"
              />
              <input
                ref={inputRef3}
                type="text"
                name="otp"
                onPaste={(e) => handlePaste(e, "otp")}
                value={emailOtp[2]}
                onChange={(e) => handleOTPChange(e.target.value, 2, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2 mr-5"
              />
              <input
                ref={inputRef4}
                type="text"
                name="otp"
                onPaste={(e) => handlePaste(e, "otp")}
                value={emailOtp[3]}
                onChange={(e) => handleOTPChange(e.target.value, 3, "email")}
                rows={4} // Set the maximum number of rows
                maxLength={1} // Set the maximum length of OTP
                className="h-14 p-4 w-14 rounded-lg border-2"
              />
            </div>
          </div>

          <div
            onClick={resendOTP}
            className="font-light text-[#696969] text-base text-center px-16 py-8 "
          >
            OTP not Received?{" "}
            <button className="text-[#E9AA06] font-semibold]"> Resend</button>
          </div>
          <h2>{backendMessage}</h2>
          <div className=" gap-8 flex items-center  justify-center w-120">
            {/* <button
              onClick={handleSubmit}
              className="bg-black mt-6 text-white px-12 py-2 border-2 border-black rounded text-lg hover:bg-white hover:text-black"
            >
              Send OTP
            </button> */}
            <button
              onClick={handleSubmit}
              className="bg-black mt-6 text-white px-12 py-2 border-2 border-black rounded text-lg hover:bg-white hover:text-black"
            >
              Verify Email
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
