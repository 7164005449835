import React, { useState, useEffect } from "react";
import LeftSidebar from "../OnboardingDashboard/LeftSidebar";
import DashboardCards from "../OnboardingDashboard/DashboardCards";
import AdminNavbar from "../adminNavbar";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MobSideBar from "../mobsidebar";
import CandidateFeedback from "./candidateFeedback";

const HrCandidateFeedback = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const approval =  queryParams.get("approval") || "PENDING";
  const interviewStatus = queryParams.get("interviewStatus") || "HOLD_ON";
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [approval,interviewStatus]);

  return (
    <>
      <div className="mainsection">
        <div className="section grid grid-cols-[260px_1fr] md:grid-cols-[220px_1fr] sm:grid-cols-1">
          <div className="YourComponentStyles">
            {isMobile ? (
              <MobSideBar currentPage="interviews" />
            ) : (
              <div className="leftsection h-screen md:block">
                <LeftSidebar currentPage="interviews" />
              </div>
            )}
          </div>

          <div className="rightsection bg-[#f5f5f8] ">
            <div className="topsection sm:mt-4 bg-white flex justify-end">
              <div className="sm:hidden">
                <AdminNavbar showlogo={false} />
              </div>
            </div>
            <div className="px-6 mt-6">
              <DashboardCards />
              <div className="tablesection">
                <CandidateFeedback interviewapproval={approval} interviewStatus={interviewStatus}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrCandidateFeedback;