import React, { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Password from "./../../../assets/images/Icons/Password 2.svg";
import logo from "./../../../assets/images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import resetPassword from "../../../assets/images/resetPassword.png";
import Swal from "sweetalert2";
import axios from "../authenticateApi/authenticateApi";
import { FORGET_PASSWORD } from "../../../utils/baseUrl";


const validationSchema = Yup.object({
  password: Yup.string().required("Please enter your Password"),
  confirm_password: Yup.string().required("Please confirm your Password")
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const ResetPassword = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const { id } = useParams();
  // const backendMessage = useSelector((state) => state?.loginSlice?.user?.message);
  // const role = useSelector((state) => state?.loginSlice?.user?.content?.customer?.role);
  // const message = useSelector((state) => state?.loginSlice?.user);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  // useEffect(() => {
  //   if (!message?.success) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: backendMessage,
  //       confirmButtonColor: '#EEAF06',
  //     });
  //   } else {
  //     if (role === "EMPLOYEE") {
  //       navigate("/top-candidate");
  //     } else if (role === "CANDIDATE") {
  //       navigate("/candidate-dashboard");
  //     }
  //   }
  // }, [role, message]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.put(FORGET_PASSWORD(id), values);
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate('/');
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      } catch (error) {
      return error;
      }
    },
  });

  return (
    <div className="login-page-container grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1 h-screen overflow-hidden ">
      <div className="left">
        <img src={logo} alt="" className="pl-8 pt-4" />
        <div className="2xl:py-[7.5rem] xl:py-[5rem] lg:py-[6rem] py-[3rem] 2xl:px-36 xl:px-24 lg:px-10 md:px-10 sm:px-12 px-6  md:pr-[1rem] md:w-[80%]  bg-white sm:py-[6.5rem] sm:w-[100%]">
          <h1 className="font-semibold text-[30px] xl:text-[24px] lg:text-[24px] md:text-[18px] sm:text-[15px] mb-[64px] xl:mb-[40px] ">
            Reset your Password
          </h1>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <div className="mb-[38px]  relative">
                <label
                  className="text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="email"
                >
                  <img src={Password} alt="email" className="mr-2" /> New Password
                </label>
                <input
                 type={passwordShown ? "text" : "password"}
                  id="password"
                  placeholder="New Password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                 <button
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center text-sm leading-5"
                  type="button"
                >
                  {passwordShown ? (
                    <IconEyeOff size={24} />
                  ) : (
                    <IconEye  size={24} />
                  )}
                </button>
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.password}
                  </p>
                ) : null}
              </div>
              <div className="mb-20 xl:mb-10 lg:mb-8 md:mb-5 sm:mb-5 relative">
                <label
                  className="text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="confirm_password"
                >
                  <img src={Password} alt="password" className="mr-2" /> Confirm Password
                </label>
                <input
                  type={passwordShown ? "text" : "password"}
                  id="confirm_password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  onChange={formik.handleChange}
                  value={formik.values.confirm_password}
                  className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <button
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center text-sm leading-5"
                  type="button"
                >
                  {passwordShown ? (
                    <IconEyeOff size={24} />
                  ) : (
                    <IconEye size={24} />
                  )}
                </button>
                {formik.touched.confirm_password && formik.errors.confirm_password ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.confirm_password}
                  </p>
                ) : null}
              </div>
              <button
                type="submit"
                className="group transition-all bg-gradient-to-l from-[#E9AA06] to-[#FFC107] w-full text-black font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Reset Password
              </button>
            </Form>
          </FormikProvider>
        </div>
      </div>
      <div className="right sm:hidden ">
        <img src={resetPassword} alt="" className="w-full h-[1000px] 2xl:block xl:block lg:block md:block sm:hidden hidden" />
      </div>
    </div>
  );
};

export default ResetPassword;
