import React, { useEffect, useState } from "react";
import smallimg1 from "../../../assets/images/Icons/smallimg1.svg";
import smallimg2 from "../../../assets/images/Icons/smallimg2.svg";
import smallimg3 from "../../../assets/images/Icons/smallimg3.svg";
import smallimg4 from "../../../assets/images/Icons/smallimg4.svg";
import { ADMIN_DASHBOARD_CARDS, ADMIN_PROFILE } from "../../../utils/baseUrl";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../../store/candidateInfoSlice/candidateInfoSlice";

const DashboardCards = () => {
  const admin = useSelector((state) => state?.AdminLogInSlice?.user?.content);
 

  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  const [user, setUser] = useState([]);
  const item = [smallimg1, smallimg2, smallimg3, smallimg4];
  useEffect(() => {
    axios.get(ADMIN_DASHBOARD_CARDS).then((response) => {
      setUser(response?.data?.content);
    });
  }, []);

  return (
    <>
    {admin?.customer?.role==="HR" ?(
    <h2 className="text-[#495057] font-semibold text-[16px] pb-3 ">
     HR Dashboard
    </h2>
    ):admin?.customer?.role==="MANAGER" ?(
      <h2 className="text-[#495057] font-semibold text-[16px] pb-3 ">
       Success Manager Dashboard
      </h2>
      ):(
        <h2 className="text-[#495057] font-semibold text-[16px] pb-3 ">
         Super Admin Dashboard
        </h2>
        )}
      <div className="mainsection grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 gap-5 mr-5">
        
        <div className="card shadow-md rounded-lg 2xl:p-8 xl:p-6 lg:p-2 md:p-4 sm:p-2 bg-white ">
          <div className="innersection flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col items-center justify-between">
            <div className="leftsec">
              <h2 className="text-[#74788d] text-base font-normal">
                Selected Candidates
              </h2>
              <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col items-center pt-3 gap-3">
                <div className="images flex">
                  {item?.map((img, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={img}
                      alt=""
                      className={imgIndex > 0 ? "ml-[-18px]" : ""}
                    />
                  ))}
                </div>
                {/* <h2 className="text-black text-[12px] font-normal">
                  {user?.clear}
                </h2> */}
              </div>
            </div>
            <div className="rightsection">
              <h2 className="candidates text-black text-[24px] font-normal">
                {user?.clear}
              </h2>
            </div>
          </div>
        </div>

        <div className="card shadow-md rounded-lg 2xl:p-8 xl:p-6 lg:p-2 md:p-4 sm:p-2 bg-white ">
          <div className="innersection flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col items-center justify-between">
            <div className="leftsec">
              <h2 className="text-[#74788d] text-base font-normal">
                On Hold Candidates
              </h2>
              <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col items-center pt-3 gap-3">
                <div className="images flex">
                  {item?.map((img, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={img}
                      alt=""
                      className={imgIndex > 0 ? "ml-[-18px]" : ""}
                    />
                  ))}
                </div>
                {/* <h2 className="text-black text-[12px] font-normal">
                  {user?.holdON}
                </h2> */}
              </div>
            </div>
            <div className="rightsection">
              <h2 className="candidates text-black text-[24px] font-normal">
                {user?.holdON}
              </h2>
            </div>
          </div>
        </div>
        <div className="card shadow-md rounded-lg 2xl:p-8 xl:p-6 lg:p-2 md:p-4 sm:p-2 bg-white ">
          <div className="innersection flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col items-center justify-between">
            <div className="leftsec">
              <h2 className="text-[#74788d] text-base font-normal">
                Rejected Candidates
              </h2>
              <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col items-center pt-3 gap-3">
                <div className="images flex">
                  {item?.map((img, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={img}
                      alt=""
                      className={imgIndex > 0 ? "ml-[-18px]" : ""}
                    />
                  ))}
                </div>
                {/* <h2 className="text-black text-[12px] font-normal">
                  {user?.noClear}
                </h2> */}
              </div>
            </div>
            <div className="rightsection">
              <h2 className="candidates text-black text-[24px] font-normal">
                {user?.noClear}
              </h2>
            </div>
          </div>
        </div>

        <div className="card shadow-md rounded-lg 2xl:p-8 xl:p-6 lg:p-2 md:p-4 sm:p-2 bg-white">
          <div className="innersection flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col items-center justify-between">
            <div className="leftsec">
              <h2 className="text-[#74788d] text-base font-normal">
                Total Candidates
              </h2>
              <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col items-center pt-3 gap-3">
                <div className="images flex">
                  {item?.map((img, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={img}
                      alt=""
                      className={imgIndex > 0 ? "ml-[-18px]" : ""}
                    />
                  ))}
                </div>
                {/* <h2 className="text-black text-[12px] font-normal">
                  {" "}
                  {user?.total}
                </h2> */}
              </div>
            </div>
            <div className="rightsection">
              <h2 className="candidates text-black text-[24px] font-normal">
                {user?.total}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCards;

// bg-gradient-to-r from-yellow-500 via-[#E9AA06] via-30% to-yellow-300
