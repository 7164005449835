import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import phoneIcon from "./../../../../assets/images/Icons/phoneIcon.svg";
import Vector from "../../../../assets/images/Icons/email.svg";
import Password from "../../../../assets/images/Icons/Password 2.svg";
import logo from "../../../../assets/images/logo.png";
import nameicon from "../../../../assets/images/nameicon.png";
import Company from "../../../../assets/images/Company.png";
import country from "../../../../assets/images/country.png";
import employerIcon from "../../../../assets/images/employeruser.png";
import linkedinIcon from "../../../../assets/images/linkedin.png";
import linkIcon from "../../../../assets/images/link.png";
import registration from "../../../../assets/images/registration.png";
import { Link, useNavigate } from "react-router-dom";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { REGISTER } from "../../../../utils/baseUrl";
import axios from "../../authenticateApi/authenticateApi";
import bgimg from "../../../../assets/images/bgnew2.png";
import { NumericFormat } from "react-number-format";
import { LogInUserHandler } from "../../../../store/loginSlice/loginSlice";
import { useDispatch } from "react-redux";
import Asterik from "../../../../common/asterik";

const validationSchema = Yup.object({
  first_name: Yup.string().required("Please enter your First Name"),
  last_name: Yup.string().required("Please enter your Last Name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your e-mail address"),
  cell_phone: Yup.string()
    .min(7, "Phone number must be at least 9 digits")
    .max(13, "Phone number must be at most 13 digits")
    .required("Phone number is required"),
  password: Yup.string().required("please enter your Password"),
  company_name: Yup.string().required("please enter your company name"),
  country: Yup.string().required("please choose your country name"),
  registration_number: Yup.string().required(
    "please enter your Registration number"
  ),
  title: Yup.string().required("please enter your title"),
  gender: Yup.string().required("please choose your gender"),
  address: Yup.string().required("please enter your address"),
  website: Yup.string().required("please enter your website link"),
  linkedin: Yup.string().required("please enter your linkedin profile link"),
});

const EmployerSignUp = () => {
  const dispatch = useDispatch();
  const [backendMessage, setBackendMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const isValidLinkedInURL = (url) => {
    // Regular expression to validate LinkedIn profile URL format
    const pattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/[\w-]+\/?$/;
    return pattern.test(url);
  };
  const isValidWebsiteURL = (url) => {
    // Regular expression to validate website URL format
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(url);
  };
  const formik = useFormik({
    
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      cell_phone: "",
      password: "",
      company_name: "",
      country: "",
      title: "",
      gender: "",
      address: "",
      website: "",
      linkedin: "",
      registration_number: "",
      role: "EMPLOYEE",
    },
    validationSchema,
    validate: (values) => {
      const errors = {};
      if (!isValidLinkedInURL(values.linkedin)) {
        errors.linkedin = 'Please enter a valid LinkedIn profile URL';
      }
      if (!isValidWebsiteURL(values.website)) {
        errors.website = 'Please enter a valid website URL';
      }
      return errors;
    },
    onSubmit: async (values, formik) => {
      const countryCode = getCountryCode(values.country);
      const number = countryCode.substring(1);
      const modifiedCellPhone = `${number}${values.cell_phone}`;
     
    

      const updatedValues = {
        ...values,
        cell_phone: modifiedCellPhone,
        // profile: myprofile.profile,
      };
      await axios
        .post(REGISTER, updatedValues, {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            // const loginCredentials = {
            //   email: values.email,
            //   password: values.password,
            // };
            // dispatch(LogInUserHandler(loginCredentials));
            navigate(
              `/otp?phoneNumber=${values.cell_phone}&firstname=${response?.data?.content?.customer?.first_name}&last_name=${response?.data?.content?.customer?.last_name}`
            );
          } else {
            setBackendMessage(response?.data?.message);
          }
        })
        .catch((error) => {
          setBackendMessage(error?.response?.data?.message);
       return error;
        });
    },
  });

  const getCountryCode = (country) => {
    switch (country) {
      case "singapore":
        return "+65";
      case "malaysia":
        return "+60";
      default:
        return undefined;
    }
  };
  return (
    <>
      <div className="login-page-container ">
      {/* <div className="left "> */}
      <img src={logo} alt="" className="pl-8 pt-4" />
      <h1 className="font-semibold text-[30px] bg-[#F1F5F9] pl-24 pt-4 mt-4">
        Create an Account
      </h1>
      <div className="bg-[#F1F5F9]  xl:py-[6rem] lg:py-[6rem] py-[1.5rem] 2xl:px-24 xl:px-24 lg:px-14 md:px-8 sm:px-6 md:pr-[5rem]   sm:py-[6.5rem] sm:w-[100%] sm:bg-inherit">
        {/* <form onSubmit={formik.handleSubmit} className="grid w-full justify-between 2xl:grid-cols-[1fr_1fr] 2xl:justify-between xl:grid-cols-[1fr_1fr] xl:justify-between lg:grid-cols-[1fr_1fr] lg:justify-between md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1  overflow-y-auto "> */}
        <form
          onSubmit={formik.handleSubmit}
          // className="grid w-full gap-8 2xl:grid-cols-[1fr_1fr]  xl:grid-cols-[1fr_1fr]  lg:grid-cols-[1fr_1fr]  md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1  overflow-y-auto "
        >
          <div className="bg-white border rounded-md p-8 grid w-full gap-8 2xl:grid-cols-[1fr_1fr]  xl:grid-cols-[1fr_1fr]  lg:grid-cols-[1fr_1fr]  md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1  overflow-y-auto">
          <div className="">
            <label
              className=" text-gray-700 text-[14px] font-semibold mb-2 flex items-center"
              htmlFor="first_name"
            >
              <img src={employerIcon} alt="email" className="mr-2 h-4 w-4" /> First Name{" "}
              <Asterik />
            </label>
            <input
              type="text"
              id="first_name"
              placeholder="please enter your first name"
              name="first_name"
              onChange={formik.handleChange}
              value={formik.values.first_name}
              className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.first_name && formik.errors.first_name ? (
              <p className="text-xs mt-1 text-red-500">
                {formik.errors.first_name}
              </p>
            ) : null}
          </div>
          <div className="">
            <label
              className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
              htmlFor="last_name"
            >
              <img src={employerIcon} alt="name" className="mr-2 h-4 w-4" /> Last Name{" "}
              <Asterik />
            </label>
            <input
              type="text"
              id="last_name"
              placeholder="please enter your last name"
              name="last_name"
              onChange={formik.handleChange}
              value={formik.values.last_name}
              className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.last_name && formik.errors.last_name ? (
              <p className="text-xs mt-1 text-red-500">
                {formik.errors.last_name}
              </p>
            ) : null}
          </div>
          <div className="">
            <label
              className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
              htmlFor="email"
            >
              <img src={Vector} alt="email" className="mr-2 " /> Email Address{" "}
              <Asterik />
            </label>
            <input
              type="email"
              id="email"
              placeholder="abc@gmail.com"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="text-xs mt-1 text-red-500">{formik.errors.email}</p>
            ) : null}
          </div>
          <div className="">
            <div className="w-full ">
              <label
                className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
                htmlFor="country"
              >
                <img src={country} alt="email" className="mr-2 h-[19px] "  /> Country{" "}
                <Asterik />
              </label>
              <div className="relative">
                <select
                  id="select"
                  name="country"
                  value={formik.values.country} // Set the value to formik's state
                  onChange={formik.handleChange} // Handle change to update the state
                  className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="" disabled>
                    Country
                  </option>
                  <option value="singapore">Singapore</option>
                  {/* <option value="malaysia">Malaysia</option> */}
                </select>
              </div>
            </div>

            {formik.touched.country && formik.errors.country ? (
              <p className="text-xs mt-1 text-red-500">
                {formik.errors.country}
              </p>
            ) : null}
          </div>

          <div className="">
            <div className="w-full ">
              <label
                className="text-gray-700 text-sm font-semibold mb-2 flex items-center"
                htmlFor="country"
              >
                <img src={phoneIcon} alt="phone" className="mr-2" /> Phone{" "}
                <Asterik />
              </label>
              <div className="flex items-center ">
                <span className=" appearance-none border rounded h-[44px] w-[50px] py-4 px-2  text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  {getCountryCode(formik.values.country)}
                </span>
                <NumericFormat
                  type="text"
                  id="cell_phone"
                  placeholder="********"
                  name="cell_phone"
                  maxLength={9}
                  onChange={formik.handleChange}
                  value={formik.values.cell_phone}
                  disabled={!formik.values.country}
                  className="appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              {formik.touched.cell_phone && formik.errors.cell_phone ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.cell_phone}
                </p>
              ) : null}
            </div>
          </div>
          <div className=" relative">
            <label
              className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
              htmlFor="password"
            >
              <img src={Password} alt="password" className="mr-2" /> Password{" "}
              <Asterik />
            </label>
            <input
              type={passwordShown ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
              className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <button
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center text-sm leading-5"
              type="button"
            >
              {passwordShown ? <IconEye size={24} /> : <IconEyeOff size={24} />}
            </button>
            {formik.touched.password && formik.errors.password ? (
              <p className="text-xs mt-1 text-red-500">
                {formik.errors.password}
              </p>
            ) : null}
          </div>
          <div className="">
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
                htmlFor="company"
              >
                <img src={Company} alt="company" className="mr-2" /> Company{" "}
                <Asterik />
              </label>
              <input
                type="text"
                id="company"
                name="company_name"
                placeholder="Company Name"
                onChange={formik.handleChange}
                value={formik.values.company_name}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.company_name && formik.errors.company_name ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.company_name}
                </p>
              ) : null}
            </div>
          </div>
          <div className="">
            <label
              className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
              htmlFor="registration_number"
            >
              <img src={registration} alt="registrationIcon" className="mr-2" />
              Company Registration No <Asterik />
            </label>
            <input
              type="text"
              id="registration_number"
              placeholder="201688888A"
              name="registration_number"
              onChange={formik.handleChange}
              value={formik.values.registration_number}
              className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.registration_number &&
            formik.errors.registration_number ? (
              <p className="text-xs mt-1 text-red-500">
                {formik.errors.registration_number}
              </p>
            ) : null}
          </div>

          {/* //////////// */}
          <div className="">
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
                htmlFor="title"
              >
                <img src={Company} alt="company" className="mr-2" /> Position{" "}
                <Asterik />
              </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Your position in the company"
                onChange={formik.handleChange}
                value={formik.values.title}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.title && formik.errors.title ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.title}
                </p>
              ) : null}
            </div>
          </div>
          <div className="">
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
                htmlFor="gender"
              >
                <img src={employerIcon} alt="company" className="mr-2 h-4 w-4" /> Gender{" "}
                <Asterik />
              </label>
              {/* <input
                type="text"
                id="gender"
                name="gender"
                placeholder="Choose Your gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              /> */}
                 <select
                id="selectGender"
                name="gender"
                value={formik.values.gender} // Set the value to formik's state
                onChange={formik.handleChange} // Handle change to update the state
                className=" appearance-none border rounded text-[14px] w-full my-2 py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>
                  Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                {/* <option value="other">Other</option> */}
              </select>
              {formik.touched.gender && formik.errors.gender ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.gender}
                </p>
              ) : null}
            </div>
          </div>
          <div className="">
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
                htmlFor="address"
              >
                <img src={country} alt="company" className="mr-2 h-5 w-4" /> Address{" "}
                <Asterik />
              </label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Company Name"
                onChange={formik.handleChange}
                value={formik.values.address}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.address && formik.errors.address ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.address}
                </p>
              ) : null}
            </div>
          </div>
          <div className="">
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
                htmlFor="website"
              >
                <img src={linkIcon} alt="company" className="mr-2 w-4 h-4" /> Website Link{" "}
                <Asterik />
              </label>
              <input
                type="text"
                id="website"
                name="website"
                placeholder="Add your website Link"
                onChange={formik.handleChange}
                value={formik.values.website}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.website && formik.errors.website ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.website}
                </p>
              ) : null}
            </div>
          </div>
          <div className=" mb-[30px]">
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-semibold mb-2 flex items-center"
                htmlFor="linkedin"
              >
                <img src={linkedinIcon} alt="company" className="mr-2 h-4 w-4" /> Linkedin Profile{" "}
                <Asterik />
              </label>
              <input
                type="text"
                id="linkedin"
                name="linkedin"
                placeholder="Add your Linkedin profile Link"
                onChange={formik.handleChange}
                value={formik.values.linkedin}
                className=" appearance-none border rounded text-[14px] w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.linkedin && formik.errors.linkedin ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.linkedin}
                </p>
              ) : null}
            </div>
          </div>
          <h2 className="text-red-600">{backendMessage}</h2>
         
          </div>
          <button
            type="submit"
            className="flex justify-center items-center mx-auto mt-4  bg-[#FFC107] hover:text-[#FFC107] border  hover:bg-white text-white font-medium py-2 px-12 rounded focus:outline-none focus:shadow-outline"
          >
            Sign Up
          </button>
        </form>
        <p className="text-[#9CA3AF] text-sm font-medium leading-[19px] mt-4 text-center">
          By creating an account, you agree to the{" "}
         <Link to={'https://bmgtalentplus.com.sg/terms-conditions/'}> <span className="text-[#E9AA06]">Terms of Service.</span> </Link>For more
          information about BMG's privacy practices, see the BMG{" "}
          <Link to={'https://bmgtalentplus.com.sg/privacy-policy/'}><span className="text-[#E9AA06]">Privacy Statement.</span></Link> We'll
          occasionally send you account-related emails.
        </p>
        <p className="mt-4 mr-8 text-center text-[#6B7280] ">
          Already have an account ?{" "}
          <Link to={"/  "} className="text-[#E9AA06] font-semibold ">
            Log in
          </Link>
        </p>
      </div>
      {/* </div> */}
      {/* <div className="right sm:hidden">
          <img
            src={bgimg}
            alt=""
            className="h-[1300px] w-full 2xl:block xl:block lg:block md:block sm:hidden hidden"
          />
        </div> */}
      </div>
    </>
  );
};

export default EmployerSignUp;
