import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { CANDIDATE_DASHBOARD } from "../../utils/baseUrl";


const initialState = {
  loading: false,
  user: null,
  error: "",
};

// if candidate Login then hit this api and render candidate Dashboard
export const fetchUser = createAsyncThunk(
  "userSlice/fetchUser",
  async (data) => {
    const response = await axios.get(CANDIDATE_DASHBOARD);
   
    return response?.data;
  }
);

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.user = [];
      state.error = action.error.message;
    });
  },
});

export default userSlice.reducer;
