import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { INTERVIEW_REQUEST } from "../../utils/baseUrl";



const initialState = {
  loading: false,
  interview: null,
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchInterviewRequest = createAsyncThunk(
  "interviewRequestSlice/fetchInterviewRequest",
  async (data) => {
    const response = await axios.get(INTERVIEW_REQUEST(data) );
    return response?.data;
  }
);

const interviewRequestSlice = createSlice({
  name: "interviewRequestSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchInterviewRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInterviewRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    });
    builder.addCase(fetchInterviewRequest.rejected, (state, action) => {
      state.loading = false;
      state.user = [];
      state.error = action.error.message;
    });
  },
});

export default interviewRequestSlice.reducer;
