import React, { useEffect } from "react";
import { Fragment } from "react";
import smallprofilevector from "../../assets/images/smallprofilecevtor.png";
import { fetchUser } from "../../store/candidateInfoSlice/candidateInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { LogOutUserReducer } from "../../store/loginSlice/loginSlice";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BASE_URL } from "../../utils/baseUrl";
import { Menu, Transition } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DropDown = () => {
  const employerData = useSelector((state) => state?.userSlice?.user?.content);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  const logoutHandler = () => {
    dispatch(LogOutUserReducer());
    navigate("/");
  };
  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex items-center">
        <img src={`${BASE_URL}/profile/${employerData?.profile_picture}`} alt="profilePic" className="h-[60px] w-[60px] rounded-full" />
          <div className="flex flex-col">
            <Menu.Button className="inline-flex items-center w-full justify-center gap-x-1.5  bg-inherit px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
              {employerData?.first_name} {employerData?.last_name}
              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/employer-dashboard"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    My Profile
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/email-verification"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Verify Email Address
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/candidate-searchbar"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Search Candidate
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/top-candidate"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Top Candidates
                  </a>
                )}
              </Menu.Item>
              <form method="POST" action="#">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="submit"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm"
                      )}
                      onClick={logoutHandler}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </form>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default DropDown;
