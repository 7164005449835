
import { createSlice } from '@reduxjs/toolkit';

const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {
    currentStep: 1,
  },
  reducers: {
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
  },
});

export const { setCurrentStep } = paginationSlice.actions;

export default paginationSlice.reducer;