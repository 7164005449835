import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CANDIDATE_SCHEDULAR_TIME,
  SLOT_MANAGEMENT,
} from "../../../utils/baseUrl";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import { useFormik } from "formik";
import { Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SlotManagement = () => {
  const [enabled, setEnabled] = useState(true);
  const initialValues = {
    meeting_date: "",
    zoom_meeting_time: "",
  };
  const navigate = useNavigate();
  const [previousData, setPreviousData] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const currentDate = new Date(); // Get the current date
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1); // Set the next date
  const selectableDate = new Date(currentDate);
  selectableDate.setDate(currentDate.getDate() + 2);

  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);

  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const year = startDate.getFullYear();
  const month = ("0" + (startDate.getMonth() + 1)).slice(-2);
  const day = ("0" + startDate.getDate()).slice(-2);

  const formattedStartDate = `${year}-${month}-${day}`;
  const fetchTimeSlots = async () => {
    try {
      const response = await axios.get(
        CANDIDATE_SCHEDULAR_TIME(formattedStartDate)
      );
      setTimeSlots(response?.data?.content);
      setIsLoading(false);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    fetchTimeSlots();
  }, [startDate, enabled]);
  const handleTimeSlotClick = (time, index) => {
    if (time.available_time) {
      // Toggle selection
      if (selectedTimeSlots.includes(time?.start_time)) {
        setSelectedTimeSlots(
          selectedTimeSlots.filter((slot) => slot !== time?.start_time)
        );
      } else {
        setSelectedTimeSlots([...selectedTimeSlots, time?.start_time]);
      }

      // You can add additional logic here if needed
      formik.setFieldValue("zoom_meeting_time", time.start_time);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const year = startDate.getFullYear();

      const month = ("0" + (startDate.getMonth() + 1)).slice(-2);
      const day = ("0" + startDate.getDate()).slice(-2);

      const formattedStartDate = `${year}-${month}-${day}`;
      let payload;
      if (selectedDate === null) {
        Swal.fire({
          icon: "error",
          title: "Meeting Date is Required",
          // text: "Please Select Both.",
          confirmButtonColor: "#EEAF06",
        });
        return;
      }
      if (enabled) {
        payload = {
          halfday: selectedTimeSlots.map((time) => ({
            StartTime: time,
            date: formattedStartDate.toString(),
          })),
        };
      } else {
        payload = {
          holydays: formattedStartDate.toString(),
        };
      }

      try {
        const response = await axios.put(SLOT_MANAGEMENT, payload);

        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/slot-management");
          fetchTimeSlots();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      } catch (error) {
        // setBackendMessage(error?.response?.data?.message);
        return error;
      }
    },
  });

  const combinedFilter = (date) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return false;
    }
    const holidays = timeSlots.filter((item) => typeof item === "string");

    const holidayDates = holidays.map((holiday) => new Date(holiday));
    if (
      holidayDates.find(
        (holidayDate) => holidayDate.toDateString() === date.toDateString()
      )
    ) {
      return false;
    }

    // Limit selection to the next 30 days
    const currentDate = new Date();
    const next30Days = new Date();
    next30Days.setDate(currentDate.getDate() + 30);
    return date <= next30Days;
  };

  return (
    <>
      <div className="  shadow-md  bg-white rounded-xl mb-[4rem] mr-6 py-10 mt-16 ">
        <div className="p-6 rounded-xl mt-2 grid 2xl:grid-cols-[1fr_1.75fr] xl:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_2fr] gap-16">
          <div className="custom-calendar border-r-2 pr-7">
            <div className="py-4">
              <p className="text-lg capitalize sm:text-sm font-normal py-4">
                Select a slot that you want to off
              </p>
            </div>
            <DatePicker
              selected={selectedDate}
              filterDate={combinedFilter}
              minDate={selectableDate}
              inline
              // selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                formik.setFieldValue(
                  "zoom_meeting_date",
                  date.toISOString().split("T")[0],
                  setSelectedDate(date)
                );
              }}
            />
          </div>
          {selectedDate ? (
            <div className="rightsection flex flex-col pt-10 ">
              <div className="flex justify-between">
                <h2 className="text-lg text-[#696969]">
                  Available Time Slots for Interview
                </h2>
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? "bg-[#E9AA06]" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#E9AA06] focus:ring-offset-2"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>

              <div className="slots mt-6">
                {enabled ? (
                  <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-1 gap-4 ">
                    {isLoading ? (
                      <div className="py-8  px-8 text-center rounded-lg">
                        <Skeleton count={6} width={300} className="mb-4" />
                      </div>
                    ) : (
                      timeSlots
                        ?.filter((time) => time && time.start_time)
                        ?.map((time, index) => (
                          <div
                            key={index}
                            onClick={() => handleTimeSlotClick(time, index)}
                            className={`border-2 sm:max-w-[90%] py-3 px-8 sm:px-4 text-center rounded-lg cursor-pointer ${
                              !time.available_time
                                ? "opacity-50 cursor-not-allowed"
                                : selectedTimeSlots.includes(time?.start_time)
                                ? "bg-[#E9AA06] text-white"
                                : ""
                            }`}
                            disabled={!time.available_time}
                          >
                            {time?.start_time} - {time?.end_time}
                          </div>
                        ))
                    )}
                  </div>
                ) : (
                  <div className="text-center mt-4">
                    <p className="text-red-500 text-[22px] capitalize">
                      You are off for full day.
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center mt-4">
              <p className="text-[#E9AA06] text-[22px] capitalize">
                Please select a specific date.
              </p>
            </div>
          )}
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="md:container lg:container xl:container 2xl:container pb-[2rem]  w-4/5 mx-auto flex  justify-center">
          <button
            type="submit"
            className="border-[1px] border-[#E9AA06] bg-[#E9AA06] text-white hover:bg-white hover:text-[#E9AA06] ml-[5px] px-20  sm:px-8  py-2 rounded text-lg flex items-center"
          >
            Submit{" "}
          </button>
        </div>
      </form>
    </>
  );
};
export default SlotManagement;
