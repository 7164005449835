export const BASE_URL = `https://api.bmgtalentplus.com.sg`;
// export const BASE_URL=`http://13.48.182.158:8080/`

// export const BASE_URL=`https://backend.mykilo.co.uk/`

export const ADMIN_LOGIN = `/admin/login`;

export const RESET_PASSWORD = `auth/resetpassword`;

export const LOGIN = `/auth/login`;

export const REGISTER = `/auth/register`;

export const SEND_OTP = `/auth/sendOtpToEmail`;

export const EMAIL_VERIFY = `/auth/verifyOtp`;

export const CANDIDATE_DASHBOARD = `/profile`;

export const ADMIN_PROFILE = `/admin/profile`;

export const OTP_VERIFICATION = `/auth/otp_verification`;

export const PHONE_OTP_VERIFICATION = `/auth/verifyOtpByPhone`;

export const RESEND_OTP = `/auth/resend_otp`;

export const SHORTLIST_CANDIDATE_LIST = `/profile/selected/candidates/selected`;

export const CONSIDERABLE_CANDIDATE_LIST = `/profile/selected/candidates/interested`;

export const DELETE_CANDIDATE_FROM_LIST = (id)=>`admin/dashboard/delCandidate/${id}`;

export const ADMIN_DASHBOARD_CARDS = `/admin/dashboard`;

export const ADD_MORE_ADMIN = `/admin/add_admin`;

export const ALL_EMPLOYERS_INTERVIEW_REQUEST = `/admin/allClearEmployees`;

export const CANDIDATE_UPDATE = (id) => `/profile/update/${id}`;

export const CANDIDATE_PICTURE = (id) => `/profile/profile_picture/${id}`;

export const CANDIDATE_PROFILE = (id) => `public/candidate/${id}`;

export const CANDIDATE_SEARCH = (key, approval, role,badge) =>{

let apiUrl=  `/public/search/candidate/${key}?CandidatesStatus=${approval}&role=${role}`;
if (badge) {
  apiUrl += `&subscription_tier=${badge}`;
}

return apiUrl;
}
export const CANDIDATE_SEARCH_BY_ADMIN = (key, approval, role,badge) =>{
  let apiUrl=`/public/search/candidate/${key}?interviewStatus=${approval}&role=${role}`;
  if (badge) {
    apiUrl += `&subscription_tier=${badge}`;
  }
  return apiUrl;
}
export const EXPORT_DATA=(status,badge)=>`/admin/dashboard/exportCandidate?CandidatesStatus=CLEAR&role=CANDIDATE&subscription_tier=${badge}`

export const EMPLOYER_CANDIDATE_SEARCH = (key, approval, role) =>
  `/public/search/candidate/${key}?interviewStatus=CLEAR&role=CANDIDATE`;

export const INTERVIEW_STATUS_ADMIN = (data) =>
  `/admin/dashboard/candidate/interview?page=${data.page}&status=${data?.status}`;

export const ALL_USERS_FOR_ADMIN = (data) =>
  `/admin/dashboard/all_user?page=${data.page}&role=${data.role}&approval=${data.approval}`;

export const INTERVIEW_REQUEST = (data) =>
  `/admin/dashboard/candidate/${data.approval}?page=${data.page}`;

export const TOP_CANDIDATE = (data) => `/public/candidate?page=${data.page}`;

export const SCHEDULAR = (date) => `/profile/employer/availability/${date}`;

export const EMPLOYER_SCHEDULE_MEETING = (id) =>
  `/public/bmg_request/candidate/${id}`;

export const CANDIDATE_SCHEDULAR_TIME = (date) =>
  `/auth/candidate/availability/${date}`;

export const EMPLOYER_SHORTLIST_CANDIDATE = (id) =>
  `/profile/likeCandidates/${id}`;

export const ALL_EMPLOYER_REQUEST = (page, interviewapproval) =>
  `/admin/dashboard/employee/request?page=${page}&accepted=${interviewapproval}`;

export const INTERVIEW_APPROVAL = (id) => `/admin/dashboard/candidate/${id}`;
export const FORGET_PASSWORD = (id) => `auth/forget_password/${id}`;

export const EMPLOYER_GIVE_RESULT = (userId) => `admin/interview/${userId}`;

export const HR_INTERVIEW_APPROVAL = (id) =>
  `/admin/dashboard/employee/request/${id}`;

export const EMPLOYER_SHORTLISTED_CANDIDATES = (id) =>
  `/admin/allClearCandidate/${id}`;

export const ALL_EMPLOYERS_CANDIDATES = (data) =>
  `/admin/dashboard/all_employees?page=${data.page}&role=${data.role}`;

export const FILTER_BY_STATUS = (selectedValue, role, currentStep,badge) =>{
  let apiUrl = `/admin/dashboard/FilterCandidate?CandidatesStatus=${selectedValue}&role=${role}&page=${currentStep}`;
  if (badge) {
    apiUrl += `&subscription_tier=${badge}`;
  }

  return apiUrl;
}


  export const FILTER_EMPLOYERS = (selectedValue, role, currentStep) =>
  `/admin/dashboard/getOnlyEmployees?page=${currentStep}&role=${role}&EmployeesStatus=${selectedValue}`;


export const UPLOAD_ZOOM_VIDEO = (id) => `/admin/dashboard/uploadVideos/${id}`;

export const SLOT_MANAGEMENT = "/admin/dashboard/holydays";

export const RESHEDULE_INTERVIEW = "/auth/interviewResechdule";
export const DELETE_VIDEO = (id) => `/admin/dashboard/delCandidateVideo/${id}`;

export const DELETE_TEAM_MEMBER = (id) => `/admin/deleteTeamMember/${id}`;

export const EMPLOYER_APPROVED_BY_ADMIN = (id, value) =>
  `/admin/employerVerified/${id}/${value}`;

export const SEARCH_PENDING_EMPLOYERS = (key, employeeStatus,role) =>
  `/public/search/employee/${key}?employeeStatus=${employeeStatus}&role=${role}`;

export const GET_PENDING_EMPLOYERS = (page, role, status) =>
  `/admin/dashboard/getOnlyEmployees?page=${page}&role=${role}&EmployeesStatus=${status}`;

export const CANDIDATE_ADMIN_PROFILE=(id)=>`/admin/dashboard/GetProfile/${id}`

export const UPDATE_BY_CONTENT_WRITER=(id)=>`/admin/dashboard/updateByContentWriter/${id}`