import React, { Fragment, useRef } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { BASE_URL } from '../../utils/baseUrl';
import crossicon from "../../assets/images/Icons/crossicon.svg";

const EmployerModal = ({ show, onClose, candidateData }) => {
    const cancelButtonRef = useRef(null);
  if (!show) return null;

  
  return (
    <Transition.Root show={show} as={Fragment}>
    <Dialog
      as="div"
      className=" z-10"
      initialFocus={cancelButtonRef}
      onClose={onClose}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className=" overflow-y-auto relative transform w-[35%] md:w-[50%] sm:w-[60%]  h-[550px] lg:h-[500px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8">
              <div className=" sm:items-start">
                <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-gray-900"
                  >
                    Employer
                  </Dialog.Title>
                  <img
                    src={crossicon}
                    alt=""
                    className="pr-10 cursor-pointer"
                    // onClick={() => setEmployerOpen(false)}
                    onClick={onClose}
                    ref={cancelButtonRef}
                  />
                </div>

                <div className="pt-8 lg:pt-5 grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-4">
                  <div key={candidateData?.item} className="">
                    <div className=" rounded-lg border-l-0 mb-4">
                      <div className="profile bg-white rounded-lg ">
                        <div className="profilecontent flex xl:flex-col lg:flex-col md:flex-col sm:flex-col items-center gap-16 xl:gap-6 py-4 pl-4">
                          {
                            <div className="profileimg ">
                              <img
                                src={`${BASE_URL}/profile/${candidateData?.profile_picture}`}
                                alt="profilePicture"
                                className="w-[150px] h-[150px] rounded-full object-cover mx-auto"
                              />
                            </div>
                          }

                          <div className="completeinfo ">
                            <div className="profileinfo flex items-start gap-4">
                              <h2 className="2xl:text-[20px] capitalize xl:text-[18px] lg:text-[16px] font-medium">
                                {candidateData?.first_name}{" "}
                                {candidateData?.last_name}
                              </h2>
                            </div>
                            <div className="roleposition mt-6 flex items-center gap-14 ">
                              <p className="text-[#696969] flex items-center gap-1 font-normal text-[14px]">
                                Position:{" "}
                                {candidateData?.title ? (
                                  <span className="text-black  text-[12px] font-medium">
                                    {candidateData?.title}
                                  </span>
                                ) : (
                                  <div>
                                    <p className="text-black pl-2">N/A</p>
                                  </div>
                                )}
                              </p>
                            </div>

                            <div className="roleposition mt-4 ">
                              <p className="text-[#696969] flex items-center gap-1 font-normal text-[14px]">
                                Gender:{" "}
                                {candidateData?.gender ? (
                                  <span className="text-black text-[12px] capitalize font-medium">
                                    {candidateData?.gender}
                                  </span>
                                ) : (
                                  <div>
                                    <p className="text-black pl-2">N/A</p>
                                  </div>
                                )}
                              </p>
                              <p className="text-[#696969] font-normal mt-4 text-[14px]">
                                Company :{" "}
                                <span className="text-black text-[12px] font-medium">
                                  {candidateData?.company_name}
                                </span>
                              </p>
                            </div>
                            <div className="email mt-4 lg:items-baseline lg:gap-4 sm:flex-col sm:items-baseline sm:gap-3 items-center gap-14">
                              <p className="text-[#696969] font-normal text-[14px]">
                                Email:{" "}
                                <span className="text-black capitalize text-[12px] font-medium">
                                  {candidateData?.email}
                                </span>
                              </p>
                              <p className="text-[#696969] mt-4 font-normal text-[14px]">
                                Phone:{" "}
                                <span className="text-black text-[12px] font-medium">
                                  {candidateData?.cell_phone}
                                </span>
                              </p>
                            </div>

                            <div className="company mt-4">
                              <p className="text-[#696969] flex items-center gap-1 font-normal text-[14px]">
                                Address:{" "}
                                {candidateData?.address ? (
                                  <span className="text-black text-[12px] font-medium">
                                    {candidateData?.address}
                                  </span>
                                ) : (
                                  <div>
                                    <p className="text-black pl-2">N/A</p>
                                  </div>
                                )}
                              </p>
                            </div>
                            <div className="company mt-4">
                              <p className="text-[#696969] flex items-center gap-1 font-normal text-[14px]">
                                Country:{" "}
                                  <span className="text-black text-[12px] capitalize font-medium">
                                    {candidateData?.country}
                                  </span>
                              
                              </p>
                            </div>
                            <div className="company mt-4">
                              <p className="text-[#696969] flex items-baseline gap-1 font-normal text-[14px]">
                                Registration No:{" "}
                              
                                  <span className="text-black text-[12px] font-medium">
                                    {candidateData?.registration_number}
                                  </span>
                              
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  );
};

export default EmployerModal;
