import React, { useEffect, useState } from "react";
import connectbmg from "../../../assets/images/Icons/connectbmg.svg";
import redheart from "../../../assets/images/Icons/redheart.svg";
import greenheart from "../../../assets/images/Icons/whiteHeart.png";
import Flag from "../../../assets/images/Flag.png";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LogOutUserReducer } from "../../../store/loginSlice/loginSlice";
import logouticon from "../../../assets/images/Icons/logouticon.svg";
import logo from "../../../assets/images/logo.png";
import blackheart from "../../../assets/images/blackheart.png";
import Skeleton from "react-loading-skeleton";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import {
  CANDIDATE_PROFILE,
  EMPLOYER_SHORTLIST_CANDIDATE,
} from "../../../utils/baseUrl";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/baseUrl";
import VerifiedButton from "../../../common/verifiedIcon/verifiedIcon";
import { IconArrowLeft, IconInfoCircleFilled } from "@tabler/icons-react";
import { Tooltip } from "react-tooltip";
import { renderBadge } from "../../../common/badges/badges";
import Chart from "react-google-charts";

const CandidateProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isLoading = useSelector((state) => state?.userSlice?.loading);

  const [user, setUser] = useState();
  console.log("🚀 ~ CandidateProfile ~ user:", user);

  const logoutHandler = () => {
    dispatch(LogOutUserReducer());
    navigate("/");
  };
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      axios.get(CANDIDATE_PROFILE(id)).then((response) => {
        console.log("Response", response);
        setUser(response?.data?.content);
      });
    } else {
      navigate(`/public-profile?candidate=${id}`);
    }
  }, []);

  const shortlistCandidate = async (value) => {
    const payload = {
      type: value,
    };
    await axios
      .post(EMPLOYER_SHORTLIST_CANDIDATE(id), payload)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/employer-dashboard")
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response?.data?.message,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  /////////////////////////// pie chart data/////////////////////////////
  const data = [
    ["Task", "Hours per Day"],
    ["TECHNICAL SKILLS", user?.technicalSkillTotal],
    ["PRESENTATION SKILLS", user?.presentationTotal],
    ["PROACTIVE & REACTIVE", user?.proactiveTotal],
    ["CASE STUDIES", user?.caseStudyTotal],
    ["ENGLISH LANGUAGE", user?.englishLanguageTotal],
    ["RECRUITER EMOTIONAL FEEL", user?.recruiterEmotionalTotal],
  ];
  const totalUsedPercentage = data
    .slice(1)
    .reduce((acc, [, percentage]) => acc + percentage, 0);
  const options = {
    Total_Marks: "100",
    Getting_Marks: totalUsedPercentage,
  };
  const feedbackData = [].concat(
    user?.technicalSkill,
    user?.presentationSkill,
    user?.proactive,
    user?.caseStudy,
    user?.englishLanguage,
    user?.recruiterEmotional
  );
  return (
    <>
      <div className="container sm:max-w-[94%] mx-auto">
        <div className="nav-sec bg-white  flex items-center sm:flex-col gap-2 sm:justify-center md:max-w-[94%] sm:max-w-[94%] sm:mx-2 justify-between py-3 pr-6">
          <Link to={"/"}>
            <img src={logo} className=" pt-2 " />
          </Link>
        </div>
      </div>
      <div className="bg-[#F1F5F9]">
        <div className="container sm:max-w-[94%] mx-auto">
          {user && (
            <div className="bg-[#F1F5F9]">
              <div className=" grid 2xl:grid-cols-[387px_1fr] xl:grid-cols-[300px_1fr] lg:grid-cols-[280px_1fr] md:grid-cols-1  pt-5  gap-8">
                <div>
                  <button
                    onClick={() => navigate(-1)}
                    className="border-2 px-2 bg-white  rounded-lg "
                  >
                    <IconArrowLeft className="w-[30px] h-[30px]  text-[#495057] " />
                  </button>
                  <div className="leftsection   flex flex-col justify-between   dashboard-background">
                    <div className="top ">
                      <div className="personalInfo bg-white border rounded-md shadow-md py-8">
                        <div className="profileimg flex justify-center relative pb-2 ">
                          <img
                            src={`${BASE_URL}/profile/${user?.profile_picture}`}
                            alt="profilePicture"
                            className="w-[90px] h-[90px] rounded-full object-cover mx-auto"
                          />
                          <div
                            className={`h-7 w-7 absolute ${
                              user?.profile_picture === "empty-avatar.png"
                                ? "left-[54%]"
                                : "left-[57%]"
                            } top-[50px] xl:left-[59%] lg:left-[58%] md:left-[56%] sm:left-[53%] -rotate-10`}
                          >
                            {renderBadge(user?.subscription_tier)}
                          </div>
                        </div>
                        <div className="candidateinfo  pb-4   px-2 flex flex-col items-center justify-center  ">
                          <h4 className="text-[20px] font-bold text-[#2c2c2c] ">
                            {user?.first_name} {user?.last_name}
                          </h4>
                          <p className="text-[16px] font-normal text-[#777777]">
                            {user?.title}
                          </p>
                        </div>
                      </div>
                      <div className="languages bg-white border rounded-md shadow-md  mt-6">
                        {/* <h2 className="text-[20px] text-[#495057] font-semibold pb-4"> */}
                        <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md p-2 font-medium bg-[#E9AA06]">
                          Languages
                        </h2>
                        <div className=" gap-2 p-4 flex flex-wrap">
                          {user?.language?.map((item, index) => (
                            <h2
                              key={index}
                              className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                            >
                              {item.lan}
                            </h2>
                          ))}
                        </div>
                      </div>
                      <div className="TechSkills bg-white  border rounded-md shadow-md  mt-6 ">
                        {/* <h2 className="text-[20px] text-[#495057] font-semibold pb-4"> */}
                        <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                          Tech Skills
                        </h2>

                        {user?.skill?.some((skill) => skill.type === "TECH") ? (
                          <div className=" p-4 flex flex-wrap gap-2">
                            {user?.skill?.map(
                              (title, index) =>
                                title.type === "TECH" && (
                                  <h2
                                    key={index}
                                    className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                                  >
                                    {title.name} {title.rating}/10
                                  </h2>
                                )
                            )}
                          </div>
                        ) : (
                          <p className="text-[22px] text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                            Candidate does not have Tech skills.
                          </p>
                        )}
                      </div>
                      <div className="SoftSkills bg-white border rounded-md shadow-md  mt-6  ">
                        <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                          Soft Skills
                        </h2>

                        {user?.skill?.some((skill) => skill.type === "SOFT") ? (
                          <div className="flex flex-wrap p-4 gap-2">
                            {user?.skill?.map(
                              (title, index) =>
                                title.type === "SOFT" && (
                                  <h2
                                    key={index}
                                    className="2xl:text-[13px] border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                                  >
                                    {title.name} {title.rating}/10
                                  </h2>
                                )
                            )}
                          </div>
                        ) : (
                          <p className="text-[22px] text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                            Candidate does not have Tech skills.
                          </p>
                        )}
                      </div>

                      <div className="noticePeriod bg-white  border rounded-md shadow-md  mt-6">
                        <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                          Notice Period
                        </h2>
                        <div className=" gap-2  p-4">
                          <h2 className="2xl:text-[13px] capitalize border w-fit mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-[#696969] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal">
                            {user?.notice_period?.replace(/_/g, " ")}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <button onClick={()=>navigate(-1)}>
              <IconArrowLeft className="w-[30px] h-[30px] text-[#495057] " />
              </button> */}
                  <div className="rightsection bg-inherit  mt-[36px] pb-12 mb-12">
                    {user?.interview_video_url ? (
                      <video
                        controls
                        className="xl:w-full 2xl:w-full h-[30rem] border rounded-md shadow-md object-cover"
                      >
                        <source
                          src={`${user?.interview_video_url}`}
                          type="video/mp4"
                        />
                        Sorry, your browser doesn't support embedded videos.
                      </video>
                    ) : (
                      <>
                        <h2 className="text-[20px] text-[#495057] font-medium text-center pt-2">
                          Video will be uploaded when Interview will be done
                        </h2>
                      </>
                    )}

                    <div className="ShortlistedCandidates bg-white border rounded-md shadow-md  mt-6  ">
                      <h2 className="text-[16px] text-white flex items-center capitalize p-2 rounded-t-md font-medium bg-[#E9AA06]">
                        please choose option below
                        <a
                          className="z-10"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="If you want to hire this candidate then shortlist. BMG Team Will contact you."
                        >
                          <Tooltip
                            id="my-tooltip"
                            style={{ fontSize: "12px" }}
                          />
                          <IconInfoCircleFilled className="w-[20px] h-[20px]  ml-2" />
                        </a>
                      </h2>
                      <div className=" flex   sm:flex-col sm:justify-center  gap-8 p-4 ">
                        <div
                          className="bg-[#F7E7BC] w-[10%]  shadow-lg cursor-pointer flex items-center justify-between py-1 px-2"
                          onClick={() => shortlistCandidate("selected")}
                        >
                          <h2 className="text-[12px] font-medium">Shortlist</h2>
                          <img src={redheart} alt="" />
                        </div>
                        <div
                          className="bg-[#F7E7BC] w-[10%] shadow-lg flex items-center justify-between py-1 cursor-pointer px-2"
                          onClick={() => shortlistCandidate("interested")}
                        >
                          <h2 className="text-[12px] font-medium">
                            Whitelist
                          </h2>
                          <img src={greenheart} alt="" className="h-4 w-4" />
                        </div>
                        <div
                          className="bg-[#F7E7BC] w-[10%] shadow-lg flex items-center justify-between  py-1 cursor-pointer  px-2"
                          onClick={() => shortlistCandidate("rejected")}
                        >
                          <h2 className="text-[12px] font-medium">Rejected</h2>
                          <img
                            src={blackheart}
                            alt=""
                            className="accent-black"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="aboutsection bg-white border rounded-md shadow-md  mt-6">
                    <h2 className="text-[16px] text-[#FFFFFF] p-2 rounded-t-md font-medium bg-[#E9AA06]">
                        About Me
                      </h2>
                      <p className="text-[14px]  leading-[26px] p-4 text-[#696969] font-normal">
                        {user?.about_me}
                      </p>
                    </div>

                    <div className="workexperience border rounded-md shadow-md  mt-6 relative bg-white  ">
                    <h2 className="text-[16px] rounded-t-md text-[#FFFFFF] p-2 font-medium bg-[#E9AA06]">
                        Work Experience
                      </h2>

                      <div>
                        {user?.experience?.map((exp, index) => (
                          <div
                            key={index}
                            className="flex items-baseline justify-between p-4 bg-white  border-b-[1px] mb-2 border-[#eeeeee] "
                          >
                            <div>
                              <p className="text-[16px] font-medium leading-[20px] pt-1 pr-16 text-[#E9AA06] ">
                                {exp.designation} | {exp.company}
                              </p>
                              <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#696969]">
                                From:{exp.start_date} To:{exp.end_date}
                              </p>
                              <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#696969]">
                                {exp.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="education relative bg-white border rounded-md shadow-md  mt-6">
                    <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md p-2 font-medium bg-[#E9AA06]">
                        Education
                      </h2>

                      <div>
                        {user?.education?.map((edu, index) => (
                          <div
                            key={index}
                            className="flex items-baseline justify-between  p-4 border-solid border-b-[1px] bg-white"
                          >
                            <div>
                              <p className="text-[16px] font-medium leading-[25px]  pr-16 text-[#E9AA06] ">
                                {edu.degree} | {edu.university}
                              </p>
                              <p className="text-[14px] font-medium leading-[25px] pr-16 text-[#696969]">
                                From:{edu.start_date} TO:{edu.end_date}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="chart relative bg-white border rounded-md shadow-md  mt-6">
                    <h2 className="text-[16px] text-[#FFFFFF] rounded-t-md p-2 font-medium bg-[#E9AA06]">
                        Candidate's Marks Chart
                      </h2>
                      {feedbackData?.length > 0 ? (
                        <Chart
                          chartType="PieChart"
                          data={data}
                          options={options}
                          width={"99.9%"}
                          height={"400px"}
                        />
                      ) : (
                        <p className="text-[18px] capitalize text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                          Graph is shown when first interview will be completed
                        </p>
                      )}
                    </div>
                  </div>
                  {/* <div className="mt-5 flex justify-center items-center">
              
                <button
                  onClick={() => navigate(-1)}
                  className="bg-black text-white border-2 flex gap-2 items-center border-black hover:bg-white hover:text-black px-10 py-2 rounded text-lg"
                >
                  Back To Applications
                </button>
              
              </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CandidateProfile;
